import React, { useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
//import { useDocTitle } from '../components/CustomHook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import webIcon from '../images/Internet.svg';
import commerceIcon from '../images/E-commerce.svg';
import webappIcon from '../images/web-app.svg';
import saasIcon from '../images/saasIcon.svg';
import mobilewebIcon from '../images/mobilewebIcon.svg';
import dataIcon from '../images/dataIcon.svg';


const WebDevInfo = () => {

    //    useDocTitle('CodeSuits | Web Development')

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation()
    const { web_title, web_subtitle1, web_subtitle2, web_case_study } = t("services_pages.webdev");


    const { website_title, website_subtitle, website_description } = t("services_pages.webdev.websites");


    const { ecommerce_title,
        ecommerce_subtitle,
        ecommerce_description,
    } = t("services_pages.webdev.ecommerce");


    const { webapp_title,
        webapp_subtitle,
        webapp_description,
    } = t("services_pages.webdev.webapp");


    const { saas_title,
        saas_subtitle,
        saas_description,
    } = t("services_pages.webdev.saas");


    const { webmobile_title,
        webmobile_subtitle,
        webmobile_description,
    } = t("services_pages.webdev.webmobile");


    const { webdata_title,
        webdata_subtitle,
        webdata_description,
    } = t("services_pages.webdev.webdata");




    return (
        <>

            <Helmet>
                <title>CodeSuits | Web Development</title>
                <meta
                    name='description'
                    content='We design and develop web applications. From a simple website to complex cloud-based SaaS application'
                />
                <meta
                    name='keywords'
                    content="software, saas, cloud, data, security, landing page, website, software engineering, web application, obile application, android, ios, mobile app, codesuits, coding, software application, sw solutions, software company"
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content="CodeSuits | Web Development" />
                <meta name="twitter:description" content="Best Products for your pet" />
                <meta name="twitter:image" content="https://styles/assetsOptimized/ourStory/StoreFront.jpeg" />
                <meta property="og:title" content="CodeSuits | Web Development" />
                <meta property="og:description" content="Best Web solutions for your business" />
                <meta property="og:image" content="url to image" />
                <meta property="og:url" content="pets.abc" />
                <meta property="og:site_name" content="CodeSuits | Web Development" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
            </Helmet>

            <div>
                <NavBar />
            </div>


            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">


                    <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
                        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">


                            <div class="max-w-2xl mx-auto text-center">

                                <h2 className="font-bold leading-tight text-center lg:text-center text-blue-900 text-4xl">{web_title}</h2>
                                <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">{web_subtitle1}</p>
                            </div>

                            <p class="max-w-xl mx-auto mt-6 text-base font-bold  lg:text-center leading-relaxed text-gray-600">{web_subtitle2}</p>

                            <div class="grid grid-cols-1 gap-6 mt-12 lg:mt-16 xl:gap-10 sm:grid-cols-2 lg:grid-cols-3">
                                <div class="overflow-hidden bg-white rounded shadow">
                                    <div class="p-8">
                                        <div class="flex items-center">

                                            <img alt="card img" className="rounded-t float-right" src={webIcon} />


                                            <div class="ml-5 mr-auto">
                                                <p class="text-xl font-semibold text-black">{website_title}</p>
                                                <p class="mt-px text-sm text-gray-600">{website_subtitle}</p>
                                            </div>

                                            <svg class="hidden w-5 h-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                            </svg>


                                        </div>
                                        <p class="text-base leading-relaxed text-gray-600 mt-7">{website_description}</p>
                                    </div>
                                </div>





                                <div class="overflow-hidden bg-white rounded shadow">
                                    <div class="p-8">
                                        <div class="flex items-center">

                                            {/* 
                                            <img class="flex-shrink-0 w-12 h-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/3/slack-logo.png" alt="" />
                                            */}

                                            <img alt="card img" className="rounded-t float-right" src={commerceIcon} />

                                            <div class="ml-5 mr-auto">
                                                <p class="text-xl font-semibold text-black">{ecommerce_title}</p>
                                                <p class="mt-px text-sm text-gray-600">{ecommerce_subtitle}</p>
                                            </div>

                                            {/* 
                                            <svg class="block w-6 h-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                            </svg>
                                            */}
                                        </div>
                                        <p class="text-base leading-relaxed text-gray-600 mt-7">{ecommerce_description}</p>
                                    </div>
                                </div>

                                <div class="overflow-hidden bg-white rounded shadow">
                                    <div class="p-8">
                                        <div class="flex items-center">



                                            <img alt="card img" className="rounded-t float-right" src={webappIcon} />



                                            <div class="ml-5 mr-auto">
                                                <p class="text-xl font-semibold text-black">{webapp_title}</p>
                                                <p class="mt-px text-sm text-gray-600">{webapp_subtitle}</p>
                                            </div>
                                            <svg class="hidden w-5 h-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                            </svg>
                                        </div>
                                        <p class="text-base leading-relaxed text-gray-600 mt-7">{webapp_description}</p>
                                    </div>
                                </div>

                                <div class="overflow-hidden bg-white rounded shadow">
                                    <div class="p-8">
                                        <div class="flex items-center">


                                            {/*          <img class="flex-shrink-0 w-12 h-auto" src="https://cdn.rareblocks.xyz/collection/celebration/images/integration/3/intercom-logo.png" alt="" />*/}
                                            <img alt="card img" className="rounded-t float-right" src={saasIcon} />





                                            <div class="ml-5 mr-auto">
                                                <p class="text-xl font-semibold text-black">{saas_title}</p>
                                                <p class="mt-px text-sm text-gray-600">{saas_subtitle}</p>
                                            </div>
                                            <svg class="hidden w-5 h-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                            </svg>
                                        </div>
                                        <p class="text-base leading-relaxed text-gray-600 mt-7">{saas_description}</p>
                                    </div>
                                </div>

                                <div class="overflow-hidden bg-white rounded shadow">
                                    <div class="p-8">
                                        <div class="flex items-center">


                                            <img alt="card img" className="rounded-t float-right" src={mobilewebIcon} />



                                            <div class="ml-5 mr-auto">
                                                <p class="text-xl font-semibold text-black">{webmobile_title}</p>
                                                <p class="mt-px text-sm text-gray-600">{webmobile_subtitle}</p>
                                            </div>
                                            <svg class="hidden w-5 h-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                            </svg>
                                        </div>
                                        <p class="text-base leading-relaxed text-gray-600 mt-7">{webmobile_description}</p>
                                    </div>
                                </div>

                                <div class="overflow-hidden bg-white rounded shadow">
                                    <div class="p-8">
                                        <div class="flex items-center">

                                            <img alt="card img" className="rounded-t float-right" src={dataIcon} />




                                            <div class="ml-5 mr-auto">
                                                <p class="text-xl font-semibold text-black">{webdata_title}</p>
                                                <p class="mt-px text-sm text-gray-600">{webdata_subtitle}</p>
                                            </div>
                                            <svg class="hidden w-5 h-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                            </svg>
                                        </div>
                                        <p class="text-base leading-relaxed text-gray-600 mt-7">{webdata_description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className=" my-8 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/portfolio-all" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    {web_case_study}
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                            </div>

                        </div>
                    </section>








                </div>
            </div>
            <Footer />
        </>

    )
}

export default WebDevInfo;