import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom'
import squidLogo from '../images/squid-192.svg';
import squidImg1 from '../images/casestudy/squidImg1.jpg';
import squidImg2 from '../images/casestudy/squidImg2.jpg';
import wearLogo from '../images/casestudy/wewear-logo.jpg';

// websites
import printLogo from '../images/casestudy/printLogo.png';
import printBg from '../images/casestudy/printBg.png';

import foodilyLogo from '../images/casestudy/foodily-log.jpg';
import foodilyBg from '../images/casestudy/foodily.jpg';

import pureLogo from '../images/casestudy/pure-logo.jpg';
import pureBg from '../images/casestudy/pureBg.jpg';

import roveroLogo from '../images/casestudy/rovero-logo.jpg';
import roveroBg from '../images/casestudy/roveroBg.jpg';

import adelLogo from '../images/casestudy/adellogo.jpg';
import adelBg from '../images/casestudy/adelBg.jpg';

import kingLogo from '../images/casestudy/kingLogo.jpg';
import kingBg from '../images/casestudy/kingBg.jpg';


import parkLogo from '../images/casestudy/parkLogo.jpg';
import parkBg from '../images/casestudy/parkBg.jpg';


import lanasLogo from '../images/casestudy/lanasLogo.jpg';
import lanasBg from '../images/casestudy/lanasBg.jpg';

import trachtLogo from '../images/casestudy/trachtLogo.jpg';
import trachBg from '../images/casestudy/trachtBg.jpg';




import shukLogo from '../images/casestudy/shukLogo.png';
import shukBg from '../images/casestudy/shukBg.jpg';

import petsLogo from '../images/casestudy/petsLogo.png';
import petsBg from '../images/casestudy/petsBg.jpg';



// saas
import squidBg from '../images/casestudy/squid_bg.png';

import qspaceBg from '../images/casestudy/qspaceBg.jpg';
import qspaceLogo from '../images/casestudy/qspaceLogo.jpg';

import saaslyBg from '../images/casestudy/saaslyBg.jpg';
import saaslyLogo from '../images/casestudy/saaslyLogo.jpg';

import ipsumBg from '../images/casestudy/ipsumBg.jpg';
import ipsumLogo from '../images/casestudy/ipsumLogo.jpg';

// mobile
import grabLogo from '../images/casestudy/grabLogo.png';
import grabBG from '../images/casestudy/grabBg.jpg';

import healthLogo from '../images/casestudy/health-logo.jpg';
import healthBG from '../images/casestudy/healthBg.png';

import ridesLogo from '../images/casestudy/ridesLogo.jpg';
import ridesBG from '../images/casestudy/ridesBg.jpg';

import netpayLogo from '../images/casestudy/netpayLogo.jpg';
import netpayBg from '../images/casestudy/netpayBg.png';

// ux/ui
import uxBg from '../images/casestudy/uxBg.jpg';
import uiBg from '../images/casestudy/uiBg.jpg';
import mediaBg from '../images/casestudy/marketingBg.jpg';
import docsBg from '../images/casestudy/docsBg.jpg';


import wewearBg from '../images/casestudy/wewearBg.png';

import icoWeb from '../images/casestudy/ico_web.png';
import icoSaaS from '../images/casestudy/icoSaaS.png';
import icoMobile from '../images/casestudy/icoMobile.png';
import icoDesign from '../images/casestudy/icoDesign.png';




import portfolioIntro from '../images/casestudy/portfolio-top.png';


const PortfolioPage = () => {

    const { t } = useTranslation()
    const { title, subtitle } = t("portfolio_all");

    const { web_title } = t("portfolio_all.websites");
    const { print_title, print_subtitle, print_description, print_learn } = t("portfolio_all.websites");
    const { bfood_title, bfood_subtitle, bfood_description, bfood_learn } = t("portfolio_all.websites");
    const { pets_title, pets_subtitle, pets_description, pets_learn } = t("portfolio_all.websites");



    const { saas_title, } = t("portfolio_all.saas");
    const { squid_title, squid_subtitle, squid_description, squid_learn } = t("portfolio_all.saas");
    const { qspace_title, qspace_subtitle, qspace_description, qspace_learn } = t("portfolio_all.saas");
    const { saasly_title, saasly_subtitle, saasly_description, saasly_learn } = t("portfolio_all.saas");
    const { ipsum_title, ipsum_subtitle, ipsum_description, ipsum_learn } = t("portfolio_all.saas");



    const { mobile_title, } = t("portfolio_all.mobile");

    const { grab_title, grab_subtitle, grab_description, grab_learn } = t("portfolio_all.mobile");
    const { health_title, health_subtitle, health_description, health_learn } = t("portfolio_all.mobile");
    const { rides_title, rides_subtitle, rides_description, rides_learn } = t("portfolio_all.mobile");
    const { netpay_title, netpay_subtitle, netpay_description, netpay_learn } = t("portfolio_all.mobile");

    const { design_title, } = t("portfolio_all.design");
    const { ux_title, ux_description, ux_learn } = t("portfolio_all.design");
    const { ui_title, ui_description, ui_learn } = t("portfolio_all.design");
    const { media_title, media_description, media_learn } = t("portfolio_all.design");
    const { docs_title, docs_description, docs_learn } = t("portfolio_all.design");



    const { wewear_title, wewear_subtitle, wewear_description, wewear_learn } = t("portfolio.wewear");

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);


    return (

        <>
            <div className="PortfolioPage" id='portfolio-all'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='portfolio' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {title}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                            </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{subtitle}</p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-18 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={portfolioIntro} />
                        </div>

                    </div>
                </div>




                {/* ---  Websites wtih scroller ---*/}

                {/*}    
                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">

                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoWeb} />


                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                        {web_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>




                    <div className="relative flex items-center justify-center w-full dark:text-gray-900">
                        <button aria-label="Slide back" type="button" className="absolute left-0 z-30 p-2 ml-10 bg-opacity-50 rounded-full focus:outline-none focus:dark:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:dark:ring-gray-600 dark:bg-gray-50">
                            <svg width="8" height="14" fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                                <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>


                        <div className="flex items-center justify-start w-full h-full gap-6 py-4 mx-auto overflow-auto lg:gap-8" >

                            <div className="relative flex flex-shrink-0 w-full sm:w-auto" >

                                <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max h-116 w-96" style={{ backgroundImage: `url(${printBg})` }}  >

                                    <div className="m-2 text-justify text-sm" >
                                        <div className="grid align-items:center" >
                                            <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={printLogo} />

                                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{print_title}</h4>
                                        </div>

                                        <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{print_subtitle}</h4>
                                        <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                            {print_description}
                                        </p>

                                        <div className="flex justify-center my-4">
                                            <Link to="/casestudy-print" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                {print_learn}
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>




                            </div>


                            <div className="relative flex flex-shrink-0 w-full sm:w-auto">

                                <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max h-116 w-96" style={{ backgroundImage: `url(${shukBg})` }}  >

                                    <div className="m-2 text-justify text-sm" >
                                        <div className="grid align-items:center">
                                            <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={shukLogo} />
                                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{bfood_title}</h4>
                                        </div>

                                        <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{bfood_subtitle}</h4>
                                        <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                            {bfood_description}
                                        </p>
                                        <div className="flex justify-center my-4">
                                            <Link to="/casestudy-bari" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                {bfood_learn}
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="relative flex flex-shrink-0 w-full sm:w-auto">


                                <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max h-116 w-96" style={{ backgroundImage: `url(${wewearBg})` }}  >

                                    <div className="m-2 text-justify text-sm">
                                        <div className="grid align-items:center">
                                            <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={wearLogo} />

                                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{wewear_title}</h4>
                                        </div>

                                        <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{wewear_subtitle}</h4>
                                        <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                            <p> {wewear_description}</p>
                                        </p>
                                        <div className="flex justify-center my-4">
                                            <Link to="/casestudy-wewear" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                {wewear_learn}
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="relative flex flex-shrink-0 w-full sm:w-auto">

                                <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max h-116 w-96" style={{ backgroundImage: `url(${petsBg})` }}  >

                                    <div className="m-2 text-justify text-sm ">
                                        <div className="grid align-items:center ">
                                            <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={petsLogo} />

                                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{pets_title}</h4>
                                        </div>

                                        <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{pets_subtitle}</h4>
                                        <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                            {pets_description}
                                        </p>
                                        <div className="flex justify-center my-4">
                                            <Link to="/casestudy-pets" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                {pets_learn}
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="relative flex flex-shrink-0 w-full sm:w-auto">

                                <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max h-116 w-96" style={{ backgroundImage: `url(${petsBg})` }}  >

                                    <div className="m-2 text-justify text-sm ">
                                        <div className="grid align-items:center ">
                                            <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={petsLogo} />

                                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{pets_title}</h4>
                                        </div>

                                        <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{pets_subtitle}</h4>
                                        <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                            {pets_description}
                                        </p>
                                        <div className="flex justify-center my-4">
                                            <Link to="/casestudy-pets" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                {pets_learn}
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <button aria-label="Slide forward" id="next" className="absolute right-0 z-30 p-2 mr-10 bg-opacity-50 rounded-full focus:outline-none focus:dark:bg-gray-600 focus:ring-2 focus:ring-offset-2 focus:dark:ring-gray-600 dark:bg-gray-50">
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4">
                                <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </button>
                    </div>

                </div> */}


                {/* ---  Websites  ---*/}



                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    {/* ---  row of icon & title   ---*/}
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">

                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoWeb} />


                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                        {web_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>



                    {/* ---  row items 1 ---*/}


                    <div className="px-4" data-aos="fade-down" data-aos-delay="600" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${foodilyBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={foodilyLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Foodily</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Coffee Bar</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        A website for a cafehouse with information about its products, menu and events. We enabled the client to increase their engagement with their customers.
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-foodily" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {print_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>

                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${pureBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={pureLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">PureRelax</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Beauty & Wellness</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        We developed an e-commerce site for PureRelax, a beauty and health products and services company. It included additional features such as dynamic content, booking system, SEO solutions and more.
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-relax" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {bfood_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${roveroBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={roveroLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Hotel Rovero</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Tourism </h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> CodeSuits developed a website for the Rovero Hotel with a room reservation and online payment solution, including dynamic content (CMS), live chat powered by artificial intelligence, accessibility and more..</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-rovero" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {wewear_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${adelBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={adelLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Adel Beauty </h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Beauty & Wellness</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        An attractive website for small business to promote its service and products in the beauty and health industry.
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-adel" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {pets_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>



                    {/* ---  row items 2 ---*/}

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    <div className="px-4" data-aos="fade-down" data-aos-delay="600" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${kingBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={kingLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">King Solomon Hotel</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Tourism</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        We created a new website for a client, built with the latest technologies and design systems to replace and upgrade their old style website with minimal functionality.
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-king" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {print_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>

                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${parkBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={parkLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Park Hotel</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Tourism</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        Another example of a website we created for a client in the hotel and tourism industry who wanted to replace his old website. Our designers and developers created an innovative and attractive website that helped the client increase orders.
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-park" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {print_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>




                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${lanasBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={lanasLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Lana's Nails</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Beauty & Wellness</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> We created a website for a small business in the beauty and wellness industry to increase its engagement with customers and sales.  </p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-lanas" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {wewear_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${trachBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={trachtLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Trachtenberg</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Distillery</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        CodeSuits developed a website for Trachtenberg's distillery. It provided order option, dynamic content (CMS) and SEO solutions.
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-tracht" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {pets_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>


                    {/* ---  row items 3 ---*/}

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>



                    <div className="px-4" data-aos="fade-down" data-aos-delay="600" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${printBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={printLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{print_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{print_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {print_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-print" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {print_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>

                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${shukBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={shukLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{bfood_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{bfood_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {bfood_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-bari" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {bfood_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${wewearBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={wearLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{wewear_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{wewear_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {wewear_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-wewear" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {wewear_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${petsBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={petsLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{pets_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{pets_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {pets_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-pets" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {pets_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>


                </div>



                {/* ---  Web and SaaS  ---*/}




                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>





                    {/* ---  row of icone & title   ---*/}
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900  hover:text-gray-500 bg-white hover:bg-gray-200 shadow transition duration-150 ease-in-out" aria-label="Twitter">


                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoSaaS} />



                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                        {saas_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>






                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${squidBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={squidLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{squid_title} </h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{squid_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {squid_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-squid" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {squid_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            {/* style={{ stroke:"black" , strokeWidth: "10px" }} */}

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${qspaceBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={qspaceLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{qspace_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{qspace_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {qspace_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-quickspace" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {qspace_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${saaslyBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={saaslyLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{saasly_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{saasly_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {saasly_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-saasly" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {saasly_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${ipsumBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={ipsumLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{ipsum_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{ipsum_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {ipsum_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-logo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {ipsum_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>

                </div>


                {/* ---  Mobile  apps  ---*/}


                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='casestudy_mobile' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>




                    {/* ---  row of icone & title   ---*/}
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900  hover:text-gray-500 bg-gray-100 hover:bg-gray-200 shadow transition duration-150 ease-in-out" aria-label="Twitter">

                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoMobile} />

                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                        {mobile_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>





                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${grabBG})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={grabLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{grab_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{grab_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {grab_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-grab" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {grab_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            {/* style={{ stroke:"black" , strokeWidth: "10px" }} */}

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${healthBG})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={healthLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{health_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{health_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {health_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-healthness" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {health_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${ridesBG})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={ridesLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{rides_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{rides_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {rides_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-rides" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {rides_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${netpayBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={netpayLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{netpay_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{netpay_subtitle}</h4>

                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {netpay_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-fintech" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {netpay_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>

                </div>


                {/* ---  UX/UI design  ---*/}


                {/*}

                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>



                    // ---  row of icone & title   --
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900  hover:text-gray-800 bg-blue-900 hover:bg-gray-500 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">



                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoDesign} />


                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                        {design_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>



                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${uxBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >

                                        <h4 className="font-semibold my-16 text-lg md:text-2xl text-center mb-4 h-1">{ux_title} </h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12"></h4>

                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {ux_description}
                                    </p>


                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-ux" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {ux_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${uiBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >

                                        <h4 className="font-semibold my-16 text-lg md:text-2xl text-center mb-4 h-1">{ui_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12"></h4>

                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {ui_description}
                                    </p>


                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-ui" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {ui_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>




                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${mediaBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >

                                        <h4 className="font-semibold my-16 text-lg md:text-2xl text-center mb-4 h-1">{media_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12"></h4>

                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {media_description}
                                    </p>


                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-marketing" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {media_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${docsBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >

                                        <h4 className="font-semibold my-16 text-lg md:text-2xl text-center mb-4 h-1">{docs_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12"></h4>

                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {docs_description}
                                    </p>


                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-docs" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {docs_learn}
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>

                </div>
                */}


                {/* ---  GOALS  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={squidImg2} />
                        </div>



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                GOALS and OBJECTIVES</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>Provide a quality solution</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>Aligned with customer's roadmap</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>Commited & Synced</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>Agile and Flexible</li>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ---  Contatct & Footer  ---*/}

                <div>
                    <Contact></Contact>
                </div>


            </div >
        </>
    )

}

export default PortfolioPage;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

