//import React from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/hero-img.png';
import ModalVideo from 'react-modal-video'
import { useTranslation } from 'react-i18next';


import './Hero.css'

//npm uninstall firebase
//npm i firebase

//web-dev.svg
const Hero = () => {
    const [isOpen, setOpen] = useState(false)

    //https://youtu.be/-79ahr9CrY0

    const { t } = useTranslation()

    return (
        <>


            {/* <section className="video-section-two">
                <div className="video-box">

                    <figure className="video-image"> */}

            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>


                <div className='bodyHero' >
                    <link rel='canonical' href='https://codesuits.com' />
                    <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in" >
                        <div className="video-box z-1">
                            <figure className="video-image">
                                <div id='hero' className="flex flex-col lg:flex-row py-8  justify-between text-center lg:text-left">

                                    <div className="lg:w-1/2 flex flex-col" data-aos="zoom-in" data-aos-delay="200">

                                        {/* <a onClick={() => setOpen(true)} className="lightbox-image overlay-box"><span className="flaticon-play-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </a> */}

                                        <h1 className="mb-8 md:text-5xl text-3xl py-8 font-bold text-blue-900">
                                            {t("description")}
                                        </h1>


                                        {/* <div className="mb-20 mt-16 text-xl font-semibold tracking-tight text-gray-500 "> */}
                                            <a onClick={() => setOpen(true)} className="lightbox-image overlay-box"><span className="flaticon-play-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </a>
                                        {/* </div> */}



                                        <div className="text-xl font-semibold tracking-tight text-gray-500 mt-64">
                                            {t("subtitle")}
                                        </div>


 


                                        <div className="casestudy mb-4 space-x-0 md:space-x-2 md:mb-8 z-50">
                                            <Link to="/portfolio-all" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0" >
                                                {t("casestudy")}
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="flex lg:justify-end w-full lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                                        <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
                                    </div>

                                </div>


                            </figure>
                        </div>




                    </div>
                </div>

            </div>


            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="-79ahr9CrY0" onClose={() => setOpen(false)} />

            {/* </figure>



                    <a onClick={() => setOpen(true)} className="lightbox-image overlay-box"><span className="flaticon-play-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </a>
                </div>
            </section> */}

            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="-79ahr9CrY0" onClose={() => setOpen(false)} /> */}



            {/* <a aria-label="Chat on WhatsApp" href="https://wa.me/1XXXXXXXXXX"> <img alt="Chat on WhatsApp" src="WhatsAppButtonGreenLarge.svg" />
<a />
to embed PNG image
<a aria-label="Chat on WhatsApp" href="https://wa.me/1XXXXXXXXXX"> <img alt="Chat on WhatsApp" src="WhatsAppButtonGreenLarge.png" />
<a /> */}


        </>
    )
}

export default Hero;
