import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import crossImg from '../images/tech_cross.jpg';
import crossLogo from '../images/tech_cross.svg';


import flutterImg from '../images/flutter.png';
import flutterIcon from '../icons/flutter.svg'
import fflowIcon from '../icons/flutterflow.svg'
import rnativeIcon from '../icons/reactnative.svg'
import xamarinIcon from '../icons/xamarin.svg'
import reactImg from '../images/reactnative.png'
import xamarinImg from '../images/xamarin.png'


const CrossPlatformInfo = () => {


    useDocTitle('CodeSuits | Cross Platform Development')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()

    const {

        tech_data_title,
        tech_data_description1,
        tech_data_description2,
        tech_data_description3,
        tech_data_description4,
        tech_data_card1,
        tech_data_card1_description,
        tech_data_card1_description2,
        tech_data_card2,
        tech_data_card2_description,
        tech_data_card3,
        tech_data_card3_description,
        tech_data_bigdata1,
        tech_data_bigdata2,
        tech_data_mlai1,
        tech_data_mlai2,
        tech_data_letstalk,

    } = t("tech_pages.tech_data");


    const { ux_techstack,
        uxui_stack,
        ux_techstack1,
        ux_techstack2,
        ux_techstack3,
        ux_techstack4,
        ux_techstack5, } = t("portfolio_all.pages.ux");

    const { ui_techstack,
        ui_techstack1,
        ui_techstack2,
        ui_techstack3,
        ui_techstack4,
        ui_techstack5, } = t("portfolio_all.pages.ui");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">


                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl ">Cross Platform Solutions</h2>
                    </div>

                    <div class="max-w-2xl mx-auto text-center">
                        <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b> CP becomes a more popular solution for companies looking to deploy their product on multiple platforms in short time and resources.</b> </p>
                        <div class="mt-5">
                            <p mt-8 text-base text-gray-600> Cross-platform technologies allow developers coding once, and deploy the resulted code on multiple platforms. The capabilities of such technologies is improving all the time, and so, the demand for a cross-platform solution is keep growing.</p>
                        </div>
                    </div>
                    <div class="max-w-2xl mx-auto text-center">
                        <p class="max-w-xl mx-auto mt-4 text-xl leading-relaxed text-gray-600"><b>CodeSuits is well experienced in deploying fast products that developed with these technologies.</b></p>
                    </div>
                </div>

                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">


                                    <img class="flex justify-center mx-auto " src={flutterImg} alt="" />


                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo1} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">Flutter </p>
                                    <p class="mt-1 text-base text-gray-600"> We develop cross-platform apps based on Google's Flutter language.</p>
                                    {/* <p class="mt-1 text-base text-gray-600">{tech_data_card1_description2}</p> */}
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={reactImg} alt="" />

                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo2} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">React Native</p>
                                    <p class="mt-1 text-base text-gray-600">We experience in developing Facebook's React Native apps.</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={xamarinImg} alt="" />

                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo3} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">Xamarin</p>
                                    <p class="mt-1 text-base text-gray-600">We also support Microsoft's Xamarin cross-platform's solution.</p>
                                </div>
                            </div>




                        </div>




                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        Flutter</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">CodeSuits Flutter experts will join your team to develop an existing code, or, build a Flutter app from a scratch. We are also experienced with Flutter tools like: FlutterFlow. </p>
                                </div>





                                <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                    <div className='flex-col justify-center items-center'>

                                        <div className='flex  justify-center items-center'>
                                            <img alt="" className="rounded-t float-center" src={flutterIcon} style={{ height: 42, width: 42 }} />
                                        </div>


                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            Flutter</h3>
                                    </div>


                                    <div className='flex-col justify-center items-center'>

                                        <div className='flex  justify-center items-center'>
                                            <img alt="" className="rounded-t float-center" src={fflowIcon} style={{ height: 42, width: 42 }} />
                                        </div>


                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            FlutterFlow</h3>
                                    </div>
                                </div>

                            </div>

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        React Native </h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">As with Flutter, React Native is an equivalent technology for cross-platform app development. By using React Native, it is easier to integrate it with other React based application like, Node JS and React JS.</p>
                                </div>


                                <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                    <div className='flex-col justify-center items-center'>

                                        <div className='flex  justify-center items-center'>
                                            <img alt="" className="rounded-t float-center" src={rnativeIcon} style={{ height: 42, width: 42 }} />
                                        </div>


                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            React Native</h3>
                                    </div>
                                </div>
                            </div>


                            {/* ---  Xamarin --- */}

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        Xamarin </h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">Microsoft's Xamarin is another cross-platform solution that can be developed simultaneously on several platforms (eg Windows Desktop, Web, Android, iOS...). <br/></p>
                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">The CodeSuits team is well experienced in building Xamarin apps from scratch, or by extending existing code.</p>
                                </div>
                            </div>



                            {/* ---  UX Tech Stack --- */}

                            <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                <div className='flex-col justify-center items-center'>

                                    <div className='flex  justify-center items-center'>
                                        <img alt="" className="rounded-t float-center" src={xamarinIcon} style={{ height: 42, width: 42 }} />
                                    </div>


                                    <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                        Xamarin </h3>
                                </div>
                            </div>

                            <div class="max-w-2xl mx-auto text-center lg:pt-12">

                                <Link to="/get-demo" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-900 transition-all duration-200 border-2 border-blue-900 rounded-md mt-9 hover:bg-blue-900 hover:text-white hover:border-blue-900 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_data_letstalk}
                                </Link>
                            </div>
                        </section>
                    </div >
                </div >
            </section >
            <Footer />
        </>


    )
}
export default CrossPlatformInfo;