import { React, useEffect } from "react";
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Video from '../components/Video';

import FloatingIcon from '../components/FloatingIcon';

import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Technologies from '../components/Technologies';
import Industries from '../components/Industries';
import { Helmet } from 'react-helmet';



const Home = () => {

    return (
        <>
            <Helmet>
                <title>Software Engineering Solutions</title>
                <meta
                    name='description'
                    content='Software engineering solutions'
                />
                <meta
                    name='keywords'
                    content="Software, Software Engineering, Web application, Mobile application, Android, iOS, Mobile app, CodeSuits, Coding, Software application, SW solutions, Software company"
                />
            </Helmet>
            {/* <FloatingIcon/> */}

            <Hero />

            {/* <Video/>  */}
            <Intro />

            <Services />
            <Technologies />
            <Industries />
            <Portfolio />
            <Cta />

            <Footer />

        </>

    )
}

export default Home;


///<Clients />


