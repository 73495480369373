import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
import Notiflix from 'notiflix';
// import emailjs from 'emailjs-com';

const Contact = () => {


    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);


    const { t } = useTranslation();
    const { message_title, message_fname, message_lname, message_email, message_phone, message_text, message_button, message_button_loading, message_address_title, message_address, message_phone_title, message_phone_number, message_email_title, message_email_address, message_validation, } = t("contact.message");


    useDocTitle('CodeSuits | SW Development - Contact Us')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])

        document.getElementById("validateMessage").style.visibility = "hidden";

    }

    const clearInput = () => {

        document.getElementById("validateMessage").style.visibility = "hidden";

        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    // https://backend.quickspace.co.il/
    //http://ec2-13-50-210-93.eu-north-1.compute.amazonaws.com:3000

    const SendServer = (e) => {
        console.log('inside sendServer');
        fetch("https://backend.quickspace.co.il")
            .then((res) => res.json()).then((data) => console.log(data));
    }



    const sendContactMessage = (e) => {

        if (firstName == "" || lastName == "" || email == "" || phone == "" || message == "") {

            document.getElementById("validateMessage").style.visibility = "visible";

        }

        else {

            e.preventDefault();
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = "loading...";

            const jsonData = {

                "properties": [
                    { "property": "email", "value": '${email}' },
                    { "property": "firstname", "value": "${name}" },
                    { "property": "website", "value": "${website}" },
                    { "property": "company", "value": "${company}" },
                    { "property": "phone", "value": "${phone}" },
                    { "property": "address", "value": "${address}" },
                    { "property": "city", "value": "${city}" },
                    { "property": "state", "value": "" },
                    { "property": "zip", "value": "" }
                ]

            };


            let fData = new FormData();
            fData.append('first_name', firstName)
            fData.append('last_name', lastName)
            fData.append('email', email)
            fData.append('phone_number', phone)
            fData.append('message', message)

            axios({
                method: "post",
                mode: "cors",
                url: "https://backend.quickspace.co.il", // http://ec2-13-50-210-93.eu-north-1.compute.amazonaws.com:3000",
                data: fData,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    // "Authorization": "Bearer pat-eu1-0b11ffed-6e17-4238-bfe6-a1eb5283056b",
                    //  "Content-Type": "text/plain",
                }
            })
                .then(function (response) {
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'send message';
                    clearInput()
                    console.log('received from server: ' + response.data);
                    //handle success
                    Notiflix.Report.success(
                        'Success',
                        response.data.message,
                        'Okay',
                    );


                    //    _tfa.push({ notify: 'event', name: 'contact', id: 1756651 });


                })
                .catch(function (error) {
                    document.getElementById('submitBtn').disabled = false;
                    document.getElementById('submitBtn').innerHTML = 'send message';
                    //handle error
                    const { response } = error;
                    if (response.status === 500) {
                        Notiflix.Report.failure(
                            'An error occurred',
                            response.data.message,
                            'Okay',
                        );
                    }
                    if (response.data.errors !== null) {
                        setErrors(response.data.errors)
                    }

                });

        }


    }

    /*
    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = false;
        document.getElementById('submitBtn').innerHTML = 'Loading...';

        const jsonData = {

            "properties": [
                {"property": "email", "value": "${email}"},
                {"property": "firstname", "value": "${name}"},
                {"property": "website", "value": "${website}"},
                {"property": "company", "value": "${company}"},
                {"property": "phone", "value": "${phone}"},
                {"property": "address", "value": "${address}"},
                {"property": "city", "value": "${city}"},
                {"property": "state", "value": ""},
                {"property": "zip", "value": ""}
              ]

          };


        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: "https://api.hubapi.com/contacts/v1/contact/", // process.env.REACT_APP_CONTACT_API,
            data: jsonData, // fData
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer pat-eu1-0b11ffed-6e17-4238-bfe6-a1eb5283056b",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Credentials": "true",
                // 'Content-Type':  'multipart/form-data'

            }
        })
        .then(function (response) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            clearInput()
            //handle success
            Notiflix.Report.success(
                'Success',
                response.data.message,
                'Okay',
            );
        })
        .catch(function (error) {
            document.getElementById('submitBtn').disabled = false;
            document.getElementById('submitBtn').innerHTML = 'send message';
            //handle error
            const { response } = error;
            if(response.status === 500) {
                Notiflix.Report.failure(
                    'An error occurred',
                    response.data.message,
                    'Okay',
                );
            }
            if(response.data.errors !== null) {
                setErrors(response.data.errors)
            }
            
        });
    }*/

    return (
        <>

            <div>
                <NavBar />
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    {/* sendEmail */}
                    <form onSubmit={sendContactMessage}>

                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">{message_title}</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder={message_fname}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.first_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="last_name"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder={message_lname}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.last_name}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="email"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder={message_email}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.email}</p>
                                    }
                                </div>

                                <div>
                                    <input
                                        name="phone_number"
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder={message_phone}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        onKeyUp={clearErrors}
                                    />
                                    {errors &&
                                        <p className="text-red-500 text-sm">{errors.phone_number}</p>
                                    }
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea
                                    name="message"
                                    placeholder={message_text}
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyUp={clearErrors}
                                ></textarea>
                                {errors &&
                                    <p className="text-red-500 text-sm">{errors.message}</p>
                                }
                            </div>

                            <div className="my-4" id="validateMessage" style={{ color: 'red', visibility: 'hidden' }} >
                                {message_validation}
                            </div>


                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button type="submit" id="submitBtn" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                        focus:outline-none focus:shadow-outline">
                                    {message_button}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div
                        className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">

                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">{message_address_title}</h2>
                                    <p className="text-gray-400">{message_address}</p>
                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col">
                                    <h2 className="text-2xl">{message_phone_title}</h2>
                                    <p className="text-gray-400">{message_phone_number}</p>

                                    <div className='mt-5'>
                                        <h2 className="text-2xl">{message_email_title}</h2>
                                        <p className="text-gray-400">{message_email_address}</p>
                                    </div>

                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <a href="https://www.facebook.com/ENLIGHTENEERING/" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                                </a>
                                <a href="https://www.linkedin.com/company/enlighteneering-inc-" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>



            </div>



            <Footer />
        </>


    )
}

export default Contact;
