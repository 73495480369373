import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';


i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: "en",
    fallbackLng: "en",
    returnObjects: true,
    resources: {

        en: {
            translation: {
                description: "We build customized software solutions for your unique business needs",
                subtitle: "We are a team of highly motivated and skilled developers dedicated to deliver the best software.",
                learnmore: "Learn more",
                casestudy: "Our portfolio",
                introtitle: "We develop high quality bespoke software solutions for organizations, institutions and SMEs",
                introdescription: {                    
                    line1: "We take responsibility for building custom software solutions that caters for automation of your business processes and improve efficiency.",
                    //line2: "Our team is well vast in software development and is ready to help develop the applications of your choice.",
                    line2: "",
                    contact: "Contact us",
  
                },

                navbar: {
                    home: "Home",    
                    services: "Services",
                    tech: "Technologies",
                    industries: "Industries",
                    casestudy: "Case Study",
                    company: "Company",
                    contact: "Contact Us",
                    lang: "English",
                    schedule: "Contact us",
                },

                services: {
                    title: "Services",
                    description: "We are deeply committed to the growth and success of our clients",
                    webdev: {
                        web_title: "Web Development",
                        web_description: "We specialize in creating and optimizing high-quality, custom websites and saas-based web applications for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients.",
                    },
                    mobile: {
                        mobile_title: "Mobile App Development",
                        mobile_description: "We develop high-quality, custom cross-platform mobile applications that are robust and optimized for performance, scalability, and accessibility.",
                    },
                    design: {
                        design_title: "UX/UI Design Services",
                        design_description: "We create and consult on making inspiring ux/ui designs. Our experienced and creative designers will help enriching the experience of your users inside your products.",
                    },
                    consult: {
                        consult_title: "General IT Consultation",
                        consult_description: "Our IT consultations service provides expert guidance and support for businesses and individuals looking to optimize their technology solutions.",
                    },
                    build: {
                        build_title1: "We",
                        build_title2: "Build",
                        build_description: "With over 20 years experience in software analysis and design and a deep understanding of the latest IT trends and solutions, we provide customized recommendations and strategies to help you improve your operations, reduce costs, and increase efficiency.",
                    },
                    collaborate: {
                        collaborate_title1: "We",
                        collaborate_title2: "Collaborate",
                        collaborate_description: "We can collaborate with your existing tech team to scale existing software applications or design customized software applications that suits your everyday need and simplifies various processes.",
                    },
                },

                services_pages: {

                    webdev: {
                        web_title: "Web Development",
                        web_subtitle1: "Web development, with its various solutions, is important for every business. From a small coffee shop, having a simple website to grow their audience, to an online banking system that allows its users to make secure transactions worldwide.",
                        web_subtitle2: "WE BUILD TAILOR-MADE WEB APPS TO TARGET OUR CUSTOMER'S GOALS",

                        websites: {
                            website_title: "Websites",
                            website_subtitle: "User friendly attractive sites",
                            website_description: "We build different types of websites (e.g. Business, E-commerce, Booking) integrated with tools to change its content dynamically and expose it to a large scale of customers.",
                        },

                        ecommerce: {
                            ecommerce_title: "E-commerce sites",
                            ecommerce_subtitle: "Your online shop",
                            ecommerce_description: "At CodeSuits we build custom e-commerce sites, related to payment, content and other services to help your business increase its sales.",
                        },

                        webapp: {
                            webapp_title: "Web applications",
                            webapp_subtitle: "Cloud-based solutions",
                            webapp_description: "Our cloud team will help your business build a cloud-base web application, including: cloud architecture design, configurations and development.",
                        },

                        saas: {
                            saas_title: "SaaS",
                            saas_subtitle: "Software as a Service solution",
                            saas_description: "We build B2C and B2B SaaS-based products with a focus on performance, load balancing, security and other cloud-based concerns.",
                        },

                        webmobile: {
                            webmobile_title: "Web Mobile apps",
                            webmobile_subtitle: "Responsive Web applications",
                            webmobile_description: "We develop at CodeSuits responsive-wise Web applications to scale it with mobile and tablet devices.",
                        },

                        webdata: {
                            webdata_title: "Web data management",
                            webdata_subtitle: "Data-driven Web applications",
                            webdata_description: "We build data-driven solutions using big data, ML and AI technologies to improve the performance of our customers' products.",
                        },
                        web_case_study: "check our case studies",
                    },

                    mobile: {
                        mobile_title: "Mobile Development",
                        mobile_subtitle1: "Nowdays, a mobile app is the best way to engage with your users.",
                        mobile_subtitle2: "Why? because they are always there..",
                        mobile_subtitle3: "We build at CodeSuits an amazing tailor-made Android and iPhone mobile apps.",

                        native: {
                            native_title: "Native applications",
                            native_description: "We develop android and ios apps using native languages (e.g. kotlin, swift).",
                        },
                        cross: {
                            cross_title: "Cross-platform applications",
                            cross_description1: "A cross-platform solution, allows developing once and deploy simultaneously on a multiple platforms. This is why it is a great way to reduce the delevelopment time of your products, and assist with your company's go-to-market strategy.",
                            cross_description2: "CodeSuits uses cross-platforms solutions like React Native and Flutter.",
                        },
                        sdk: {
                            sdk_title: "SDK",
                            sdk_description: "We create android and ios sdk (e.g. java, kotlin and swift), and mobile libraries in native languages (e.g. c/c++ and python).",
                        },
                        hollistic: {
                            hollistic_title: "Holistic solution",
                            hollistic_description: "A whole cross-platform customized solution with your mobile and cloud-based interface & services.",
                        },
                        contact: "LET'S TALK!",
                    },

                },


                tech: {
                    title: "Technologies",
                    description: "Our technology stack contains the following tools",
                    data: {
                        data_title: "Data, ML & AI",
                        data_description: "Nowdays, data is a crucial part for every business that needs to be handled properly. We specialize in developing, managing and analyzing data. Whether your business needs a big-data solutions, or, an AI features, our data scientists, data developers and BI experts, will build, or, help your teams with planning and implementing it properly.",
                    },
                    cloud: {
                        cloud_title: "Cloud & DevOps Technologies",
                        cloud_description: "We help SMBs and large corporations target and resolve their cloud technology challanges. As AWS certified partners, our cloud experts will help your business building a cloud-based solution taking in mind performance, security, migration, transformation and computing strartegies.",
                    },
                    cross: {
                        cross_title: "Cross Platforms",
                        cross_description: "Cross platform solutions are great to reduce development time and help your business move faster with its go-to-market strategy. At CodeSuits, we work with multiple cross-platform technologies.",
                    },
                    security: {
                        security_title: "Security and Privacy",
                        security_description: "Security and data privacy are crucial aspects to handle carefully, while your business is keep growing. Aligned with most being used security and privacy standards, our experts will help you building the right solutions.",
                    },
                    creative: {
                        creative_title1: "We",
                        creative_title2: "Creative",
                        creative_description: "Our well experienced and creative UX/UI designers, will create and improve designs, combined with the worlds best practices and modern trends, that will inspire your customers and partners.",
                    },
                    innovative: {
                        innovative_title1: "We",
                        innovative_title2: "Innovative",
                        innovative_description: "The skills and experience we've developed at CodeSuits during many years in the IT industry and an endless projects we've helped to release, allow our IT & UX/UI experts assist your business grow faster.",
                    },
                },


                tech_pages: 
                {
                    tech_data: {

                        tech_data_title: 'Data & ML/AI',
                        tech_data_description1: 'We live today in a data-driven world.',
                        tech_data_description2: 'A business that wants to survive in this reality must manage its data properly.',
                        tech_data_description3: 'This refers to the way it is collected, organized, manipulated, analyzed and secured.',
                        tech_data_description4: 'CodeSuits is well experienced in creating products that aligned with these concearns.',
                        tech_data_card1: "AI-Engine Solutions",
                        tech_data_card1_description: 'Integration with AI engines',
                        tech_data_card1_description2: '(ex: ChatGPT, Binge, etc)',
                        tech_data_card2: "Customized AI solution",
                        tech_data_card2_description: 'Complete ML/AI solution for your business',
                        tech_data_card3: "Cloud Services", 
                        tech_data_card3_description: 'Design, Configure and Manage your cloud services',
                        tech_data_bigdata1: "BIG DATA",
                        tech_data_bigdata2: "Our AWS, GCP, Azure certified cloud experts will help your business manage its Big Data products.",
                        tech_data_mlai1: "ML & AI",
                        tech_data_mlai2: "Enhance your product with AI-powered features. Our data scientists will build a customized ML/AI solution for your business.",
                        tech_data_letstalk: "LET'S TALK!",

                    },

                },



                industries: {
                    title: "Industries",
                    description: "Our teams expertise and experience allow us to support a variety industries",
                    marketing: {
                        marketing_title: "Marketing",
                        marketing_description: "We deliver digital transformation in marketing to optimize customer analysis and enrich companies with actionable insight.",

                    },
                    retail: {
                        retail_title: "Retail",
                        retail_description: "CodeSuits help retail companies adopt new ways of customer engagement, retention and generation of new leads. Improve your business KPIs by integrating our cutting-edge solutions.",
                    },
                    finance: {
                        finance_title: "Finance",
                        finance_description: "We provide IT solutions to financial institutions, payment companies and advisory firms. Our secured and privacy-wise solutions aligned with the industry's highest standards.",

                    },
                    automotive: {
                        auto_title: "Automotive",
                        auto_description: "We employ standards & environments aligned with the most known car manufacturers and we are networking directly with the OEM data management systems.",

                    },
                    share: {
                        share_title1: "We",
                        share_title2: "Transparent",
                        share_description: "CodeSuits transparent with its customers in its knowledge and experience. Our approach for a project success is to synchronize our technical knowledge and experience with our customers and be transparent as possible.",
                    },

                    agile: {
                        agile_title1: "We",
                        agile_title2: "Agile",
                        agile_description: "The experience of our experts, and the working methods we use at CodeSuits, allow us to quickly merge with your organic teams and move forward your business goals.",
                    },
                },

                portfolio: {
                    title: "Case Studies",
                    all_cases: "See All",
                    squid: {
                        squid_title: "Squid",
                        squid_subtitle: "CRM System",
                        squid_description: "AI-enhanced solution to manage customers relationship. This cross-platform project included planning, design and development for web, android & ios applications using aws cloud-based services.",
                        squid_learn: "Learn More",
                    },
                    health: {
                        health_title: "Healthness",
                        health_subtitle: "Healthcare Platform",
                        health_description: "Developing an AI-Powered Android & iOS App with Computer Vision for Face & Wellness Analysis.",
                        health_learn: "Learn More",
                    },
                    wewear: {
                        wewear_title: "WeWear",
                        wewear_subtitle: "E-Commerce Website with AI",
                        wewear_description: "AI-powered E-commerce fashion website. We added Chatbot functionality to increase customer's support and leveraged sales by offering prediction-based campaigns.",
                        wewear_learn: "Learn More",
                    },
                    netpay: {
                        netpay_title: "NetPay",
                        netpay_subtitle: "Digital Wallet Mobile Application",
                        netpay_description: "An innovative digital wallet, made for a finanace company, to improve its customers manage their expenses.",
                        netpay_learn: "Learn More",
                    },

                },

                portfolio_all: {
                    title: "CASE STUDIES",
                    subtitle: "This page contains examples for some of our projects and services in different fields using a tailored-made technologies that we customized for the needs of each customer.",
                    websites: {

                        web_title: "Websites & E-commerce",

                        print_title: "JUSTPRINT",
                        print_subtitle: "E-commerce Printing Website",
                        print_description: "An E-commerce website for ordering prints on clothes and accessories. Included payment and clearence capabilities and orders system.",
                        print_learn: "Learn More",

                        bfood_title: "BARI-FOOD",
                        bfood_subtitle: "A restaurant website",
                        bfood_description: "A Website for a super-fresh healthy food restaurant. We've soloved the customer a problem of unreaching all clients orders by building a smart ordering system.",
                        bfood_learn: "Learn More",

                        pets_title: "PETS",
                        pets_subtitle: "E-commerce site for pets food & accessories",
                        pets_description: "We've created for the client a website to sale his pets products. Using an easy-to-use flow we've developed, users could easily navigate, purchase products and re-order past purchases for saving time.",
                        pets_learn: "Learn More",



                    },
                    saas: {
                        saas_title: "Web & SaaS Apps",

                        squid_title: "SQUID",
                        squid_subtitle: "CRM platform",
                        squid_description: "AI-enhanced solution to manage customers relationship. This cross-platform project included planning, design and development for web, android & ios applications using aws cloud-based services.",
                        squid_learn: "Learn More",

                        qspace_title: "QUICKSPACE",
                        qspace_subtitle: "Real estate system",
                        qspace_description: "AI-powered SaaS solution we've developed for a real-estate company. It allowed its clients search and find assets in a smart way. We've also provided dashboard tools to allow our customer analyze its users behavior for improving its future campaigns and offers",
                        qspace_learn: "Learn More",

                        saasly_title: "SAASLY",
                        saasly_subtitle: "Advanced Analytics platform",
                        saasly_description: "A B2B Cloud-based platform to help companies analyze their product's performance including tools to achieve better results in terms of users most important metrics.",
                        saasly_learn: "Learn More",


                        ipsum_title: "LOGOIPSUM",
                        ipsum_subtitle: "Platform to improve a business management",
                        ipsum_description: "We've joined the customer's product and dev teams to help with developing cloud-based solutions for their product.",
                        ipsum_learn: "Learn More",

                    },

                    mobile: {
                        mobile_title: "Mobile Development",


                        grab_title: "FASTGRAB",
                        grab_subtitle: "Food delivery app",
                        grab_description: "A food delivery app for Android and iPhone devices. Feature examples: Food order, purchasing and clearance. Dynamic content control by client. Chat feature. App performance analytics.",
                        grab_learn: "Learn More",


                        health_title: "HEALTHNESS",
                        health_subtitle: "Healthcare Platform",
                        health_description: "Developing an AI-Powered Android & iOS App with Computer Vision for Face & Wellness Analysis.",
                        health_learn: "Learn More",


                        rides_title: "RIDES",
                        rides_subtitle: "Bike store application",
                        rides_description: "Flutter application for Android and iOS made for a bicycle shop. Feature examples: purchasing and clearance, dynamic content control. App performance analytics.",
                        rides_learn: "Learn More",


                        netpay_title: "NETPAY",
                        netpay_subtitle: "Digital Wallet Mobile Application",
                        netpay_description: "An innovative digital wallet, made for a finanace company, to improve its customers manage their expenses.",
                        netpay_learn: "Learn More",
                    },

                    design: {

                        design_title: "UX/UI Solutions",

                        ux_title: "USER EXPERIENCE",
                        ux_description: "Even a good app, with the best value for its users, may face increased churn if users lose themselves in the product. We know how to create a friendly user experience to keep the user inside the app. Check out our UX examples on different products.",
                        ux_learn: "Learn More",

                        ui_title: "USER INTERFACE",
                        ui_description: "By creating an intuitive and easy-to-use interface, UI design enhances the overall user experience and makes the app more enjoyable to interact with. An app's success heavily relies on user engagement. We build UI design concepts customized for each customer with the relevant design systems and style guide.",
                        ui_learn: "Learn More",

                        media_title: "VISUALS FOR MARKETING",
                        media_description: "Our design work does not end within the boundaries of the software. We help our clients inform users about this by creating attractive visual marketing campaigns. Click the button below to review some of our work.",
                        media_learn: "Learn More",

                        docs_title: "UX/UI FOR DOCUMENTATION & RELEASE NOTES",
                        docs_description: "Your product may not be complete without clear documentation to guide your users on how to use each feature. Check out some of our documentation and release notes in action.",
                        docs_learn: "Learn More",

                    },

                    pages: {


                        foodily: {

                            food_page_title: "FOODILY",
                            food_subtitle1: "Restuarant & Coffee bar",
                            food_subtitle2: "A restaurant website with information about their products and events with the option to place online orders for their menu items and cookbooks.",
                            food_description: "PROJECT DESCRIPTION",
                            food_client_title: "The Client",
                            food_product_title: "The Product",
                            food_work_title: "The Work",
                            food_client_description: "The client is a new chef's restaurant with a deli that sells quality food and other items such as the chef's books.",
                            food_product_description: "A website to promote our customers' business and allow their customers to make online purchases for restaurant food products and chef's books.",
                            food_work_description: "Our team created a UX/UI design that matched the client's requirements. With 3w work our developer completed this e-commerce site.",

                            food_profile: "Profile",
                            food_profile_country_title: "Country",
                            food_profile_country_value: "UK",
                            food_profile_industry_titile: "Industry",
                            food_profile_industry_value: "Restaurants",
                            food_profile_team_titile: "Team size",
                            food_profile_team_value: "3 (PM, Fullstack eng., UX/UI)",

                            food_goals: "GOALS and OBJECTIVES",
                            food_goal1: "E-commerce site to place users orders",
                            food_goal2: "Client can change content dynamically",
                            food_goal3: "Multiple contact options",
                            food_goal4: "SEO tools to increase site exposure",
                            food_goal5: "Provide domain & hosting solutions",

                            food_challanges: "CHALLANGES",
                            food_challange_title1: "Simplify Order Placement",
                            food_challange_1: "Minimize the number of steps required to complete the order for the customer, offering a simple, clear, and ethical flow.",
                            food_challange_title2: "Simplify Content Management",
                            food_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            food_challange_title3: "Increase Customer Exposure",
                            food_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            food_solution_title: "SOLUTION",
                            food_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            food_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            food_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            food_techstack: "Tech Stack",
                            food_techstack1: "R.JS",
                            food_techstack2: "WC",
                            food_techstack3: "HG",
                            food_techstack4: "JML",

                            food_results_title: "OUR RESULTS",
                            food_result_title1: "⭡ Increase Exposure",
                            food_result_description1: "The site's ranking improved over time and its exposure increased.",
                            food_result_title2: "⭡ Increase Sales",
                            food_result_description2: "The E-commerce site CodeSuits built for the client helped them increase their total sales in the first 3 months by 36%.",
                            food_result_title3: "Easy Content Control",
                            food_result_description3: "The customer was able to change items and prices easily.",


                        },




                        pure: {

                            pure_page_title: "PURERELAX",
                            pure_subtitle1: "Beauty & Wellness",
                            pure_subtitle2: "A Website for a business in the beauty and wellness industry",
                            pure_description: "PROJECT DESCRIPTION",
                            pure_client_title: "The Client",
                            pure_product_title: "The Product",
                            pure_work_title: "The Work",
                            pure_client_description: "The client is a beauty and health care clinic. It provides services and related products.",
                            pure_product_description: "A website to promote our customers' business with an online ordering and payment system.",
                            pure_work_description: "CodeSuits collected the client's requirements, prepared the UX/UI and technical design and completed the work in 2-3 weeks.",

                            pure_profile: "Profile",
                            pure_profile_country_title: "Country",
                            pure_profile_country_value: "US",
                            pure_profile_industry_titile: "Industry",
                            pure_profile_industry_value: "Beauty & Wellness",
                            pure_profile_team_titile: "Team size",
                            pure_profile_team_value: "3 (PM, Fullstack eng., UX/UI)",

                            pure_goals: "GOALS and OBJECTIVES",
                            pure_goal1: "E-commerce site to place users orders",
                            pure_goal2: "Client can change content dynamically",
                            pure_goal3: "Booking a treatment",
                            pure_goal4: "SEO tools to increase site exposure",
                            pure_goal5: "Provide domain & hosting solutions",

                            pure_challanges: "CHALLANGES",
                            pure_challange_title1: "Simplify Order Placement",
                            pure_challange_1: "Minimize the number of steps required to complete the order for the customer, offering a simple, clear, and ethical flow.",
                            pure_challange_title2: "Simplify Content Management",
                            pure_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            pure_challange_title3: "Increase Customer Exposure",
                            pure_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            pure_solution_title: "SOLUTION",
                            pure_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            pure_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            pure_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            pure_techstack: "Tech Stack",
                            pure_techstack1: "NJS",
                            pure_techstack2: "WC",
                            pure_techstack3: "HG",
                            pure_techstack4: "JML",

                            pure_results_title: "OUR RESULTS",
                            pure_result_title1: "⭡ Increase Exposure",
                            pure_result_description1: "The site's ranking improved over time and its exposure increased.",
                            pure_result_title2: "⭡ Increase Sales",
                            pure_result_description2: "The E-commerce site CodeSuits built for the client helped them increase their total sales in the first 3 months by 36%.",
                            pure_result_title3: "Easy Content Control",
                            pure_result_description3: "The customer was able to change items and prices easily.",


                        },


                        rovero: {

                            rovero_page_title: "ROVERO HOTEL",
                            rovero_subtitle1: "Hotel & Tourism",
                            rovero_subtitle2: "Website for hotel to place guests orders and run online campaigns",
                            rovero_description: "PROJECT DESCRIPTION",
                            rovero_client_title: "The Client",
                            rovero_product_title: "The Product",
                            rovero_work_title: "The Work",
                            rovero_client_description: "The client is a boutiq hotel willing to upgrade its old website and increase its engagement and reservations.",
                            rovero_product_description: "A new website with attractive design and new features to improve users experience and increase total reservations.",
                            rovero_work_description: "CodeSuits collected the client's requirements, prepared the UX/UI and technical design and completed the work in 4-5 weeks.",

                            rovero_profile: "Profile",
                            rovero_profile_country_title: "Country",
                            rovero_profile_country_value: "Italy",
                            rovero_profile_industry_titile: "Industry",
                            rovero_profile_industry_value: "Hotels & Tourism",
                            rovero_profile_team_titile: "Team size",
                            rovero_profile_team_value: "3 (PM, Fullstack eng., UX/UI)",

                            rovero_goals: "GOALS and OBJECTIVES",
                            rovero_goal1: "E-commerce site to place users orders",
                            rovero_goal2: "Booking a room system",
                            rovero_goal3: "Client Dashboard to change content",
                            rovero_goal4: "SEO tools to increase site exposure",
                            rovero_goal5: "Live Chat & Accessibility widgets",

                            rovero_challanges: "CHALLANGES",
                            rovero_challange_title1: "Integration Order Solution",
                            rovero_challange_1: "Integration of a 3rd party booking system to allow users place their online orders properly without possible mistakes 'on the way'.",
                            rovero_challange_title2: "Simplify Content Management",
                            rovero_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            rovero_challange_title3: "Increase Customer Exposure",
                            rovero_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            rovero_solution_title: "SOLUTION",
                            rovero_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            rovero_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            rovero_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            rovero_techstack: "Tech Stack",
                            rovero_techstack1: "NJS",
                            rovero_techstack2: "WC",
                            rovero_techstack3: "HG",
                            rovero_techstack4: "JML",

                            rovero_results_title: "OUR RESULTS",
                            rovero_result_title1: "⭡ Increase Exposure",
                            rovero_result_description1: "The site's ranking improved over time and its exposure increased.",
                            rovero_result_title2: "⭡ Increase Sales",
                            rovero_result_description2: "The E-commerce site CodeSuits built for the client helped them increase their total sales in the first 3 months by 36%.",
                            rovero_result_title3: "Easy Content Control",
                            rovero_result_description3: "The customer was able to change items and prices easily.",


                        },

                        adel: {

                            adel_page_title: "ADEL BEAUTY",
                            adel_subtitle1: "Beauty & Wellness",
                            adel_subtitle2: "Website for a smal-business in the beauty and wellness field",
                            adel_description: "PROJECT DESCRIPTION",
                            adel_client_title: "The Client",
                            adel_product_title: "The Product",
                            adel_work_title: "The Work",
                            adel_client_description: "A small business that takes its first steps in the field of beauty. It provides nail care and cosmetics services.",
                            adel_product_description: "A new website with a landing page and additional pages including an online purchase option for cosmetic items.",
                            adel_work_description: "We gathered the client's requirements, used a website template to reduce costs for the client and aligned the design and content for the client. Completed the job in 1-2 weeks.",

                            adel_profile: "Profile",
                            adel_profile_country_title: "Country",
                            adel_profile_country_value: "Israel",
                            adel_profile_industry_titile: "Industry",
                            adel_profile_industry_value: "Beauty & Wellness",
                            adel_profile_team_titile: "Team size",
                            adel_profile_team_value: "2 (PM, Fullstack eng.)",

                            adel_goals: "GOALS and OBJECTIVES",
                            adel_goal1: "E-commerce site to place users orders",
                            adel_goal2: "Dynamic content for the client",
                            adel_goal3: "SEO tools to increase site exposure",
                            adel_goal4: "Live Chat & Accessibility widgets",

                            adel_challanges: "CHALLANGES",
                            adel_challange_title1: "Integration Order Solution",
                            adel_challange_1: "Integration of a 3rd party booking system to allow users place their online orders properly without possible mistakes 'on the way'.",
                            adel_challange_title2: "Simplify Content Management",
                            adel_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            adel_challange_title3: "Increase Customer Exposure",
                            adel_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            adel_solution_title: "SOLUTION",
                            adel_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            adel_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            adel_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            adel_techstack: "Tech Stack",
                            adel_techstack1: "NJS",
                            adel_techstack2: "WC",
                            adel_techstack3: "HG",
                            adel_techstack4: "JML",

                            adel_results_title: "OUR RESULTS",
                            adel_result_title1: "⭡ Increase Exposure",
                            adel_result_description1: "The site's ranking improved over time and its exposure increased.",
                            adel_result_title2: "⭡ Increase Sales",
                            adel_result_description2: "The E-commerce site CodeSuits built for the client helped them increase their total sales in the first 4 months by 50%.",
                            adel_result_title3: "Easy Content Control",
                            adel_result_description3: "The customer was able to change items and prices easily.",


                        },


                        king: {

                            king_page_title: "KING SOLOMON HOTEL",
                            king_subtitle1: "Hotel & Tourism",
                            king_subtitle2: "Website for hotel to increase its bookings and engagement with users",
                            king_description: "PROJECT DESCRIPTION",
                            king_client_title: "The Client",
                            king_product_title: "The Product",
                            king_work_title: "The Work",
                            king_client_description: "A 3-star hotel that wanted to replace its old website in order to increase user engagement and bookings.",
                            king_product_description: "We've created a new website from a scratch aligned with the custoemrs requirements. It provided a new booking engine, AI-powered live-chat, campaign tools and more...",
                            king_work_description: "Our team gathered the client's requirements, descided on the right technologies and tools to use, prepared the right design and delivered the site in ~4 weeks.",

                            king_profile: "Profile",
                            king_profile_country_title: "Country",
                            king_profile_country_value: "Israel",
                            king_profile_industry_titile: "Industry",
                            king_profile_industry_value: "Hotels & Tourism",
                            king_profile_team_titile: "Team size",
                            king_profile_team_value: "3 (PM, Fullstack eng., UX/UI)",

                            king_goals: "GOALS and OBJECTIVES",
                            king_goal1: "E-commerce site to place users orders",
                            king_goal2: "Dynamic content for the client",
                            king_goal3: "SEO tools to increase site exposure",
                            king_goal4: "Live Chat & Accessibility widgets",

                            king_challanges: "CHALLANGES",
                            king_challange_title1: "Integration Order Solution",
                            king_challange_1: "Integration of a 3rd party booking system to allow users place their online orders properly without possible mistakes 'on the way'.",
                            king_challange_title2: "Simplify Content Management",
                            king_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            king_challange_title3: "Increase Customer Exposure",
                            king_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            king_solution_title: "SOLUTION",
                            king_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            king_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            king_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            king_techstack: "Tech Stack",
                            king_techstack1: "NJS",
                            king_techstack2: "WC",
                            king_techstack3: "HG",
                            king_techstack4: "JML",

                            king_results_title: "OUR RESULTS",
                            king_result_title1: "⭡ Increase Exposure",
                            king_result_description1: "The site's ranking improved over time and its exposure increased.",
                            king_result_title2: "⭡ Increase Sales",
                            king_result_description2: "The website we built, helped the client increase their sales by 65% in the first 6 months, in addition to other channels (e.g. Booking.com).",
                            king_result_title3: "Easy Content Control",
                            king_result_description3: "The customer was able to change items and prices easily.",


                        },


                        park: {

                            park_page_title: "PARK HOTEL",
                            park_subtitle1: "Hotel & Tourism",
                            park_subtitle2: "Website for hotel to increase its bookings and engagement with users",
                            park_description: "PROJECT DESCRIPTION",
                            park_client_title: "The Client",
                            park_product_title: "The Product",
                            park_work_title: "The Work",
                            park_client_description: "A 3-star hotel that wanted to replace its old website in order to increase user engagement and bookings.",
                            park_product_description: "We've created a new website from a scratch aligned with the custoemrs requirements. It provided a new booking engine, AI-powered live-chat, campaign tools and more...",
                            park_work_description: "Our team gathered the client's requirements, descided on the right technologies and tools to use, prepared the right design and delivered the site in ~4 weeks.",

                            park_profile: "Profile",
                            park_profile_country_title: "Country",
                            park_profile_country_value: "Israel",
                            park_profile_industry_titile: "Industry",
                            park_profile_industry_value: "Hotels & Tourism",
                            park_profile_team_titile: "Team size",
                            park_profile_team_value: "3 (PM, Fullstack eng.,UX/UI)",

                            park_goals: "GOALS and OBJECTIVES",
                            park_goal1: "E-commerce site to place users orders",
                            park_goal2: "Dynamic content for the client",
                            park_goal3: "SEO tools to increase site exposure",
                            park_goal4: "Live Chat & Accessibility widgets",

                            park_challanges: "CHALLANGES",
                            park_challange_title1: "Integration Order Solution",
                            park_challange_1: "Integration of a 3rd party booking system to allow users place their online orders properly without possible mistakes 'on the way'.",
                            park_challange_title2: "Simplify Content Management",
                            park_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            park_challange_title3: "Increase Customer Exposure",
                            park_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            park_solution_title: "SOLUTION",
                            park_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            park_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            park_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            park_techstack: "Tech Stack",
                            park_techstack1: "NJS",
                            park_techstack2: "WC",
                            park_techstack3: "HG",
                            park_techstack4: "JML",

                            park_results_title: "OUR RESULTS",
                            park_result_title1: "⭡ Increase Exposure",
                            park_result_description1: "The site's ranking improved over time and its exposure increased.",
                            park_result_title2: "⭡ Increase Sales",
                            park_result_description2: "The website we built, helped the client increase their sales by 65% in the first 6 months, in addition to other channels (e.g. Booking.com).",
                            park_result_title3: "Easy Content Control",
                            park_result_description3: "The customer was able to change items and prices easily.",


                        },


                        lanas: {

                            lanas_page_title: "LANA'S NAILS",
                            lanas_subtitle1: "Beauty",
                            lanas_subtitle2: "Website for a nails beauty business",
                            lanas_description: "PROJECT DESCRIPTION",
                            lanas_client_title: "The Client",
                            lanas_product_title: "The Product",
                            lanas_work_title: "The Work",
                            lanas_client_description: "A new business for nails care and beauty, willing to promote its business using online site.",
                            lanas_product_description: "We've created a simple website aligned with the custoemr's needs. It provided information on the provided services and additional features to help the client inprove engagement and sales.",
                            lanas_work_description: "We gathered the client's requirements, designed and developed the website. Additionally, we implemented digital marketing features to help the client reach more customers.",

                            lanas_profile: "Profile",
                            lanas_profile_country_title: "Country",
                            lanas_profile_country_value: "Israel",
                            lanas_profile_industry_titile: "Industry",
                            lanas_profile_industry_value: "Beauty",
                            lanas_profile_team_titile: "Team size",
                            lanas_profile_team_value: "3 (PM, Fullstack eng.,UX/UI)",

                            lanas_goals: "GOALS and OBJECTIVES",
                            lanas_goal1: "Dynamic content for the client",
                            lanas_goal2: "SEO tools to increase site exposure",
                            lanas_goal3: "Live Chat & Accessibility widgets",

                            lanas_challanges: "CHALLANGES",
                            lanas_challange_title1: "Integration Order Solution",
                            lanas_challange_1: "Integration of a 3rd party booking system to allow users place their online orders properly without possible mistakes 'on the way'.",
                            lanas_challange_title2: "Simplify Content Management",
                            lanas_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            lanas_challange_title3: "Increase Customer Exposure",
                            lanas_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            lanas_solution_title: "SOLUTION",
                            lanas_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            lanas_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            lanas_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            lanas_techstack: "Tech Stack",
                            lanas_techstack1: "NJS",
                            lanas_techstack2: "WC",
                            lanas_techstack3: "HG",
                            lanas_techstack4: "JML",

                            lanas_results_title: "OUR RESULTS",
                            lanas_result_title1: "⭡ Increase Exposure",
                            lanas_result_description1: "The site's ranking improved over time and its exposure increased.",
                            lanas_result_title2: "⭡ Increase Sales",
                            lanas_result_description2: "The website we built, helped the client increase their sales by 65% in the first 6 months, in addition to other channels (e.g. Booking.com).",
                            lanas_result_title3: "Easy Content Control",
                            lanas_result_description3: "The customer was able to change items and prices easily.",


                        },


                        tracht: {

                            tracht_page_title: "TRACHTENBERG",
                            tracht_subtitle1: "Distillery",
                            tracht_subtitle2: "A site for the distillery to sell its products online and to advertise events and sales campaigns",
                            tracht_description: "PROJECT DESCRIPTION",
                            tracht_client_title: "The Client",
                            tracht_product_title: "The Product",
                            tracht_work_title: "The Work",
                            tracht_client_description: "A distillery business that is ready to promote its business through a website with online sales and special offers.",
                            tracht_product_description: "A website with dynamic content capabilities, online orders and online promotions.",
                            tracht_work_description: "Once UX/UI design was ready, our developers integrated payments, CMS and campaign engines. Work completed in ~2 weeks.",

                            tracht_profile: "Profile",
                            tracht_profile_country_title: "Country",
                            tracht_profile_country_value: "Israel",
                            tracht_profile_industry_titile: "Industry",
                            tracht_profile_industry_value: "Distillery",
                            tracht_profile_team_titile: "Team size",
                            tracht_profile_team_value: "3 (PM, Fullstack eng.,UX/UI)",

                            tracht_goals: "GOALS and OBJECTIVES",
                            tracht_goal1: "Dynamic content for the client",
                            tracht_goal2: "SEO tools to increase site exposure",
                            tracht_goal3: "E-commerce capabilities",
                            tracht_goal4: "Running digital campaigns",

                            tracht_challanges: "CHALLANGES",
                            tracht_challange_title1: "Integration Order Solution",
                            tracht_challange_1: "Integration of a 3rd party booking system to allow users place their online orders properly without possible mistakes 'on the way'.",
                            tracht_challange_title2: "Simplify Content Management",
                            tracht_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            tracht_challange_title3: "Increase Customer Exposure",
                            tracht_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            tracht_solution_title: "SOLUTION",
                            tracht_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            tracht_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            tracht_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            tracht_techstack: "Tech Stack",
                            tracht_techstack1: "NJS",
                            tracht_techstack2: "WC",
                            tracht_techstack3: "HG",
                            tracht_techstack4: "JML",

                            tracht_results_title: "OUR RESULTS",
                            tracht_result_title1: "⭡ Increase Exposure",
                            tracht_result_description1: "The site's ranking improved over time and its exposure increased.",
                            tracht_result_title2: "⭡ Increase Sales",
                            tracht_result_description2: "The website we built, helped the client increase their sales by 65% in the first 6 months, in addition to other channels (e.g. Booking.com).",
                            tracht_result_title3: "Easy Content Control",
                            tracht_result_description3: "The customer was able to change items and prices easily.",


                        },



                        justprint: {

                            jp_page_title: "JUSTPRINT",
                            jp_subtitle1: "E-commerce Printing Website",
                            jp_subtitle2: "Online store connected to a cloud-based CMS and payment system",
                            jp_description: "PROJECT DESCRIPTION",
                            jp_client_title: "The Client",
                            jp_product_title: "The Product",
                            jp_work_title: "The Work",
                            jp_client_description: "The client is a textile and other product printing business that wanted to expand it sales online.",
                            jp_product_description: "The product is an e-commerce site that allows its users to place orders online.",
                            jp_work_description: "After taking the requirements from the client and understanding his needs, our UI/UX provided a client-approved prototype and our fullstack developer created the site.",

                            jp_profile: "Profile",
                            jp_profile_country_title: "Country",
                            jp_profile_country_value: "Israel",
                            jp_profile_industry_titile: "Industry",
                            jp_profile_industry_value: "Printing",
                            jp_profile_team_titile: "Team size",
                            jp_profile_team_value: "3 (PM, Fullstack eng., UX/UI)",

                            jp_goals: "GOALS and OBJECTIVES",
                            jp_goal1: "E-commerce site to place users orders",
                            jp_goal2: "Client can change content dynamically",
                            jp_goal3: "Multiple contact options",
                            jp_goal4: "SEO tools to increase site exposure",
                            jp_goal5: "Provide domain & hosting solutions",

                            jp_challanges: "CHALLANGES",
                            jp_challange_title1: "Simplify Order Placement",
                            jp_challange_1: "Minimize the number of steps required to complete the order for the customer, offering a simple, clear, and ethical flow.",
                            jp_challange_title2: "Simplify Content Management",
                            jp_challange_2: "Create a friendly interface to allow the customer to easily change the items, prices and other items on the site.",
                            jp_challange_title3: "Increase Customer Exposure",
                            jp_challange_3: "Building an exposure strategy through website promotion and other marketing tools to increase the exposure of the site.",

                            jp_solution_title: "SOLUTION",
                            jp_solution_description1: "First, our designer created the user experience and user interface. The prototype was presented to the client.",
                            jp_solution_description2: "Our frontend developer implemented the website based on UX/UI designs.",
                            jp_solution_description3: "We used tools like WooCommerce to handle payments and clearance, and added SEO tools.",

                            jp_techstack: "Tech Stack",
                            jp_techstack1: "PHP",
                            jp_techstack2: "WooCommerce",
                            jp_techstack3: "WordPress",
                            jp_techstack4: "Joomla",

                            jp_results_title: "OUR RESULTS",
                            jp_result_title1: "⭡ Increase Exposure",
                            jp_result_description1: "The site's ranking improved over time and its exposure increased.",
                            jp_result_title2: "⭡ Increase Sales",
                            jp_result_description2: "The E-commerce site CodeSuits built for the client helped them increase their total sales in the first 6 months by 25%.",
                            jp_result_title3: "Easy Content Control",
                            jp_result_description3: "The customer was able to change items and prices easily.",


                        },

                        barifood: {

                            bf_title: "Bari-Food",
                            bf_subtitle1: "A restaurant website",
                            bf_subtitle2: "A Website for a super-fresh healthy food restaurant. We've soloved the customer a problem of unreaching all clients orders by building a smart ordering syst",
                            bf_description: "PROJECT DESCRIPTION",
                            bf_client_title: "The Client",
                            bf_product_title: "The Product",
                            bf_work_title: "The Work",
                            bf_client_description: "The client is a restaurant that is willing to improve its online ordering solution following complaints from their customers about not being able to do it properly.",
                            bf_product_description: "A restaurant website with a description of the menu options and a new feature powered by artificial intelligence that we developed to handle customer booking properly.",
                            bf_work_description: "We created a new website, with a better UX/UI design adapted to the customer's needs. We developed AI models that answered the problems that users faced.",

                            bf_profile: "Profile",
                            bf_profile_country_title: "Country",
                            bf_profile_country_value: "Israel",
                            bf_profile_industry_titile: "Industry",
                            bf_profile_industry_value: "Restaurant",
                            bf_profile_team_titile: "Team Size",
                            bf_profile_team_value: "3 (PM, UX/UI, Full-stack Eng.)",

                            bf_goals: "GOALS and OBJECTIVES",
                            bf_goal1: "Dealing with the bad in-site user experience of not being able to make a restaurant reservation properly.",
                            bf_goal2: "Improve the UX/UI design of the site.",
                            bf_goal3: "Provide the customer with the opportunity to update the site's content from time to time.",
                            bf_goal4: "Increase website exposure by adding SEO solutions.",

                            bf_challanges: "CHALLANGES",
                            bf_challange_title1: "Solve sync problens",
                            bf_challange_1: "Address synchronization issues between the restaurant's online and offline systems that result in ordering conflicts, or, lost customer orders.",
                            bf_challange_title2: "Increase website exposure",
                            bf_challange_2: "Find marketing solutions to increase the exposure of the customer in the search of users for healthy food restaurants.",
                            bf_challange_title3: "Increase reservations",
                            bf_challange_3: "Developed an AI powered solution to solve the major problem of lost customer orders.",

                            bf_solution_title: "SOLUTION",
                            bf_solution_description1: "A new website with a simple user experience and an attractive user interface design.",
                            bf_solution_description2: "A new solution for synchronizing online and offline systems.",
                            bf_solution_description3: "Include SEO tools to increase the site's visibility in users' search results.",

                            bf_techstack: "Tech Stack",
                            bf_techstack1: "WordPress",
                            bf_techstack2: "Joomla",
                            bf_techstack3: "Python",
                            bf_techstack4: "AWS",

                            bf_results_title: "OUR RESULTS",
                            bf_result_title1: "⭡ Increase Reservations",
                            bf_result_description1: "Increase users reservations by 35%",
                            bf_result_title2: "⭣ Reservation Conflicts",
                            bf_result_description2: "Reduction in order conflicts thanks to the new synchronization solutions.",
                            bf_result_title3: "⭡ Increase Exposure",
                            bf_result_description3: "Analytics tools have shown a ~20-25% increase in orders from users who came to the site by finding it in their search results.",

                        },

                        wewear: {

                            ww_title: "WeWear",
                            ww_subtitle1: "Fashion online store with AI Chatbot functionlities",
                            ww_subtitle2: "High-Load Support AI Chatbot for an Online Fashion Store.",
                            ww_description: "PROJECT DESCRIPTION",
                            ww_client_title: "The Client",
                            ww_product_title: "The Product",
                            ww_work_title: "The Work",
                            ww_client_description: "Our client is a B2C eCommerce company operating in the fashion niche. The organization sells a wide range of clothes and accessories online for customers all over the UK.",
                            ww_product_description: "The product is an AI–powered E-commerce fashion website with chatbot for resolving customer queries. It is capable of processing high-load customer requests and present end-user campaigs with relevant offers based on prediction of future purchases",
                            ww_work_description: "Build an E-commerce website, with content management and clearence solutions. Frontend design and implementation. Using AI tools to handle users requests and predict future purchases to increase the customer's sales.",

                            ww_profile: "Profile",
                            ww_profile_country_title: "Country",
                            ww_profile_country_value: "United Kingdom",
                            ww_profile_industry_titile: "Industry",
                            ww_profile_industry_value: "Fashion",
                            ww_profile_team_titile: "Team size",
                            ww_profile_team_value: "5 (PM, ML/AI, Fullstack devs., BI)",

                            ww_goals: "GOALS and OBJECTIVES",
                            ww_goal1: "Build E-commerce fashion website",
                            ww_goal2: "Customer can change the content",
                            ww_goal3: "Connect the site with clearence",
                            ww_goal4: "Automate customer support",
                            ww_goal5: "Make Chatbot handle high peak loads",

                            ww_challanges: "CHALLANGES",
                            ww_challange_title1: "Unified Data Format",
                            ww_challange_1: "Process the data from the website in a format that the chatbot can use. This involves properly parsing, gathering, formatting, and storing data in a vector database.",
                            ww_challange_title2: "Responses Accuracy",
                            ww_challange_2: "Fine-tune the chatbot to retrieve information from the vector database and answer user questions accurately. This required us creating an agent chatbot that uses appropriate tools and additional guardrails to ensure it provides correct and complete responses without hallucination.",
                            ww_challange_title3: "Scale the Chatbot",
                            ww_challange_3: "Deploy the chatbot to handle high peak loads, particularly during working hours and weekends. For that, our team needed to ensure the system could scale up and down when needed to maintain performance.",

                            ww_solution_title: "SOLUTION",
                            ww_solution_description1: "As the first step, we started working on the chatbot’s architecture. We chose LangChain agent powered by Mistral LLM to serve as the foundation of the solution’s design. This component receives user queries and sifts through several data sources to craft the most relevant response. The data, including parsed website content stored in S3 buckets (AWS storage), is housed within a vector database built on PostgreSQL and deployed on AWS. This database automatically converts all information into a vector format, enabling fast retrieval for accurate responses.",
                            ww_solution_description2: "Once ready, the entire solution was packaged as an API with configurations. We also provided extensive documentation to facilitate easy integration with the client’s existing web infrastructure.",
                            ww_solution_description3: "Finally, the chatbot deployment was managed using EC2 instances with Kubernetes scripts to enable autoscaling, ensuring the system could handle high peak loads efficiently.",

                            ww_techstack: "Tech Stack",
                            ww_techstack1: "WPS",
                            ww_techstack2: "PHP",
                            ww_techstack3: "N.JS",
                            ww_techstack4: "AWS",
                            ww_techstack5: "Python",

                            ww_results_title: "OUR RESULTS",
                            ww_result_title1: "Latency less 5s",
                            ww_result_description1: "The chatbot delivers responses within less than 5 seconds for 99% of user queries.",
                            ww_result_title2: "100 Req. per sec.",
                            ww_result_description2: "The system can be constantly available during peak usage times to handle a high volume of user queries without interruption.",
                            ww_result_title3: "Sales increased in 55%",
                            ww_result_description3: "The revenue increased in the 1st half year in 55%. Requests and offer prediction allowed handling customers properly and produce a better revenue by targeting the right audience with relevant content on the right time.",


                        },

                        pets: {

                            pets_title: "PETS",
                            pets_subtitle1: "An online e-commerce site selling pet, pet food and accessories",
                            pets_subtitle2: "We created a website for the client to sell his pet products. Using an easy-to-use flow we developed, users could easily navigate, purchase products and re-order previous purchases to save time.",
                            pets_description: "PROJECT DESCRIPTION",
                            pets_client_title: "The Client",
                            pets_product_title: "The Product",
                            pets_work_title: "The Work",
                            pets_client_description: "The client is a store that sells pets and pet food and accessories. The owner wanted to improve his sales and replace his old website that did not support online sales.",
                            pets_product_description: "The product is an e-commerce site that allows users to make online purchases. The content is dynamic and controlled by the business. The website also calculates and informs the customer about orders that need to be placed for the inventory.",
                            pets_work_description: "We designed the UX/UI of the website and other relevant functionalities like online booking and confirmation. We have created an automation system to order out of stock items after they have been purchased by users.",

                            pets_profile: "Profile",
                            pets_profile_country_title: "Country",
                            pets_profile_country_value: "Israel",
                            pets_profile_industry_titile: "Industry",
                            pets_profile_industry_value: "Pets",
                            pets_profile_team_titile: "Team size",
                            pets_profile_team_value: "4 (PM, UX/UI, Fullstack Eng., AI Eng.)",

                            pets_goals: "GOALS and OBJECTIVES",
                            pets_goal1: "Online store with payment capabilities",
                            pets_goal2: "Automated inventory manager",
                            pets_goal3: "Campaigns alerts solution",
                            pets_goal4: "CMS for dynamic content",

                            pets_challanges: "CHALLANGES",
                            pets_challange_title1: "Inventory Automation Integration",
                            pets_challange_1: "Integrating our solution with the existing inventory solution used by the customer.",
                            pets_challange_title2: "Campaign Accuracy",
                            pets_challange_2: "Create an accurate AI-based campaign solution based on users' past actions. It was expected to provide 80% accuracy.",
                            pets_challange_title3: "CMS & Payments",
                            pets_challange_3: "Adding CMS for dynamic content and payments solutions.",

                            pets_solution_title: "SOLUTION",
                            pets_solution_description1: "The work started with creating a proper UX/UI for the website and designing a technical architecture for the technical solution. A prototype was presented to the client.",
                            pets_solution_description2: "Created a technical design for the ML models running in the cloud and the collected data, and an API to communicate with the website.",
                            pets_solution_description3: "Added CMS functionality and in-site payments capabilities.",

                            pets_techstack: "Tech Stack",
                            pets_techstack1: "WPS",
                            pets_techstack2: "React",
                            pets_techstack3: "WooCommerce",
                            pets_techstack4: "N.JS",

                            pets_results_title: "OUR RESULTS",
                            pets_result_title1: "⭡ Sales Increase",
                            pets_result_description1: "Total sales increased by about 30% thanks to the development of an online store with a friendly user experience and supported push notification campaigns.",
                            pets_result_title2: "⭡ Time Efficiency",
                            pets_result_description2: "The automation solution CodeSuits developed to improve the way inventory is managed saved the client ~38% process time compared to the old site.",
                            pets_result_title3: "⭡ Engagement",
                            pets_result_description3: "The new website helped the client to increase engagement with his customers.",


                        },


                        squid: {

                            squid_title: "SQUID",
                            squid_subtitle1: "CRM Platform",
                            squid_subtitle2: "SaaS-based Web and Mobile application with AI capabilities.",
                            squid_description: "PROJECT DESCRIPTION",
                            squid_client_title: "The Client",
                            squid_product_title: "The Product",
                            squid_work_title: "The Work",
                            squid_client_description: "The client is a B2B2C startup that needed help with building a cross-platofrm CRM product. Our team integrated the customer's dev and product teams and joined daily meetings for bringing this solution to the finish line on time.",
                            squid_product_description: "The product is a SaaS-based CRM platform enhanced with LLM & AI capabilities to provife a better customer reltionship management.",
                            squid_work_description: "We've joined the customer during the initial phase while the product was very amature. Our technical team recommended on an architecture design for the platform in which we joined later on the development phase. Our UX/UI prepared a prototype of the expected design and later advanced visuals.",

                            squid_profile: "Profile",
                            squid_profile_country_title: "Country",
                            squid_profile_country_value: "USA",
                            squid_profile_industry_titile: "Industry",
                            squid_profile_industry_value: "CRM",
                            squid_profile_team_titile: "Team size",
                            squid_profile_team_value: "6 (PM, ML/AI, Fullstack + Mobile eng.)",

                            squid_goals: "GOALS and OBJECTIVES",
                            squid_goal1: "Build a Cloud-based Web application",
                            squid_goal2: "Sync with Android and iOS Mobile app",
                            squid_goal3: "Enhance CRM by AI functionalities",
                            squid_goal4: "Support millions of users",

                            squid_challanges: "CHALLANGES",
                            squid_challange_title1: "Simplify User Flow",
                            squid_challange_1: "Simplify the user experience and the user flow required to complete any action in the system (adding a new contact, card, etc..).",
                            squid_challange_title2: "Inefficient Workflow",
                            squid_challange_2: "Digitize and centralize all business-critical tasks as users have managed until using this product, actions like order tracking and finance integration of applications such as Microsoft Excel, notepad and e-mail.",
                            squid_challange_title3: "Load Balancing",
                            squid_challange_3: "Build a system with cloud-based services to support millions of users while keeping the project on a reasonable budget.",

                            squid_solution_title: "SOLUTION",
                            squid_solution_description1: "After designing the architecture using AWS cloud-based services, it was presented to the customer and approved.",
                            squid_solution_description2: "Our backend engineer started working on the setup while rest of team worked on frontend, mobile apps and data models to be use.",
                            squid_solution_description3: "The data models, writtern in Python, were integrated as a cloud service being used by the Web and Mobile applications.",

                            squid_techstack: "Tech Stack",
                            squid_techstack1: "PHP",
                            squid_techstack2: "N.JS",
                            squid_techstack3: "AWS",
                            squid_techstack4: "Kotlin",
                            squid_techstack5: "Swift",
                            squid_techstack6: "Python",

                            squid_results_title: "OUR RESULTS",
                            squid_result_title1: "⭡ Lead Generation",
                            squid_result_description1: "Thanks to the design our team made, in both the technical and the user-interface scopes, it made the process of generating and managing new leads more effecient and easy.",
                            squid_result_title2: "20% Less Time Spend",
                            squid_result_description2: "Using our AI solutions helped reducing time spend for managing tasks in ~20%.",
                            squid_result_title3: "Better Orchestration",
                            squid_result_description3: "We've developed AI-powered tools to improve the Omnichannel Orchestration.",


                        },


                        quickspace: {

                            qs_title: "QUICKSPACE",
                            qs_subtitle1: "Real Estate Cloud-based website",
                            qs_subtitle2: "Cloud-based website with AI features to provide better results for the end-users.",
                            qs_description: "PROJECT DESCRIPTION",
                            qs_client_title: "The Client",
                            qs_product_title: "The Product",
                            qs_work_title: "The Work",
                            qs_client_description: "The client is a real estate company with an old website that is ready to replace it with a new one having new technologies",
                            qs_product_description: "A cloud-based site that shows users similar results based on their search input and personal taste.",
                            qs_work_description: "We built a website connected to AWS cloud so users could browse our listings, or, by using AI-models, it is actively display results based on the user inputs.",

                            qs_profile: "Profile",
                            qs_profile_country_title: "Country",
                            qs_profile_country_value: "USA",
                            qs_profile_industry_titile: "Industry",
                            qs_profile_industry_value: "Real estate",
                            qs_profile_team_titile: "Team size",
                            qs_profile_team_value: "4 (PM, UX/UI, Fullstack eng. AI eng.)",

                            qs_goals: "GOALS and OBJECTIVES",
                            qs_goal1: "Building a real estate website",
                            qs_goal2: "Adding CMS for dynamic content",
                            qs_goal3: "Cloud data management",
                            qs_goal4: "AI models development",

                            qs_challanges: "CHALLANGES",
                            qs_challange_title1: "Results Latency",
                            qs_challange_1: "We have taken into account possible latency in displaying results to the user, which may be affected by network performance and data manipulation in the cloud",
                            qs_challange_title2: "Data integration",
                            qs_challange_2: "We needed to create proper integration for our AWS cloud services with the customer's existing data sources to generate accurate results.",
                            qs_challange_title3: "Load Balancing",
                            qs_challange_3: "Our solution had to support millions of users without impacting performance.",

                            qs_solution_title: "SOLUTION",
                            qs_solution_description1: "Our teams created the high-level architecture and UX/UI design. We understood which services inside AWS needs to be used.",
                            qs_solution_description2: "Using TensorFlow and Kaggle datasets, our AI data scientist developed models to provide similar results for given samples",
                            qs_solution_description3: "We integrated our database with existing user sources, integrated all solutions for deployment and checked all requirements are properly implemented.",

                            qs_techstack: "Tech Stack",

                            qs_techstack1: "PHP",
                            qs_techstack2: "N.JS",
                            qs_techstack3: "Python",
                            qs_techstack4: "WPS",

                            qs_results_title: "OUR RESULTS",
                            qs_result_title1: "⭡ Increased Revenue",
                            qs_result_description1: "By providing a personalization-wise solution, users' search resulted in a higher rate for matching their property, which led to an increase in customer revenue.",
                            qs_result_title2: "Improving Measurement",
                            qs_result_description2: "The tools we developed allowed the client to improve the way they measure user performance within the site.",
                            qs_result_title3: "Better CRM Management",
                            qs_result_description3: "The solution we developed, including integration with their CRM system, allowed the client to improve their customer management through the website.",


                        },


                        saasly: {

                            saasly_title: "SAASLY",
                            saasly_subtitle1: "Advanced Analytics platform",
                            saasly_subtitle2: "A B2B cloud-based platform to help companies analyze their product's performance including tools to achieve better results in terms of users most important metrics.",
                            saasly_description: "PROJECT DESCRIPTION",
                            saasly_client_title: "The Client",
                            saasly_product_title: "The Product",
                            saasly_work_title: "The Work",
                            saasly_client_description: "The client is a startup in the early stages of creating this product and has limited resources and technological know-how.",
                            saasly_product_description: "A cloud-based data-driven solution which integrates with customers products using a dedicateed API and analyze their performance.",
                            saasly_work_description: "We collected the requirements from the client and provided a high level architectural design. Our UX/UI designer created a designed prototype that helped build on the next stage of development.",

                            saasly_profile: "Profile",
                            saasly_profile_country_title: "Country",
                            saasly_profile_country_value: "UK",
                            saasly_profile_industry_titile: "Industry",
                            saasly_profile_industry_value: "SW",
                            saasly_profile_team_titile: "Team size",
                            saasly_profile_team_value: "5 (PM, Fullstack Engs., UX/UI)",

                            saasly_goals: "GOALS and OBJECTIVES",
                            saasly_goal1: "Create dashboard with different analytics features.",
                            saasly_goal2: "Implementation of management tools for the client.",
                            saasly_goal3: "Building an API layer for integration with customer products.",
                            saasly_goal4: "Design and implement cloud-based services for data handling.",

                            saasly_challanges: "CHALLANGES",
                            saasly_challange_title1: "Data Accuracy",
                            saasly_challange_1: "The core mission of the product is to provide accurate analytical information. Therefore, it is very important to make sure that the data is managed and operated properly.",
                            saasly_challange_title2: "Performance",
                            saasly_challange_2: "It is important that the data be calculated in the cloud and send results back to the frontend with a minimum delay.",
                            saasly_challange_title3: "User Experience",
                            saasly_challange_3: "Since this is a data-driven product, full with data and numeric information, it was even more important to create a friendly user experience and user interface product, to ease the user experience inside the product.",

                            saasly_solution_title: "SOLUTION",
                            saasly_solution_description1: "We created the architecture design with a flow of data management between the cloud services and the front end interface.",
                            saasly_solution_description2: "Our designer created the user experience design, so he helped understand how the functionality should be presented and adapt it to the application.",
                            saasly_solution_description3: "We tested the solution with various products on the market and with the client's design partners to ensure that the integration works correctly with their products.",

                            saasly_techstack: "Tech Stack",
                            saasly_techstack1: "React",
                            saasly_techstack2: "N.JS",
                            saasly_techstack3: "AWS",
                            saasly_techstack4: "MySQL",
                            saasly_techstack5: "Python",

                            saasly_results_title: "OUR RESULTS",
                            saasly_result_title1: "GA Product",
                            saasly_result_description1: "We helped the client deliver a B2B product with full MVP functionality within a 9-month timeframe.",
                            saasly_result_title2: "Accurate Results",
                            saasly_result_description2: "The product was able to produce in the first edition of GA an analytical figure with an accuracy of 92% that improved over time.",
                            saasly_result_title3: "Reducing Latency ",
                            saasly_result_description3: "We've improved our solution during time in which it was able to provide results with less than 3 sec.",
                        },

                        logoipsum: {

                            logo_title: "LOGOIPSUM",
                            logo_subtitle1: "Business management platform",
                            logo_subtitle2: "We joined the LOGOIPSUM's team to develop a management tool designed for organizations that are ready to improve their work approach.",
                            logo_description: "PROJECT DESCRIPTION",
                            logo_client_title: "The Client",
                            logo_product_title: "The Product",
                            logo_work_title: "The Work",
                            logo_client_description: "The client is a young software company with low resources that wanted to speed up the development process of its product. We joined with our knowledge and experience and assisted in development and consulting to shorten the release process.",
                            logo_product_description: "The product is a web and mobile 'work management' platform designed to help teams organize, track and manage their work.",
                            logo_work_description: "We joined the organic team and helped plan the architecture solution. Our UX/UI designer provided the designs and prototype for each platform. Finally, we connected the cloud solution with the mobile and web applications.",

                            logo_profile: "Profile",
                            logo_profile_country_title: "Country",
                            logo_profile_country_value: "USA",
                            logo_profile_industry_titile: "Industry",
                            logo_profile_industry_value: "SW",
                            logo_profile_team_titile: "Team size",
                            logo_profile_team_value: "6 (PM, Mobile, FE & Cloud Eng.,UX/UI)",

                            logo_goals: "GOALS and OBJECTIVES",
                            logo_goal1: "Build Cross-platform product",
                            logo_goal2: "Create AI-based tools",
                            logo_goal3: "Cloud data manager",
                            logo_goal4: "RESTful API interface",

                            logo_challanges: "CHALLANGES",
                            logo_challange_title1: "Cross-platform Sync",
                            logo_challange_1: "Building a cross-platform solution raises multiple challenges. For example, developing a feature on Android may not work on iOS due to platform limitations.",
                            logo_challange_title2: "Data Synch",
                            logo_challange_2: "It was challenging to sync the cloud data with each platform properly. The server we built had to support millions of requests from each platform and equalize the response between the two.",
                            logo_challange_title3: "Load Balancing",
                            logo_challange_3: "The server had to properly support and manage millions of requests with scaling capabilities.",

                            logo_solution_title: "SOLUTION",
                            logo_solution_description1: "We designed and implemented the database management using AWS services like DynamoDB, S3, etc.",
                            logo_solution_description2: "We use AWS DataSync to improve the synchronization of the 3 platforms.",
                            logo_solution_description3: "Based on the requirements of the client's product team, our UX/UI designer, using tools such as Figma and Adobe Illustrator, created the relevant designs and prototypes.",

                            logo_techstack: "Tech Stack",
                            logo_techstack1: "R.Native",
                            logo_techstack2: "N.JS ",
                            logo_techstack3: "Python",
                            logo_techstack4: "AWS",

                            logo_results_title: "OUR RESULTS",
                            logo_result_title1: "Time Alignment",
                            logo_result_description1: "CodeSuits helped the client deploy the GA release on time after ~10 months of intensive work.",
                            logo_result_title2: "⭡ Users Acquisition",
                            logo_result_description2: "The friendly interface that our team created, and the back-end application, along with good marketing work by our client, helped the product to be adopted by many customers.",
                            logo_result_title3: "Measurement",
                            logo_result_description3: "The analytical tool we built helps the client measure the success of their product.",
                        },

                        fastgrab: {

                            fg_title: "FASTGRAB",
                            fg_subtitle1: "Food delivery app",
                            fg_subtitle2: "A food delivery app for Android and iPhone devices. Feature examples: Food order, purchasing and clearance. Dynamic content control by client. Chat feature. App performance analytics.",
                            fg_description: "PROJECT DESCRIPTION",
                            fg_client_title: "The Client",
                            fg_product_title: "The Product",
                            fg_work_title: "The Work",
                            fg_client_description: "The client is a new food delivery company that was looking for a mobile solution for their business.",
                            fg_product_description: "The product is a B2C mobile application for Android and iPhone devices. Customers can order food from the platform's restaurant and cafe partners, or pick up the order or receive it from the platform's delivery partners.",
                            fg_work_description: "Matching the requirements with the customer. Prepare technical and וUX/UI designs. Started working on the cloud-based solution and mobile development.",

                            fg_profile: "Profile",
                            fg_profile_country_title: "Country",
                            fg_profile_country_value: "UK",
                            fg_profile_industry_titile: "Industry",
                            fg_profile_industry_value: "Food",
                            fg_profile_team_titile: "Team size",
                            fg_profile_team_value: "5 (PM, Mobile & Cloud Eng, UX/UI)",

                            fg_goals: "GOALS and OBJECTIVES",
                            fg_goal1: "Build a delivery app",
                            fg_goal2: "Food businesses can register",
                            fg_goal3: "Customers can order",
                            fg_goal4: "Delivery partners can get deliveries",
                            fg_goal5: "Sync Android & iOS apps",

                            fg_challanges: "CHALLANGES",
                            fg_challange_title1: "Sync & Orchestration",
                            fg_challange_1: "It was a challenge to orchestrate all the entities of the app to make it work together properly.",
                            fg_challange_title2: "Time limitation",
                            fg_challange_2: "It is required to implement the application from scratch within 10-12 months.",
                            fg_challange_title3: "Cross-platform Limitations",
                            fg_challange_3: "Some features that are seamlessly integrated in Android are not supported in iOS and vice versa.",

                            fg_solution_title: "SOLUTION",
                            fg_solution_description1: "Our UX/UI designer created a Figma prototype tailored to the client's requirements. Our technical engineers worked on the technical architecture of the solution.",
                            fg_solution_description2: "At the same time, we started developing a cloud-based solution using AWS services, and to align with the time limit we developed a cross-platform mobile solution using React Native.",
                            fg_solution_description3: "We overcame limitation issues on the mobile platforms, and came up with a creative approach to implement the required features on both platforms.",

                            fg_techstack: "Tech Stack",
                            fg_techstack1: "React",
                            fg_techstack2: "N.JS",
                            fg_techstack3: "Python",
                            fg_techstack4: "AWS",

                            fg_results_title: "OUR RESULTS",
                            fg_result_title1: "Time Alignment",
                            fg_result_description1: "We released a cross-platform delivery app on time that matched our customer's requirements and gave them value.",
                            fg_result_title2: "⭡ User acquisition",
                            fg_result_description2: "The solution we developed for the customer helped them product we developed",
                            fg_result_title3: "⭡ Increase in sales",
                            fg_result_description3: "Our work helped the client to make raevenu by providing values ​​to its users.",
                        },

                        healthness: {

                            health_title: "HEALTHNESS",
                            health_subtitle1: "Health care app",
                            health_subtitle2: "Developing an AI-Powered Android and iOS App with Computer Vision for Face Wellness Analysis.",
                            health_description: "PROJECT DESCRIPTION",
                            health_client_title: "The Client",
                            health_product_title: "The Product",
                            health_work_title: "The Work",
                            health_client_description: "A B2C company operating in the Healthcare industry. The mission of the company is to help people take care of their health and well-being, providing feedback on the process of aging and giving actionable advice on aesthetic wellness.",
                            health_product_description: "A Mobile app for health and beauty monitoring, designed to serve aged 18-60 Americans, who are mostly healthy but want to monitor their health to prolong their lifespan.",
                            health_work_description: "Designed a groundbreaking system that can centralize healthcare data and come up with one number score to reflect the overall health span of a user and help to live a longer, healthier, and happier life.",

                            health_profile: "Profile",
                            health_profile_country_title: "Country",
                            health_profile_country_value: "USA",
                            health_profile_industry_titile: "Industry",
                            health_profile_industry_value: "Healthcare",
                            health_profile_team_titile: "Team size",
                            health_profile_team_value: "6-8 (PM, ML/AI, Fronetend, BI and Mobile)",

                            health_goals: "GOALS and OBJECTIVES",
                            health_goals_subtitle: "Developing an AI-Powered iOS App with Computer Vision for Face Wellness Analysis",
                            health_goal1: "Consolidate Multiple Health Metrics into a Single Score",
                            health_goal2: "Help users understand their health status relative to their peers",
                            health_goal3: "Provide Timely and Actionable Health Alerts to Users",
                            health_goal4: "Facilitate Access to Top-Rated Healthcare Professionals and Clinics",
                            health_goal5: "Provide Users with Accurate Skin Condition Assessments Using Visual Data",

                            health_challanges: "CHALLANGES",
                            health_challange_title1: "Data Aggregation",
                            health_challange_1: "Find a way to effectively collect user data from smart devices using bioelectrical impedance to create an ML-powered score.",
                            health_challange_title2: "Vision Integration",
                            health_challange_2: "Implement our extensive expertise in Computer Vision to develop features including facial imperfection analysis, aging tracking, and apparent age estimation.",
                            health_challange_title3: "Accuracy",
                            health_challange_3: "Deal with all challenges related to implementing a Retrieval-Augmented Generation (RAG) chatbot with a Large Language Model (LLM) to explain facial analysis results and provide personalized recommendations.",

                            health_solution_title: "SOLUTION",
                            health_solution_description1: "During the development process, our team worked in a closed cohesion with a doctor who had a deep knowledge of Artificial Intelligence and Machine Learning, as well as a User Experience/User Interface designer on the client’s side.",
                            health_solution_description2: "To build a highly precise solution, we utilized a custom dataset labeled under the direction of medical experts for the training of our AI/ML models. To ensure the quality and reliability of data, we conducted regular data quality reviews under the guidance of medical experts involved in the project.",
                            health_solution_description3: "Our team delivered this solution in the form of an Android and iOS applications that connects user smart devices and aggregates data powered with ML models wrapped into API.",

                            health_techstack: "Tech Stack",
                            health_techstack1: "AWS",
                            health_techstack2: "Kotlin",
                            health_techstack3: "Swift",
                            health_techstack4: "Python",

                            health_results_title: "OUR RESULTS",
                            health_result_title1: "Device & API Integrations",
                            health_result_description1: "Our team delivered this solution in the form of an Android & iOS application that connects user smart devices and aggregates data powered with ML models wrapped into API.",
                            health_result_title2: "85+% Accuracy Rate of AI/ML Models",
                            health_result_description2: "Ensured the highest possible accuracy rate of the machine learning models at the core of our healthcare app, significantly contributing to cutting-edge user experience and healthcare suggestions reliability.",
                            health_result_title3: "AI Expertise at Scale",
                            health_result_description3: "Delivered our extensive expertise in Machine Learning, Computer Vision, Retrieval-Augmented Generation, and Large Language Models to develop a unique functionality in a powerful Android & iOS app.",
                        },

                        rides: {

                            rides_title: "RIDES",
                            rides_subtitle1: "App for a bike shop",
                            rides_subtitle2: "Flutter application for Android and iOS made for a bicycle shop. Feature examples: purchasing and clearance, dynamic content control. App performance analytics.",
                            rides_description: "PROJECT DESCRIPTION",
                            rides_client_title: "The Client",
                            rides_product_title: "The Product",
                            rides_work_title: "The Work",
                            rides_client_description: "A bicycle shop that wanted to expand its offline activity and increase its exposure with more users through a mobile application.",
                            rides_product_description: "B2C mobile app for Android and iOS. Allow users to order bikes and accessories, but also create a community of riders by providing information about GPS-based rides and trails.",
                            rides_work_description: "Our team designed the architecture and UX/UI design for both Android and iOS. We developed cloud-based solutions for data management and communication with the application, designed payment and confirmation functions, and built GPS-based solution for riding trails.  ",

                            rides_profile: "Profile",
                            rides_profile_country_title: "Country",
                            rides_profile_country_value: "UK",
                            rides_profile_industry_titile: "Industry",
                            rides_profile_industry_value: "Bicycle",
                            rides_profile_team_titile: "Team size",
                            rides_profile_team_value: "5 (PM, Mobile + Cloud Eng., UX/UI",

                            rides_goals: "GOALS and OBJECTIVES",
                            rides_goal1: "E-commerce app",
                            rides_goal2: "Android & iOS impl.",
                            rides_goal3: "Dynamic content",
                            rides_goal4: "Trails data",

                            rides_challanges: "CHALLANGES",
                            rides_challange_title1: "GPS-based Accuracy",
                            rides_challange_1: "The app provided and allowed users to upload and download GPS-based cycling trials shared by other users. The challenge we faced was implementing a trial presentation on each platform and the required accuracy.",
                            rides_challange_title2: "Data Sync",
                            rides_challange_2: "We needed to build a cloud-based solution that will synchronize the data on both platforms properly.",
                            rides_challange_title3: "E-commerce Application",
                            rides_challange_3: "Payments and confirmation from both platforms are required to work with one account of the same solution.",

                            rides_solution_title: "SOLUTION",
                            rides_solution_description1: "We adapted our technical plan and UX/UI design to the product requirements we defined together with the client.",
                            rides_solution_description2: "Our teams started working on the cloud solution using AWS services and we chose Flutter as a cross-platform solution to accelerate mobile development.",
                            rides_solution_description3: "We integrated the solution with a payment system that supported easily both platforms and it was aligned with the data we managed with our cloud-services.",
                            rides_solution_description4: "As the request to build a community of riders, we built a solution that would allow users to exchange and use a GPS-based ride trial solution for sharing and viewing within the app.",

                            rides_techstack: "Tech Stack",
                            rides_techstack1: "Flutter",
                            rides_techstack2: "Zelle",
                            rides_techstack3: "Python",
                            rides_techstack4: "AWS",

                            rides_results_title: "OUR RESULTS",
                            rides_result_title1: "⭡ Increase income",
                            rides_result_description1: "The e-commerce solution for Android and iOS helped the client increase its total revenue from new online users.",
                            rides_result_title2: "⭡ Acquisition & Engagement",
                            rides_result_description2: "The community solution we developed helped the client increase engagement with existing users and acquire new users who are willing to use the in-app riding information, share information with other users, and actually buy items from the app.",
                            rides_result_title3: "Time Effeciency",
                            rides_result_description3: "The development of the applications for the required features, on both platforms connected to the cloud services, has been adjusted to the time frame of ~8 months.",
                        },

                        netpay: {

                            netpay_title: "NETPAY",
                            netpay_subtitle1: "Digital wallet for a finanace company",
                            netpay_subtitle2: "Development of innovative expense management application",
                            netpay_description: "PROJECT DESCRIPTION",
                            netpay_client_title: "The Client",
                            netpay_product_title: "The Product",
                            netpay_work_title: "The Work",
                            netpay_client_description: "The client is a leading institution pioneering innovative financial management solutions, known for expertise in strategic planning, risk management, investment advisory, and fintech integration.",
                            netpay_product_description: "A digital wallet application to allow users make transactions and manage expenses in a betetr way.",
                            netpay_work_description: "Build an iOS and Android wallet application, that allow making transactions using different currencies and assists its users manage their expenses in a better way. To aligned with the Finanace industry standards, it was required keeping the data collected safe and secured.",

                            netpay_profile: "Profile",
                            netpay_profile_country_title: "Country",
                            netpay_profile_country_value: "USA",
                            netpay_profile_industry_titile: "Industry",
                            netpay_profile_industry_value: "Fintech",
                            netpay_profile_team_titile: "Team size",
                            netpay_profile_team_value: "5 (PM, Mobile, Cloud, Security Eng., UX/UI)",

                            netpay_goals: "GOALS and OBJECTIVES",
                            netpay_goal1: "Build payments management app",
                            netpay_goal2: "Automate customer support",
                            netpay_goal3: "Develop AI-models to monitor expenses",
                            netpay_goal4: "Secure Mobile and Cloud data",

                            netpay_challanges: "CHALLANGES",
                            netpay_challange_title1: "Holistic Solution",
                            netpay_challange_1: "Integrate the app with the company's systems for making this holistic solution happen.",
                            netpay_challange_title2: "Data Accuracy",
                            netpay_challange_2: "Due to its delicate, it was highly important handling the data transfered from the app to the servers, and vice versa, in the most accurate way.",
                            netpay_challange_title3: "Security & Privacy",
                            netpay_challange_3: "As for the well-known risks of a digital wallet, it was crucial to develop a secured solution to guarantee the safety of the users data and privacy.",

                            netpay_solution_title: "SOLUTION",
                            netpay_solution_description1: "To address the client's challenges, CodeSuits has developed a user-friendly application that aims to simplify expense tracking and financial management through a suite of highly effective features. This app allowed users to be notified in time, get clear dashboard images for upcoming expense reminders, and get insights into income, expenses, and balance. With Expense Manager, users can effortlessly manage credit/debit cards via scanning or manual entry, along with subject options. The main focus was on simplicity and convenience, as the application serves as a valuable tool for maintaining financial organization and controlling expenses.",
                            netpay_solution_description2: "We integrated the app with the company's systems and confirmed the data is synced properly on both directions.",
                            netpay_solution_description3: "Finally, we've secured the data using TLSv1.3 and aligned the solution with the leading payment standard 'PCI DSS'.",

                            netpay_techstack: "Tech Stack",
                            netpay_techstack1: "Swift",
                            netpay_techstack2: "Kotlin",
                            netpay_techstack3: "AWS",
                            netpay_techstack4: "Python",

                            netpay_results_title: "OUR RESULTS",
                            netpay_result_title1: "30% Mgmt. Improve",
                            netpay_result_description1: "30% improved clarity regarding spending habits and expense management.",
                            netpay_result_title2: "↓ 70% Late Fees",
                            netpay_result_description2: "70% decrease in late fees and penalties due to timely payment reminders.",
                            netpay_result_title3: "↑ 39% Engagemnt",
                            netpay_result_description3: "The overall engagement with the company's products increased in 39% after the application was released.",
                        },

                        ux: {

                            ux_title: "USER EXPERIENCE",
                            ux_subtitle1: "We create clear, simple and friendly UX designs to help users easily navigate themselves within the product",

                            ux_category_title1: "User Experience (UX)",
                            ux_category_description1: "Your mobile, or, web application, can provide the best value to its users, but without a good user experience (UX), or a user interface (UI) implemantaton, they might be lost and make your product's churn increase.",

                            ux_category_title2: "User Interface (UI)",
                            ux_category_description2: "We create clear and attractive user interface designs to enrich the user experience of the product by conforming to design concepts and using our creativity.",

                            ux_category_title3: "Designs for Marketing",
                            ux_category_description3: "We create attractive visuals for campaigns, banners, release notes and other marketing needs.",

                            ux_category_title4: "Visuals for Docs & Guides",
                            ux_category_description4: "We create visuals and user experience in documentations, user guides, blogs, tutorials and more.",


                            ux_title2: "Maintaining consistency of the user flow throughout the journey",
                            ux_bullet1: "Solving specific user problems",
                            ux_bullet2: "Not reinventing the wheel",
                            ux_bullet3: "Low user's learning curve",

                            ux_title3: "User-centricity",
                            ux_bullet4: "Putting the user’s needs first",
                            ux_bullet5: "User-centricity built in the UX process",
                            ux_bullet6: "Starting with user research",

                            ux_title4: "Context",
                            ux_bullet7: "Not building design in a vacuum",
                            ux_bullet8: "How users will interact the product?",
                            ux_bullet9: "Any factors will interupt the UX?",
                            ux_bullet10: "Emotional states are likely to be?",

                            ux_title5: "Hierarchy",
                            ux_bullet11: "Visual hierarchy of individual pages",
                            ux_bullet12: "Sitemap of the web or mobile app",
                            ux_bullet13: "Help users to navigate your product",
                            ux_bullet14: "How elements laid out on a page",

                            ux_title6: "Accessibility",
                            ux_bullet15: "Product is accessible for most people",
                            ux_bullet16: "Including users with disabilities",
                            ux_bullet17: "Contrast for users with visual impairments",

                            ux_title7: "Usability",
                            ux_bullet18: "How easy a product is to use",
                            ux_bullet19: "Learnability: How easy for users",
                            ux_bullet20: "Efficiency: Tasks done quick & efficient",
                            ux_bullet21: "On user return, UX is re-familiarise",
                            ux_bullet22: "Errors: How many users errors",
                            ux_bullet23: "Satisfaction: Pleasant and enjoyable",

                            uxui_stack: "Our UX/UI Stack",
                            ux_techstack: "Tech Stack",
                            ux_techstack1: "Figma",
                            ux_techstack2: "Balsamiq",
                            ux_techstack3: "Axure",
                            ux_techstack4: "Miro",
                            ux_techstack5: "Sketch",

                        },

                        ui: {

                            ui_title: "USER INTERFACE",
                            ui_subtitle1: "We create clear and attractive UI designs to enrich users experience within the product",

                            ui_title2: "Clarity",
                            ui_bullet1: "Users navigation intuitive",
                            ui_bullet2: "Highly visible components",
                            ui_bullet3: "Each element's purpose is clear",

                            ui_title3: "Responsiveness",
                            ui_bullet4: "Suitable for wider range devices",
                            ui_bullet5: "Smooth user experience",
                            ui_bullet6: "Elements usable and accessible",

                            ui_title4: "Reduce cognitive load",
                            ui_bullet7: "Avoid users need to think",
                            ui_bullet8: "Applying 3-click rule",
                            ui_bullet9: "Min. recall in favor of recognition",

                            ui_title5: "Alignment",
                            ui_bullet10: "Visual arrangement of items",
                            ui_bullet11: "Order ease with user navigation",

                            ui_title6: "Grids",
                            ui_bullet12: "Give interface a structure feel",
                            ui_bullet13: "Achieving visual balance",

                            ui_title7: "Minimalism",
                            ui_bullet14: "Keeping minimalism with usefulness",

                            ui_techstack: "Tech Stack",
                            ui_techstack1: "Figma",
                            ui_techstack2: "Photoshop",
                            ui_techstack3: "Illustrator",
                            ui_techstack4: "JIM.",
                            ui_techstack5: "Zeplin",

                        },


                        marketing: {

                            market_title: "MARKETING & MEDIA",
                            market_subtitle1: "We create attractive visuals for campaigns and other marketing needs",

                            market_title2: "Contrast and Repetition",
                            market_bullet1: "Draw attention to important parts",
                            market_bullet2: "Text styling (Bolding,,color, type, etc..)",
                            market_bullet3: "Repetition to reinforce brand identity",
                            market_bullet4: "Repetition makes it easier to use",

                            market_title3: "Alignment and Proximity",
                            market_bullet5: "Using alignment to ease user's reading",
                            market_bullet6: "Proximity to organize items together",
                            market_bullet7: "Proximity mostly important with typo",

                        },

                        dcos: {

                            dcos_title: "UX/UI FOR DOCUMENTATION & RELEASE NOTES",
                            dcos_subtitle1: "We create visuals and user experience in documentations, user guides, release notes, blogs, tutorials and more..",

                            dcos_title1: "Documentation & Guidelines",
                            dcos_bullet1: "We designed clear sw documentations",
                            dcos_bullet2: "Planned with clients UX & UI",
                            dcos_bullet3: "Created relevant assets and aniations",

                            dcos_title2: "Release Notes",
                            dcos_bullet4: "Empathizing important text",
                            dcos_bullet5: "Well order text to ease reading",
                            dcos_bullet6: "Proper links to relevant pages",

                            dcos_title3: "Blogs & Tutorials",
                            dcos_bullet7: "Prepared visuals for clients blogs",
                            dcos_bullet8: "Planed the entire user experience",

                            dcos_techstack: "Tech Stack",
                            dcos_techstack1: "Readme",
                            dcos_techstack2: "Doxygen",
                            dcos_techstack3: "ClickUp",
                            dcos_techstack4: "Document360",
                            dcos_techstack5: "GitBook",
                            dcos_techstack6: "Tettra",


                        },
                    },

                },

                general: {
                    cta: {
                        cta_title: "Are you ready to scale your business?",
                        cta_subtitle1: "Get in touch and let us build something amazing",
                        cta_subtitle2: " together!",
                        cta_messgae: "Send message",
                    },
                },

                footer: {
                    logo: {
                        logo_title: "SW Development & Consultant",
                        logo_address: "Tel Aviv, Israel.",

                    },
                    company: {
                        company_about: "About",
                        company_aproach: "Approach",
                        company_policy: "Privacy Policy",
                        company_terms: "Terms of Use",
                        company_contact: "Contact",
                    },
                    services: {
                        services_web: "Web Development",
                        services_mobile: "Mobile Development",
                        services_uxui: "UX/UI Solutions",
                        services_consult: "General IT Consultations",
                    },
                    social: {
                        social_title: "Social Media Links",
                        social_subtitle: "Follow us on social media",
                    },
                },

                contact: {
                    message: {
                        message_title: "SEND US A MESSAGE",
                        message_fname: "First Name*",
                        message_lname: "Last Name*",
                        message_email: "Email*",
                        message_phone: "Phone*",
                        message_text: "Message*",
                        message_button: "SEND MESSAGE",
                        message_button_loading: "loading...",
                        message_address_title: "Office Address",
                        message_address: "Tel Aviv. Israel",
                        message_phone_title: "Call Us",
                        message_phone_number: "+972546604257",
                        message_email_title: "Send an Email",
                        message_email_address: "info@codesuits.com",
                        message_validation: "Please fill in all fields before sending your message",

                    },
                },

                demo: {
                    form: {
                        form_title: "SEND US A MESSAGE",
                        form_cb_web: "Web Application/Websites/E-commerce",
                        form_cb_mobile: "Mobile Develoopment",
                        form_cb_cloud: "Cloud Solutions",
                        form_cb_uxui: "UX/UI Services",
                        form_cb_consult: "IT Consultant",

                        form_fname: "First Name*",
                        form_lname: "Last Name*",
                        form_email: "Email*",
                        form_phone: "Phone*",
                        form_text: "Message*",
                        form_button: "SEND MESSAGE",
                        form_button_loading: "loading...",
                        form_address_title: "Office Address",
                        form_address: "Tel Aviv. Israel",
                        form_phone_title: "Call Us",
                        form_phone_number: "+972546604257",
                        form_email_title: "Send an Email",
                        form_email_address: "info@codesuits.com",
                        form_validation: "Please fill in all fields before sending your message",

                    },
                },

            },
        },
        he: {
            translation: {
                description: "פתרונות תוכנה בהתאמה אישית לצרכים של העסק שלכם",
                subtitle: "אנחנו צוות של מפתחים ומעצבים עם ניסיון רב בתעשייה",
                learnmore: "קראו עוד",
                casestudy: "תיק עבודות",
                introtitle: "אנו מפתחים פתרונות תוכנה באיכות גבוהה עבור ארגונים גדולים, מוסדות וחברות קטנות",
                contact: "יצירת קשר",
                introdescription: {
                    line1: "הצוותים שלנו מנוסים בפיתוח תוכנה ועיצוב חויית משתמש ונכונים לעזור לכם בבניית התוכנה שרלבנטית עבורכם.",
                    line2: "נבנה לכם פתרון בהתאמה אישית תוך כדי סנכרון ושיתוף פעולה מלא עם הצוותים האורגניים שלכם.",
                    contact: "יצירת קשר",
                },

                navbar: {
                    home: "דף הבית",
                    services: "שירותים",
                    tech: "טכנולוגיות",
                    industries: "תעשיות",
                    casestudy: "תיק עבודות",
                    company: "חברה",
                    contact: "צרו קשר",
                    lang: "עברית",
                    schedule: "בואו נדבר",
                },



                services: {
                    title: "שירותים",
                    description: "סל השירותים שלנו כולל מספר תחומים בתוכנה, עיצוב וייעוץ",
                    webdev: {
                        web_title: "Web פיתוח מוצרי ",
                        web_description: "אנחנו מתמחים ביצירה ופיתוח של מוצרים חדשים וקיימים תוך כדי שמירה על התאמה אישית לצרכי הלקוח. החל מאתרי תדמית, חנויות אונליין ואפליקציות ענן",
                    },
                    mobile: {
                        mobile_title: "פיתוח אפליקציות למובייל",
                        mobile_description: "אנו מפתחים אפליקציות נייטיב וקרוס פלאטפורם. חיבור לענן ולדאטה תוך שמירה על אבטחת המידע, ביצועים ויציבות",
                    },


                    design: {
                        design_title: "UX/UI שירותי",
                        design_description: "אנחנו מייצרים ומייעצבים עיצובים מעוררי השראה גם ברמת חוויית המשתמש וגם ברמת ממשק המשתמש. המעצבים שלנו ייעזרו לכם להעשיר את המוצר שלכם בעיצובים מרשימים שירצו למשתמשים שלכם להשאר יותר במוצר",
                    },


                    consult: {
                        consult_title: "ועיצוב IT ייעוץ בנושאי ",
                        consult_description: "אם אתם חברה גדולה, סטארטאפ בראשית דרכו או, יזמים פרטיים, המהנדסים שלנו יעזרו לכם להבין את הפתרון הטכני שאתם מחפשים עבור המוצר או הרעיון שלכם",
                    },



                    build: {
                        build_title1: "",
                        build_title2: "פיתוח",
                        build_description: "עם ניסיון של יותר מ-20 שנה בפיתוח מוצרים טכנולוגים של אפליקציות ענן, מובייל, וחומרה, עם דגש על ממשק וחווית המשתמש, אנו יודעים איך לפתח מוצרים בהתאמה אישית העונים על יעדי העסק שלכם ",
                    },


                    collaborate: {
                        collaborate_title1: "שיתוף",
                        collaborate_title2: "פעולה",
                        collaborate_description: "שיטות העבודה שפתחנו מאפשרות לנו להשתלב בצורה יעילה עם פרויקטים קיימים שצריכים הרחבת פיתוח, או יצירת מוצר חדש ביחד עם הצוותים שלכם, או, בעצמנו תוך סנכרון מלא ",
                    },
                },

                services_pages: {
                    webdev: {
                        web_title: "Web פיתוח",
                        web_subtitle1: "פיתוח ווב, על סוגיו השונים, חשוב לכל עסק. מבית קפה קטן, עם אתר אינטרנט פשוט להגדלת הקהל שלו, ועד למערכת בנקאית מקוונת המאפשרת למשתמשים שלה לבצע עסקאות מאובטחות ברחבי העולם.",
                        web_subtitle2: "אנו בונים אפליקציות אינטרנט המותאמות אישית להשגת יעדי לקוחותינו",

                        websites: {
                            website_title: "אתרים",
                            website_subtitle: "אתרים ידידותיים למשתמש ואטרקטיביים",
                            website_description: "אנו בונים סוגים שונים של אתרי אינטרנט (כגון עסקים, מסחר אלקטרוני, בוקינג) המשולבים בכלים לשינוי התוכן באופן דינמי וחשיפה להיקף גדול של לקוחות",
                        },

                        ecommerce: {
                            ecommerce_title: "E-commerce אתרי",
                            ecommerce_subtitle: "חנויות מסחר    אונליין",
                            ecommerce_description: "אנו בונים אתרי מסחר אלקטרוני מותאמים אישית, הקשורים לתשלום, תוכן ושירותים אחרים כדי לעזור לעסק שלך להגדיל את המכירות שלו.",
                        },

                        webapp: {
                            webapp_title: "Web אפליקציות",
                            webapp_subtitle: "פתרונות מבוססי ענן",
                            webapp_description: "צוות הענן שלנו יעזור לעסק שלכם לבנות יישום אינטרנט מבוסס ענן הכולל תכנון ארכיטקטורת ענן, הגדרות ופיתוח.",
                        },

                        saas: {
                            saas_title: "SaaS",
                            saas_subtitle: "Software as a Service פתרונות",
                            saas_description: "אנו מפתחים מוצרי עסק-ללקוח ועסק-לעסק עם התמקדות בביצועים, טיפול בעומס משתמשים, אבטחת המידע ושירותי ענן אחרים.",
                        },

                        webmobile: {
                            webmobile_title: "אפליקציות ווב למובייל",
                            webmobile_subtitle: "אפליקציות ריספונסיביות",
                            webmobile_description: "אנו מפתחים יישומי אינטרנט רספונסיביים המותאמים אוטומטית גם למכשירים ניידים וטאבלטים.",
                        },

                        webdata: {
                            webdata_title: "ניהול המידע ",
                            webdata_subtitle: "אפליקציות ווב מבוססות מידע",
                            webdata_description: "אנו בונים פתרונות מבוססי נתונים תוך שימוש בטכנולוגיות ביג דאטה ובינה מלאכותית כדי לשפר את ביצועי המוצרים של הלקוחות שלנו.",
                        },
                        web_case_study: "התרשמו מתיק העבודות שלנו",

                    },

                    mobile: {
                        mobile_title: "פיתוח מובייל",
                        mobile_subtitle1: "כיום, אפליקציה לנייד היא הדרך הטובה ביותר לתקשר עם המשתמשים שלך.",
                        mobile_subtitle2: "מַדוּעַ? כי הם תמיד שם",
                        mobile_subtitle3: "אנו בונים אפליקציות אטרקטיביות לאנדרואיד ואייפון בהתאמה אישית.",

                        native: {
                            native_title: "Native אפליקציות",
                            native_description: "אנו מפתחים אפליקציות אנדרואיד ו-אייפון באמצעות שפות כמו קוטלין וסויפט.",
                        },
                        cross: {
                            cross_title: "אפליקציות מרובות פלטפורמות",
                            cross_description1: "פתרון חוצה פלטפורמות, מאפשר לפתח פעם אחת ולפרוס בו זמנית במספר פלטפורמות. זו הסיבה שזו דרך מצוינת לצמצם את זמן הפיתוח של המוצרים שלך, ולסייע באסטרטגיית היציאה לשוק של החברה שלך.",
                            cross_description2: "אנו משתמשים בטכנולוגיות קרוס-םלטפורמות כמו פלאטר וריאקט-נייטיב",
                        },
                        sdk: {
                            sdk_title: "SDK",
                            sdk_description: "אנחנו מפתחים ספריות לאנדרואיד ואייפון בקוטלין, ג'אווה וסוויפט. בנוסף, אנחנו מפתחים ספריות נייטיב בפייטון וסי++",
                        },
                        hollistic: {
                            hollistic_title: "פתרון הוליסטי",
                            hollistic_description: "פתרון שלם בהתאמה אישית חוצת פלטפורמות עם ממשק ושירותים מבוססי מובייל ושירותי ענן.",
                        },
                        contact: "!בואו נדבר",
                    },
                },


                tech: {
                    title: "טכנולוגיות",
                    description: "ארגז הכלים הטכנולוגי שלנו כולל את התחומים הבאים",
                    data: {
                        data_title: "בסיסי נתונים ובינה מלאכותית",
                        data_description: "כיום, לעסק בצמיחה, עם כמות גדולה של לקוחות, ניהול נכון של המידע הוא רכיב קריטי להצלחתו של המוצר. אנחנו מומחים בפיתוח , ניהול וניתוח המידע. אם העסק שלכם צריך פתרון ביג-דאטה, או פיתוח מוצר מבוסס בינה מלאכותית, אנשי הדאטה וחוקרי הבינה המלאכותית שלנו יעזרו בפיתוח הפתרון שהכי נכון לכם",

                    },
                    cloud: {
                        cloud_title: "DevOps-טכנולוגיות ענן ו",
                        cloud_description: "אנחנו עוזרים לעסקים קטנים וגדולים לפתור את אתגרי הענן שלהם. כשותפים מוסמכים של אמזון, מומחי הענן שלנו יעזרו לעסק שלכם לבנות פתרון מבוסס ענן תוך השמת דגש על שיקולים חשובים כגון: ביצועים, תמיכה משמעותית במשתמשי קצה, אבטחת מידע, חיבור עם מערכות נוספות , עלויות ענן ועוד",
                    },
                    cross: {
                        cross_title: "Cross-platform פיתוח",
                        cross_description: "פתרון מרובה פלטפורמות מאפשר לפתח פעם אחת ולהפיץ כמעט באותו זמן על מספר פלטפורמות שונות. למשל, אפליקציית ווב, אנדרואיד ו-אייפון. שימוש בפתרון מסוג הזה, שהולך וצובר יותר פופולאריות בשנים האחרונות, חוסך משאבים רבים של כסף, מפתחים וזמן פיתוח. עבור חברות עם משאבים מוגבלים, המעוניינות לשחרר מוצר על יותר מפלטפורמה אחת בזמן קצר יחסית, פתרון מסוג זה יכול להיות מאוד משמעותי",

                    },
                    security: {
                        security_title: "אבטחת מידע",
                        security_description: "אבטחת המידע ושמירה על פרטיות המשתמשים קריטיים להצלחתו של המוצר ולמוניטין שלו. תוך שימוש בסטנדרטיים פופולארים בעולמות הבנקים, הרכב, ועוד, מומחי האבטחה שלנו ייתפרו לכם את הפתרון הבטוח למוצר שלכם",

                    },
                    creative: {
                        creative_title1: "",
                        creative_title2: "יצירתיות",
                        creative_description: "הניסיון הרב של אנשי הפיתוח והעיצוב שלנו מאפשר לנו לבוא עם פתרון יצירתי ומהיר מבחינה טכנית או עיצובית עבור המוצר שלך. קראו עוד על הפתרונות שלנו בדף תיק העבודות",
                    },
                    innovative: {
                        innovative_title1: "",
                        innovative_title2: "חדשנות",
                        innovative_description: "כחלק מהגישה שלנו בקודסויטס, אנו מקפידים לעבוד עם הטכנולוגיות המתקדמות בעולם התוכנה ובתעשיות השונות. ע״י שימוש בגישה זו, אנו יודעים מהם הפתרונות היעילים ביותר עבור המוצר שלכם, ויכולות האינטגרציה שלנו עם קוד קיים יותר רחבה וגמישה",
                    },
                },

                tech_pages: 
                {
                    tech_data: {
            
                        tech_data_title: 'פיתוח דאטה',
                        tech_data_description1: 'אנו חיים היום בעולם מבוסס נתונים.',
                        tech_data_description2: 'עסק שרוצה לשרוד במציאות הזו חייב לנהל את הנתונים שלו כמו שצריך.',
                        tech_data_description3: 'זה מתייחס לאופן איסוף, מאורגן, מניפולציה, ניתוח ומאובטח.',
                        tech_data_description4: 'המפתחים שלנו מנוסים היטב ביצירת מוצרים המותאמים לדרישות אלה',
                        tech_data_card1: "מנועי בינה מלאכותית",
                        tech_data_card1_description: 'AI אינטגרציה עם מנועי ',
                        tech_data_card1_description2: '',
                        tech_data_card2: "AI מותאם אישית",
                        tech_data_card2_description: 'פתרון בינה מלאכותית מותאם אישית ללקוח',
                        tech_data_card3: "שירותי ענן", 
                        tech_data_card3_description: 'ארכיטקטורה, הגדרות, פיתוח וניהול שירותי ענן',
                        tech_data_bigdata1: "BIG DATA",
                        tech_data_bigdata2: "מומחי הענן שלנו, מוסמכי פתרונות הענן של אמזון, גוגל ומייקרוספט, ייעזרו לעסק שלך לבנות ולנהל את מוצרי הביג-דאטה שלו",
                        tech_data_mlai1: "ML & AI",
                        tech_data_mlai2: "AI שפרו את המוצרים שלכם בפיצ'רים מבוססי ",
                        tech_data_letstalk: "!בואו נדבר",
            
                    },
            
                },


                industries: {
                    title: "תעשיות",
                    description: "אנשי הפיתוח שלנו מנוסים במתן פתרונות בתעשיות שונות",
                    marketing: {
                        marketing_title: "מרקטינג ומדיה",
                        marketing_description: "אנו מפתחים מוצרים וכלי תוכנה למוצרי שיווק ומדיה המאפשרים להגדיל את ההתממשקות מול הלקוחות שלכם ולנתח בצורה טובה יותר את הביצועים",



                    },
                    retail: {
                        retail_title: "קמעונות ומסחר",
                        retail_description: "אנחנו בקוד-סוויטס עוזרים לקמעוניים וחברות סחר למצוא פתרונות חדשים כדי להגדיל את הממשק מול הלקוחות ולהגדיל רווחים",
                    },
                    finance: {
                        finance_title: "כספים ופיננסים",
                        finance_description: "בתחום הפיננסי והביטוחי אנו מתמחים בייעוץ ופיתוח כלי תשלומים וסליקה תוך דגש על בנייה נכונה של בסיסי הנתונים, אבטחת המידע ושמירה על פרטיות הלקוח",

                    },
                    automotive: {
                        auto_title: "תעשיית הרכב",
                        auto_description: "אנו עובדים עם תקנים ופרוטוקולים המקובלים בפיתוח תוכנה בעולם הרכב",

                    },
                    share: {
                        share_title1: "",
                        share_title2: "שקיפות",
                        share_description: "חשוב לנו בקוד-סויטס לעבוד בצורה שקופה מול הלקוחות שלנו. מעבר לאני מאמין שלנו בשקיפות ואמינות, אנו מאמינים שעבודה בה כל הצדדים יודעים מה יתר הצוותים עושים, מביאה לתוצאות טובות יותר ",
                    },

                    agile: {
                        agile_title1: "",
                        agile_title2: "זריזות",
                        agile_description: "הניסיון העשיר והמקצועיות של אנשי הפיתוח והעיצוב שלנו מאפשר לנו להכנס מהר לכל פרויקט, אם הוא חדש, או, קוד קיים, תוך הקפדה על בניית פתרון איכותי ועמידה בלוחות הזמנים",
                    },
                },

                portfolio: {
                    title: "תיק עבודות",
                    all_cases: "לכל העבודות",
                    squid: {
                        squid_title: "Squid",
                        squid_subtitle: "מערכת CRM ",
                        squid_description: "מערכת סאאס (שירות כפתרון) למערכת קשרי לקוחות. המערכת נבנתה לאנדרואיד, אייפון ואפליקציית ווב עם שירותי ענן. הוספנו יכולות בינה מלאכותית לשיפור הביצועים",
                        squid_learn: "עוד פרטים",

                    },
                    health: {
                        health_title: "Healthness",
                        health_subtitle: "מערכת שירותי בריאות ואורך חיים בריא",
                        health_description: "פיתחנו אפליקציית אנדרואיד ואייפון עם יכולות בינה מלאכותית המאפשרות זיהוי מצבי הרוח של משתמשי הקצה ע״י מערכת לזיהוי פנים שבנינו. בהתאם לזיהוי מצח הרוח המשתמשים קבלו המלצות שונות בהתאמה אישית.",
                        health_learn: "עוד פרטים",
                    },
                    wewear: {
                        wewear_title: "WeWear",
                        wewear_subtitle: "חנות אונליין למכירת בגדים",
                        wewear_description: "בנינו חנות אונליין למכירת בגדים. פיתחנו צ'אטבוט עם יכולות של בינה מלאכותית המאפשרים לתת מענה יעיל למשתמשי הקצה ולחסוך ללקוח גיוס נוסף של אנשי תמיכת לקוחות. בנוסף פתחנו כלים מבוססי פרסונליציה כדי לצפות מתי הזמן הנכון להקפיץ למשתמשים קמפיינים על סמך קניות עבר שלהם",
                        wewear_learn: "פרטים נוספים",
                    },
                    netpay: {
                        netpay_title: "NetPay",
                        netpay_subtitle: "אפליקציית ארנק דיגיטאלי",
                        netpay_description: "פיתחנו עבור גוף פיננסי אפליקציית ארנק דיגיטאלי המאפשרת לבצע תשלומים לבתי עסק ולהעביר כספים לחשבונות אחרים. בנוסף, בנינו לבקשת הלקוח, כלים המנתחים את ההתנהגות הפיננסית של המשתמש כדי לעזור לו להפחית את ההוצאות ולקבל תנאים טובים יותר בהמשך ע״י אותו גוף פיננסי",
                        netpay_learn: "עוד פרטים",
                    },

                },

                portfolio_all: {
                    title: "תיק עבודות",
                    subtitle: "הדף מכיל דוגמאות לפרויקטים שלנו לפי תחומים שונים. בכל פרויקט נעשתה התאמה אישית של הטכנולוגיות הרלבנטיות",


                    websites: {
                        web_title: "אתרי תדמית וחנויות אונליין",

                        print_title: "JUSTPRINT",
                        print_subtitle: "חנות הדפסות אונליין ",
                        print_description: "בניית חנות אונליין להזמנת הדפסות על גבי בגדים, כובעים ואביזרים. במסגרת הפרויקט בנינו מערכת הזמנות, תשלומים וסליקה.",
                        print_learn: "פרטים נוספים",


                        bfood_title: "הבריאפוד",
                        bfood_subtitle: "אתר תדמית למסעדה",
                        bfood_description: ". אתר תדמית למסעדה של אוכל בריא וטרי מהחקלאי ללקוח. הוספנו מערכת הזמנות חכמה שחסכה ללקוח כח אדם לקבלת הזמנות",
                        bfood_learn: "פרטים נוספים",

                        pets_title: "PETS",
                        pets_subtitle: "חנות דיגיטאלית למכירת אוכל ואביזרים לבע״ח",
                        pets_description: "בנינו עבור הלקוח חנות דיגיטאלית למכירת אוכל ואביזרים לבעלי חיים. בנינו ממשק וחוויית משתמש קלים ונוחים שאפשרו למשתמשים לבצע רכישות בקלות כולל אפשרות לבצע הזמנות חוזרות ולחסוך זמן",
                        pets_learn: "פרטים נוספים",


                    },

                    saas: {
                        saas_title: " Web & SaaS אפליקציות",

                        squid_title: "SQUID",
                        squid_subtitle: "מערכת CRM ",
                        squid_description: "מערכת סאאס (שירות כפתרון) למערכת קשרי לקוחות. המערכת נבנתה לאנדרואיד, אייפון ואפליקציית ווב עם שירותי ענן. הוספנו יכולות בינה מלאכותית לשיפור הביצועים",
                        squid_learn: "פרטים נוספים",

                        qspace_title: "QUICKSPACE",
                        qspace_subtitle: "מערכת לניהול נדל״ן",
                        qspace_description: "פתחנו מערכת המבוססת בינה מלאכותית עבור חברה לחיפוש נדל״ן. הפתרון אפשר ללקחותיה לאתר נחכסים ביתר קלות ובהתאמה אישית מה שהגדיל את ההמכירות. בנוסף בנינו ללקוח כלים המאפשרים לנתח את התנהגות באתר ולשפר את הקמפיינים והמכירות ע״ס נתונים אלה",
                        qspace_learn: "פרטים נוספים",

                        saasly_title: "SAASLY",
                        saasly_subtitle: "פלטפורמה מתקדמת לניתוח ביצועים",
                        saasly_description: "פתחנו פלטפורמת ענן מתקדמת שמאפשרת לחברות ללמוד ולנתח את ביצועי המוצר שלהם תוך הבנה של איזה דברים טוב ואיפה צריך להשתפר כדי להגיע ליעדי החברה",
                        saasly_learn: "פרטים נוספים",

                        ipsum_title: "LOGOIPSUM",
                        ipsum_subtitle: "פלטפורמה לניהול עסקים",
                        ipsum_description: "הצטרפנו לצוותי הפרוקדט והפיתוח של הלקוח כדי לעזור בפיתוח פלטפורמה לניהול עסקים. מומחי הענן שלנו עזרו בפיתוח פתרונות ענן כדי לאפשר למוצר לתמוך במספר רב של משתמשי קצה",
                        ipsum_learn: "פרטים נוספים",


                        health_title: "Healthness",
                        health_subtitle: "מערכת שירותי בריאות ואורך חיים בריא",
                        health_description: "פיתחנו אפליקציית אנדרואיד ואייפון עם יכולות בינה מלאכותית המאפשרות זיהוי מצבי הרוח של משתמשי הקצה ע״י מערכת לזיהוי פנים שבנינו. בהתאם לזיהוי מצח הרוח המשתמשים קבלו המלצות שונות בהתאמה אישית.",
                        health_learn: "פרטים נוספים",
                    },

                    mobile: {

                        mobile_title: "אפליקציות מובייל",


                        grab_title: "FASTGRAB",
                        grab_subtitle: "אפליקציה לשליחויות בתחום המזון",
                        grab_description: "פתחנו אפליקציה לאנדרואיד ואייפון המיועדת לשליחויות של אוכל מבתי עסק ללקוחות. האפליקציה כללה יכולות של הזמנה, תשלום, שיחת צאט ועוד",
                        grab_learn: "פרטים נוספים",


                        health_title: "HEALTHNESS",
                        health_subtitle: "מערכת שירותי בריאות ואורך חיים בריא",
                        health_description: "פיתחנו אפליקציית אנדרואיד ואייפון עם יכולות בינה מלאכותית המאפשרות זיהוי מצבי הרוח של משתמשי הקצה ע״י מערכת לזיהוי פנים שבנינו. בהתאם לזיהוי מצח הרוח המשתמשים קבלו המלצות שונות בהתאמה אישית.",
                        health_learn: "פרטים נוספים",


                        rides_title: "RIDES",
                        rides_subtitle: "אפליקציה עבור חנות אופניים",
                        rides_description: "פתחנו בפלאטר אפליקציה לאנדרואיד ואייפון עבור רשת אופניים קטנה. האפליקציה חוברה לענן וכללה יכולות של הזמנה ותשלום, הכנסת תוכן דינאמי וניתוח ביצועים ע״י הלקוח",
                        rides_learn: "פרטים נוספים",


                        netpay_title: "NETPAY",
                        netpay_subtitle: "אפליקציית ארנק דיגיטאלי",
                        netpay_description: "פיתחנו עבור גוף פיננסי אפליקציית ארנק דיגיטאלי המאפשרת לבצע תשלומים לבתי עסק ולהעביר כספים לחשבונות אחרים. בנוסף, בנינו לבקשת הלקוח, כלים המנתחים את ההתנהגות הפיננסית של המשתמש כדי לעזור לו להפחית את ההוצאות ולקבל תנאים טובים יותר בהמשך ע״י אותו גוף פיננסי",
                        netpay_learn: "פרטים נוספים",
                    },


                    design: {
                        design_title: "UX/UI פתרונות",


                        ux_title: "חוויית משתמש",
                        ux_description: "גם אפליקציה מוצלחת, עם התמורה הטובה ביותר עבור המשתמשים שלה, עשויה להתמודד עם נטישה מוגברת אם המשתמשים יאבדו את עצמם במוצר. אנו יודעים ליצור חווית משתמש ידידותית כדי להשאיר את המשתמש בתוך האפליקציה. בדוק את דוגמאות ה-UX שלנו על מוצרים שונים.",
                        ux_learn: "פרטים נוספים",

                        ui_title: "ממשק משתמש",
                        ui_description: "על ידי יצירת ממשק אינטואיטיבי וקל לשימוש, עיצוב ממשק המשתמש משפר את חווית המשתמש הכוללת והופך את האפליקציה למהנה יותר לאינטראקציה. הצלחתה של אפליקציה מסתמכת במידה רבה על מעורבות המשתמש. אנו בונים תפיסות עיצוב ממשק משתמש מותאמות אישית לכל לקוח עם מערכות העיצוב ומדריך הסגנונות הרלוונטיים.",
                        ui_learn: "פרטים נוספים",

                        media_title: "עיצובים למרקטינג",
                        media_description: "עבודת העיצוב שלנו אינה מסתיימת בגבולות התוכנה. אנו עוזרים ללקוחותינו ליידע את המשתמשים על כך על ידי יצירת קמפיינים שיווקיים חזותיים אטרקטיביים. לחצו על הלחצן למטה כדי לסקור חלק מהעבודות שלנו.",
                        media_learn: "פרטים נוספים",

                        docs_title: "עיצובים לדוקמנטציה",
                        docs_description: "ייתכן שהמוצר שלך לא יהיה שלם ללא תיעוד ברור שינחה את המשתמשים שלך כיצד להשתמש בכל תכונה. בדקו כמה מהתיעוד והערות השחרור שלנו בפעולה.",
                        docs_learn: "פרטים נוספים",
                    },


                    pages: {


                        justprint: {

                            jp_page_title: "JUSTPRINT",
                            jp_subtitle1: "חנות אי-קומרס להדפסות",
                            jp_subtitle2: "חנות אונליין עם מערכת הזמנות, סליקה ותוכן דינאמי הנשלט ע״י הלקוח",
                            jp_description: "תיאור הפרויקט",
                            jp_client_title: "הלקוח",
                            jp_product_title: "המוצר",
                            jp_work_title: "העבודה",
                            jp_client_description: "חנות להדפסות שרצתה אתר כדי להגדיל את היקף המכירות שלה",
                            jp_product_description: "אתר מכירות אונליין עם עיצוב וחוויית משתמש ידידותיים ונוחים המאפשרים חווית קניות קלה וכייפית עבור המשתמש. מערכת הזמנות וסליקה ויכולת שינוי תוכן באופן דינאמי ע״י הלקוח",
                            jp_work_description: "לאחר קבלת הדרישות מהלקוח בנינו עיצוב ראשוני ופרוטוטייפ שהוצג ללקוח. לאחר קבלת אור ירוק מהלקוח התחלנו בפיתוח האתר תוך כדי סנכרון בעבודה מול הלקוח כדי לוודא שאנחנו בכיוון ולמנוע בזבוז זמן מיותר",

                            jp_profile: "פרופיל",
                            jp_profile_country_title: "מדינה",
                            jp_profile_country_value: "ישראל",
                            jp_profile_industry_titile: "תעשייה",
                            jp_profile_industry_value: "הדפסות",
                            jp_profile_team_titile: "הצוות שלנו",
                            jp_profile_team_value: "מ. פרויקט, מעצבת, מפתח",

                            jp_goals: "יעדים ומטרות",
                            jp_goal1: "בניית אתר מכירות",
                            jp_goal2: "הלקוח יכול לשנות את התוכן",
                            jp_goal3: "מספר דרכי התקשרות",
                            jp_goal4: " SEO הגדלת החשיפה לאתר ע״ ",
                            jp_goal5: "תמיכה בדומיין ואחסון",

                            jp_challanges: "אתגרים",
                            jp_challange_title1: "לפשט חוויית קניות",
                            jp_challange_1: "להקטין את מספר הצעדים הנדרדשים כדי להשלים את תהליך הקניה",
                            jp_challange_title2: "שינוי תוכן קל",
                            jp_challange_2: "לייצר ממשק ידידותי עבור הלקוח בו הוא יכול לשנות בקלות תוכן כמו פריטים ומחירים",
                            jp_challange_title3: "הגדלת חשיפת האתר",
                            jp_challange_3: "בניית אסטרטגיה לחשיפת האתר בחיפוש ע״ משתמשים",

                            jp_solution_title: "הפתרון",
                            jp_solution_description1: "המעצבת שלנו יצרה עיצוב רלבנטי ע״ס הדרישות ופרוטוטייפ כדי שהלקוח ייאשר",
                            jp_solution_description2: "המפתח שלנו התחיל לעבוד על האתר לפי העיצוב שאושר תוך שימוש בכלים רלוונטים",
                            jp_solution_description3: "השתמשנו בכלים חיצוניים ופופולאריים , כמו למשל, וו-קומרס , כדי לבצע סליקה ומעקב מכירות",

                            jp_techstack: "טכנולוגיות",

                            jp_techstack1: "PHP",
                            jp_techstack2: "WooCommerce",
                            jp_techstack3: "WordPress",
                            jp_techstack4: "Joomla",

                            jp_results_title: "תוצאות",
                            jp_result_title1: "⭡ הגדלת חשיפה",
                            jp_result_description1: "דירוג האתר הלך והשתפר עם הזמן והחשיפה של האתר עלתה",
                            jp_result_title2: "⭡ הגדלת מכירות",
                            jp_result_description2: "בניית האתר ללקוח ע״י קודסוויטס העלתה את היקף המכירות של הלקוח בכ-25% בחצי שנה הראשונה",
                            jp_result_title3: "שינוי תוכן יעיל",
                            jp_result_description3: "הלקוח מעדכן מדי פעם את התוכן באתר בקלות ובשביעות רצון",


                        },

                        barifood: {

                            bf_title: "הבריא-פוד",
                            bf_subtitle1: "אתר למסעדה",
                            bf_subtitle2: "בנינו אתר למסעדת אוכל בריא שהדגשים היו עיצוב חדשני ואטרקטיבי, טיפול בבעיות סנכרון של הזמנות אונליין ולהוסיף כלים שיעזרו לחשוף את האתר יותר בחיפושי גולשים באינטרנט",
                            bf_description: "תיאור הפרויקט",
                            bf_client_title: "הלקוח",
                            bf_product_title: "המוצר",
                            bf_work_title: "העבודה",
                            bf_client_description: "הלקוח הוא מסעדה ששמה דגש על אוכל בריא ורכיבים טריים בשיטת מהשדה לסועד. הלקוח רצה לעשות שינויים באתר הנוכחי עקב בעיות טכניות ועיצוב לא אטרקטיבי שהיו באתר הקיים",
                            bf_product_description: "הצוות שלנו עיצב ובנה אתר חדש שמצד אחד מציד את תפריט המסעדה, עם אפשרות לשנות תוכן באופן דינאמי ע״י הלקוח, ויכולת ניהול טובה של מערכת הזמנות הלקוחות",
                            bf_work_description: "לאחר הבנת הדרישות, המעצב שלנו יצר עיצוב חדש לאתר שכלל חוויית משתמש חדשה וממשק משתמש אטרקטיבי. יצרנו פרוטוטייפ בפיגמה שהוצג ללקוח. לאחר אישור הלקוח התחלנו תהליך של פיתוח האתר שנמשך כ-3 שבועות בהן בנינו את האתר עם יכולות חדשות של ניהול מערכת ההזמנות",

                            bf_profile: "פרופיל",
                            bf_profile_country_title: "מדינה",
                            bf_profile_country_value: "ישראל",
                            bf_profile_industry_titile: "תעשיה",
                            bf_profile_industry_value: "מסעדנות",
                            bf_profile_team_titile: "הצוות שלנו",
                            bf_profile_team_value: "3 אנשי צוות",

                            bf_goals: "ייעדים ומטרות",
                            bf_goal1: "עיצוב מחודש ואטרקטיבי לאתר",
                            bf_goal2: "בניית פתרון לניהול הזמנות",
                            bf_goal3: "מערכת לתוכן דינאמי",
                            bf_goal4: "הוספת כלי חשיפה של האתר ",

                            bf_challanges: "אתגרים",
                            bf_challange_title1: "פתרון בעיית הזמנות",
                            bf_challange_1: "מציאת פתרון לבעיות הסנכרון של מערכת ההזמנות באתר ומקומות פנויים במסעדה",
                            bf_challange_title2: "עלייה החשיפת האתר",
                            bf_challange_2: "הוספת כלים שיעזרו לאתר להופיע יותר בחיפושי גולשים ברשת",
                            bf_challange_title3: "שינוי תוכן דינאמי",
                            bf_challange_3: "הוספת יכולות לשינוי תוכן ע״י הלקוח",

                            bf_solution_title: "פתרונות",
                            bf_solution_description1: "פתחנו מערכת מבוססת בינה מלאכותית שמזהה מתי יש עומס בהזמנות ולפי זה נותנת ללקוח התראה בזמן על צפי גדול של לקוחות",
                            bf_solution_description2: "חיבור נכון של מערכת ההזמנות באתר עם מערכת הזמנות במסעדה",
                            bf_solution_description3: "הוספת כלי שיווק מתקדמים",

                            bf_techstack: "טכנולוגיות",
                            bf_techstack1: "WordPress",
                            bf_techstack2: "Joomla",
                            bf_techstack3: "Python",
                            bf_techstack4: "AWS",


                            bf_results_title: "תוצאות",
                            bf_result_title1: "⭡ עלייה בהזמנות",
                            bf_result_description1: "עלייה של כ-35% בהזמנות דרך האתר",
                            bf_result_title2: "⭣ ירידה ברישום כפול",
                            bf_result_description2: "ירידה במקרים של דאבל בוקינג אודות לפתרון חדש של סנכרון בין האתר למערכת במסעדה",
                            bf_result_title3: "⭡ עלייה בחשיפה",
                            bf_result_description3: "עלייה של כ-20-25% במספר הלקוחות שבצעו הזמנות באתר לאחר שהגיעו אליו מחיפוש שעשו במנוע חיפוש",


                        },

                        wewear: {

                            ww_title: "WeWear",
                            ww_subtitle1: "חנות אופנה אונליין ",
                            ww_subtitle2: "צ'אטבוט מבוסס בינה מלאכותית עבור מתן תמיכה ומענה ללקוחות באתר",
                            ww_description: "תיאור הפרויקט",
                            ww_client_title: "הלקוח",
                            ww_product_title: "המוצר",
                            ww_work_title: "העבודה",
                            ww_client_description: "הלקוח הוא חברה בתחום האופנה. החברה מוכרת פריטי לבוש ואופנה באנגליה בעיקר דרך הרשת ע״י מספר אתרים",
                            ww_product_description: "אתר מכירות מבוסס בינה מלאכותית עם צ'אטבוט שנועד לתת מענה לפניות לקוחות. בנוסף, הוא מקפיץ הצעות ללקוח באופן אישי בהתאם לזיהוי הרגלי הקניות של כל לקוח ",
                            ww_work_description: "בניית אתר מכירות עם יכולות שליטה בתוכן באופן דינאמי, בניית עיצוב אטרקטיבי ומודלים מבוססי בינה מלאכותית שיענו על דרישות המוצר",

                            ww_profile: "פרופיל",
                            ww_profile_country_title: "מדינה",
                            ww_profile_country_value: "אנגליה",
                            ww_profile_industry_titile: "תעשייה",
                            ww_profile_industry_value: "אופנה",
                            ww_profile_team_titile: "הצוות שלנו",
                            ww_profile_team_value: "מ.פרויקט, חוקר, מפתחים ,מעצבת",

                            ww_goals: "ייעדים ומטרות",
                            ww_goal1: "בניית אתר מכירות אונליין",
                            ww_goal2: "הלקוח יכול לשנות את התוכן",
                            ww_goal3: "חיבור למערכת סליקה",
                            ww_goal4: "הכנסת אוטומציה בתמיכת לקוחות",
                            ww_goal5: "להכניס צ'אטבוט שיש עומס פניות",

                            ww_challanges: "אתגרים",
                            ww_challange_title1: "איחוד פורמט המידע",
                            ww_challange_1: "עיבוד המידע מהאתר בפורמט שהצ'אטבוט יכול לעבוד איתו. זה כולל ניתוח המידע ביצוע המרות והתאמות ואחסון בענן",
                            ww_challange_title2: "תגובות מדויקות",
                            ww_challange_2: "חידוד התשובות של הצ'אטבוט לפניות ושאלות של לקוחות.",
                            ww_challange_title3: "גמישות של הצ'אטבוט",
                            ww_challange_3: "הצ'אטבוט צריך להתמודד עם מספר רב של פניות בשעות העומס או מספר קטן יחסית בשעות רגילות. לכן, צריך לוודא שהוא יכול לעבוד בכל עומס של פניות",

                            ww_solution_title: "פתרונות",
                            ww_solution_description1: "בהתחלה עבודה על הארכיטקטורה של הצ'אטבוט. פתחנו מנגנון שיודע לתרגם בקשות שמגיעות מהצ'אטבוט.  ",
                            ww_solution_description2: "פתחנו ממשק שמאפשר לאתר לשלוח בקשות של הצ'אטבוט לענן ולעבד את הבקשות מול המידע ולהפיק תוצאות חזרה לאתר",
                            ww_solution_description3: "לבסוף, שחררנו את האתר עם פתרונות בענן של אמזון עם יכולות לעמוד בעומס פניות",

                            ww_techstack: "טכנולוגיות",
                            ww_techstack1: "WPS",
                            ww_techstack2: "PHP",
                            ww_techstack3: "N.JS",
                            ww_techstack4: "AWS",
                            ww_techstack5: "Python",

                            ww_results_title: "תוצאה",
                            ww_result_title1: "השעייה קטנה מ-5 שניות",
                            ww_result_description1: "הצ'אטבוט יכול לתת מענה לכל פניה בפחות מ-5 שניות ב-99% מהמקרים בכל עומס נתון",
                            ww_result_title2: "מאה פניות בשניה",
                            ww_result_description2: "הצ'אטבוט יכול לתת מענה בשניה לכמאה פניות בלי עכובים או חריגות",
                            ww_result_title3: "עליית מכירות ",
                            ww_result_description3: "ע״י בניית מנגנון זיהוי של זמנים אידיאליים להצעת קמפיינים ללקוחות המכירות עלו ביותר מחמישים אחוז",


                        },



                        pets: {

                            pets_title: "PETS",
                            pets_subtitle1: "חנות אונליין למכירת ציוד ,אוכל וחיות מחמד",
                            pets_subtitle2: "בנינו ללקוח אתר עבור מכירת המוצרים שלו. יצרנו ממשק נוח וידידותי למשתמשים וחיברנו את האתר עם מערכת מבוססת בינה מלאכותית לזיהוי הרגלי קנייה של הלקוחות כדי להציע מבצעים עתידיים",
                            pets_description: "תיאור הפרויקט",
                            pets_client_title: "הלקוח",
                            pets_product_title: "המוצר",
                            pets_work_title: "העבודה",
                            pets_client_description: "הלקוח הוא חנות לממכר חיות מחמד ומיוד נלווה אשר רצה לשדרג את האתר תדמית שלו באתר מכירות אטרקטיבי עם ממשק ידידותי שיעזור לעלות את המכירות",
                            pets_product_description: "אתר מכירות אונליין עם מערכת חכמה שלומדת את הרגלי הקניה של הלקוחות כדי להציע קמפיינים עתידיים על סמך קניות עבר של כל לקוח. חיבור של האתר עם מערכת לניהול מלאי והזמנה אוטומטית של מוצרים חדשים",
                            pets_work_description: "העבודה כללה איפיון של האתר ברמה העיצובית וברמה הטכנית. מבחינת אלמנטים של סליקה ושינוי תוכן באופן דינאמי ומבחינת פתרון בענן מבוסס בינה מלאכותית ללמידה של הרגלי הקניה של המשתמשים",

                            pets_profile: "פרופיל",
                            pets_profile_country_title: "מדינה",
                            pets_profile_country_value: "ישראל",
                            pets_profile_industry_titile: "תעשייה",
                            pets_profile_industry_value: "חיות מחמד",
                            pets_profile_team_titile: "הצוות שלנו",
                            pets_profile_team_value: "5 - מ.פרויקט, מתכנתים, מעצבת, חוקר",

                            pets_goals: "ייעדים ומטרות",
                            pets_goal1: "חנות אונליין עם פתרונות סליקה",
                            pets_goal2: "מערכת ניהול מלאי אוטומטית",
                            pets_goal3: "מערכת חכמה לקמפיינים",
                            pets_goal4: "מערכת לניהול תוכן דינאמי",

                            pets_challanges: "אתגרים",
                            pets_challange_title1: "אוטומציה לניהול המלאי",
                            pets_challange_1: "חיבור של הפתרון החדש שלנו עם מערכת קיימת לניהול המלאי. וידוא ששתי המערכות מסונכרנות באופן מדויק כדי למנוע כשל בהזמנות של המשתמשים באתר ומצד שני של אספקה חדשה",
                            pets_challange_title2: "דיוק בקמפיינים",
                            pets_challange_2: "לייצר מערכת מבוססת בינה מלאכותית היודעת  לצפות מתי הזמן הנכון להציע ללקוחות מבצעים עתידיים על סמך קניות עבר",
                            pets_challange_title3: "תוכן דינאמי וסליקה",
                            pets_challange_3: "הטמעת כלים לניהול ועדכון תוכן באופן דינאמי ומדויק וחיוב ללא טעויות של המשתמשים באתר",

                            pets_solution_title: "פתרונות",
                            pets_solution_description1: "העבודה החלה בניית העיצוב לאתר ביצירת ממשקי משתמש נוחים לשימוש. בנוסף,  התחלנו ביצירת ארכיטקטורה טכנית לפתרון הנדרש ",
                            pets_solution_description2: "התחלנו בפיתוח של התכנון התיאורטי בשלב הראשון. הוספנו בענן יכולות של בינה מלאכותית ללימוד הרגלי הקנייה של הלקוח. הוספנו ממשק בין האתר לענן כדי לשלוח נתונים בצורה מאובטחת. חיברנו את המערכת לניהול המלאי של הלקוח עם הפתרון החדש שלנו",
                            pets_solution_description3: "עשינו בדיקות קצה לקצה כדי לוודא שהכל עובד כמו שצריך",

                            pets_techstack: "טכנולוגיות",
                            pets_techstack1: "WPS",
                            pets_techstack2: "React",
                            pets_techstack3: "Node.JS",
                            pets_techstack4: "WooCommerce",
                            pets_techstack5: "Joomla",

                            pets_results_title: "תוצאה",
                            pets_result_title1: "עלייה במכירות",
                            pets_result_description1: "סה״כ המכירות של הלקוח עלו בכ-30% אודות לפיתוח של האתר החדש עם העיצוב החדש והפתרונות הנלווים",
                            pets_result_title2: "עליה בייעול הזמן",
                            pets_result_description2: "פתרון האוטומציה שהצוות שלנו פתח עבור הלקוח עזר לצמצם את זמן הטיפול בניהול המלאי עבור מוצרים שנמכרו באתר הקודם בכ-38 אחוז",
                            pets_result_title3: "עלייה בהתקשרות הלקוח",
                            pets_result_description3: "ע״י העיצוב החדש והפיצ'רים שהתווספו, האתר החדש עזר לעלות את ההתקשרות מול המשתמשים וזמן השהייה שלהם באתר ",


                        },


                        squid: {

                            squid_title: "SQUID",
                            squid_subtitle1: "CRM מערכת",
                            squid_subtitle2: "מערכת מבוססת סאאס שפתחנו לדרישת הלקוח כאפליקציית ווב ומובייל על אנדרואיד ואייפון",
                            squid_description: "תיאור הפרויקט",
                            squid_client_title: "הלקוח",
                            squid_product_title: "המוצר",
                            squid_work_title: "העבודה",
                            squid_client_description: "הלקוח הוא סאטרטאפ שפיתח את המוצר והיה צריך תגבור מבחינת תכנון הארכיטקטורה , העיצובים והמימוש",
                            squid_product_description: "מערכת לניהול קרי לקוחות המבוססת בינה מלאכותית לייעול תהליכים. המוצר בנוי מממשק המאפשר להגדיר לקוחות ולפתוח כרטיסים, לשלוח קמפיינים בשיטת אומני צ'אנל.",
                            squid_work_description: "הצטרפנו לצוות האורגני של החברה כדי לעזור בבניית הארכיטקטורה למוצר. המעצבת שלנו יצרה עיצוב לפי הדרישות ובנתה פרוטוטייפ שבעזרתו כל הצוותים יכלו להתקדם במימוש בהמשך.",

                            squid_profile: "פרופיל",
                            squid_profile_country_title: "מדינה",
                            squid_profile_country_value: "USA",
                            squid_profile_industry_titile: "תעשייה",
                            squid_profile_industry_value: "CRM",
                            squid_profile_team_titile: "הצוות שלנו",
                            squid_profile_team_value: "6 - מ.פרויקט, מתכנתים, מעצבת, חוקרים",

                            squid_goals: "ייעדים ומטרות",
                            squid_goal1: "פיתוח אפליקציית ווב מבוססת ענן",
                            squid_goal2: "סנכרון עם אפליקציות אנדרואיד ואייפון",
                            squid_goal3: "פיצ'רים מבוססי בינה מלאכותית",
                            squid_goal4: "יכולת תמיכה במיליוני משתמשים",

                            squid_challanges: "אתגרים",
                            squid_challange_title1: "חוויית משתמש",
                            squid_challange_1: "שיפור חוויית המשתמש בכל תהליך במוצר עם מספר רב של צעדים ופעולות הנדרשים ע״י המשתמש. למשל, תהליך הוספת לקוח או יצירת כרטיס חדש",
                            squid_challange_title2: "המרת סגנון עבודה",
                            squid_challange_2: "בניית המוצר בצורה כזאת שיוכל לתמוך בלקוחות שעבדו עד עכשיו באמצעים ידניים או מרובי אפליקציות לניהול הלקוחות שלהם ולדעת לייבא ולרכז את כל המידע שנאגר עד עכשיו למערכת החדשה בלי לשבש נתונים",
                            squid_challange_title3: "תמיכה מרובה",
                            squid_challange_3: "בניית מערכת עם שירותים מבוססי ענן היכולה לספק תמיכה במיליוני משתמשים תוך כדי שמירה על תקציב סביר ",

                            squid_solution_title: "פתרונות",
                            squid_solution_description1: "לאחר תכנון הארכיטקטורה באמצעות שירותים מבוססי ענן AWS, היא הוצגה ללקוח ואושרה.",
                            squid_solution_description2: "מתכנת הבקהנד שלנו התחיל לעבוד על ההגדרה בעוד שאר הצוות עבד על הפרונטהנד, אפליקציות לנייד ומודלים של נתונים לשימוש.",
                            squid_solution_description3: "דגמי הנתונים, שנכתבו ב-פייטון, שולבו כשירות ענן המשמש את יישומי האינטרנט והנייד.",

                            squid_techstack: "טכנולוגיות",
                            squid_techstack1: "PHP",
                            squid_techstack2: "N.JS",
                            squid_techstack3: "AWS",
                            squid_techstack4: "Kotlin",
                            squid_techstack5: "Swift",
                            squid_techstack6: "Python",

                            squid_results_title: "תוצאה",
                            squid_result_title1: "⭡ יצירת לידים",
                            squid_result_description1: "הודות לעיצוב שהצוות שלנו עשה, הן בהיקפים הטכניים והן בהיקפים של ממשק המשתמש, הוא הפך את תהליך היצירה והניהול של לידים חדשים ליעיל וקל יותר.",
                            squid_result_title2: "פחות זמן ניהול",
                            squid_result_description2: "השימוש בפתרונות הבינה המלאכותית שלנו עזר להפחית את זמן ההוצאה לניהול משימות בכ-20%.",
                            squid_result_title3: "אורקסטריישן טוב יותר",
                            squid_result_description3: "פיתחנו כלים המופעלים על ידי בינה מלאכותית כדי לשפר את הניהול הרב-ערוצי.",


                        },


                        quickspace: {

                            qs_title: "QUICKSPACE",
                            qs_subtitle1: "אתר נדל״ן מבוסס-ענן",
                            qs_subtitle2: "פתרון מבוסס ענן עם יכולות בינה מלאכותית כדי לתת תוצאות יותר טובות עבור המשתמשים",
                            qs_description: "תיאור הפרויקט",
                            qs_client_title: "הלקוח",
                            qs_product_title: "המוצר",
                            qs_work_title: "העבודה",
                            qs_client_description: "הלקוח הוא חברת נדל״ן שרצתה לשדרג את האתר הישן שלה באתר מתקדם עם יכולות ביצוע יותר טובות ומראה יותר אטרקטיבי שמעודכן לטכנולוגיות עדכניות",
                            qs_product_description: "בניית אפליקציית ווב המאפשרת למשתמש מצד אחד לחפש נכסים לפי קריטריונים מסויימים ומצד שני להציע התאמות דומות ע״ס נתונים שהכניס למערכת תוך משם דגש על התאמה אישית",
                            qs_work_description: "בנינו אתר שמחובר לשירותי ענן רלבנטים , יצרנו מודלים להפקת התוצאות הנדרשות תוך הקפדה על זמן ביצוע מינימלי",

                            qs_profile: "פרופיל",
                            qs_profile_country_title: "מדינה",
                            qs_profile_country_value: "USA",
                            qs_profile_industry_titile: "תעשייה",
                            qs_profile_industry_value: "Real Estate",
                            qs_profile_team_titile: "הצוות שלנו",
                            qs_profile_team_value: "4 - מהנדסי תוכנה , מעצבת ומ. פרויקט ",

                            qs_goals: "ייעדים ומטרות",
                            qs_goal1: "עיצוב ובניית האתר",
                            qs_goal2: "יכולת תוכן דינאמי",
                            qs_goal3: "ניהול המידע בענן",
                            qs_goal4: "AI פיתוח מודלי ",


                            qs_challanges: "אתגרים",
                            qs_challange_title1: "השהייה בתוצאות",
                            qs_challange_1: "לקחנו בחשבון יתכנות של עיכובים בהצגת הנתונית למשתמש כתוצאה של ביצועי רשת וניהול המידע והמודלים בענן",
                            qs_challange_title2: "אינטגרצית מידע",
                            qs_challange_2: "היה חשוב שהמידע והתוצאות שהפתרון שלנו מציג ללקוח או מועבר למערכת הניהול לקוחות של הלקוח נכונים, גם מהסיבה שהם מבוססים על אינטגרציה מול המערכות של הלקוח ואינטגרציה לא נכונה עשויה להוביל לטעויות",
                            qs_challange_title3: "תמיכה מרובה",
                            qs_challange_3: "היה חשוב שהפתרון שלנו ידע לתמוך במיליוני משתמשי קצה בעומסים שונים ובזמנים שונים מבלי לאבד מיעילות הביצוע שלו",

                            qs_solution_title: "פתרונות",
                            qs_solution_description1: "הצוותים שלנו יצרו את הארכיטקטורה ברמה גבוהה ואת עיצוב . הגדרנו באילו שירותים בתוך אמזון צריך להשתמש.",
                            qs_solution_description2: "באמצעות מערכי נתונים ושימוש בכלים כמו טנסרפלו, מהנדס הבינה מלאכותית שלנו פיתח מודלים כדי לספק תוצאות דומות עבור דגימות נתונות",
                            qs_solution_description3: "שילבנו את מסד הנתונים שלנו עם מקורות משתמשים קיימים, שילבנו את כל הפתרונות לפריסה ובדקנו שכל הדרישות מיושמות כהלכה.",

                            qs_techstack: "טכנולוגיות",
                            qs_techstack1: "PHP",
                            qs_techstack2: "N.JS",
                            qs_techstack3: "Python",
                            qs_techstack4: "WPS",


                            qs_results_title: "תוצאה",
                            qs_result_title1: "⭡ עלייה ברווח",
                            qs_result_description1: "על ידי מתן פתרון להתאמה אישית, החיפוש של המשתמשים הביא לשיעור גבוה יותר עבור התאמת הנכס שלהם, מה שהוביל לעלייה בהכנסות הלקוחות.",
                            qs_result_title2: "שיפור המדידה",
                            qs_result_description2: "הכלים שפיתחנו אפשרו ללקוח לשפר את האופן שבו הם מודדים את ביצועי המשתמשים בתוך האתר.",
                            qs_result_title3: "שיפור ניהול לקוחות",
                            qs_result_description3: "הפתרון שפיתחנו, כולל אינטגרציה עם מערכת הניהול לקוחות שלהם, אפשר ללקוח לשפר את ניהול הלקוחות שלו דרך האתר.",


                        },


                        saasly: {

                            saasly_title: "SAASLY",
                            saasly_subtitle1: "פלטפורמה מתקדמת לניתוח מידע",
                            saasly_subtitle2: "מערכת עסק לעסק מבוססת ענן עם יכולות מתקדמות לעזור לעסקים לנתח את המידע והביצועים של המוצרים והמשתמשים שלהם ",
                            saasly_description: "תיאור הפרויקט",
                            saasly_client_title: "הלקוח",
                            saasly_product_title: "המוצר",
                            saasly_work_title: "העבודה",
                            saasly_client_description: "הלקוח הוא סטראטאפ בתחילת דרכו , בשלבים ראשונים של בניית המוצר עם תקציב וכח אדם דל",
                            saasly_product_description: "מוצר מונחה מידע המבוסס על שירותי ענן המשתלב עם מוצרים של לקוחות ע״י ממשקי תוכנה ומנתח את הביצועים שלהם ברמות שונות של מידע",
                            saasly_work_description: "אספנו דרישות מהלקוח ובנינו ארכיטקטורה טכנית . המעצב שלנו בנו עיצוב ופרוטוטייפ שעזר להתקדם בפיתוח.",

                            saasly_profile: "פרופיל",
                            saasly_profile_country_title: "מדינה",
                            saasly_profile_country_value: "UK",
                            saasly_profile_industry_titile: "תעשייה",
                            saasly_profile_industry_value: "תוכנה וניתוח מידע",
                            saasly_profile_team_titile: "הצוות שלנו",
                            saasly_profile_team_value: "5 - מ.פרויקט, מתכנתים ומעצב",

                            saasly_goals: "ייעדים ומטרות",
                            saasly_goal1: "פיתוח דשבורד ועמודים נוספים עם פיצ'רים של ניתוח המידע",
                            saasly_goal2: "בנינו מוניטור וכלי ניהול עבור הלקוח",
                            saasly_goal3: "בניית ממשק תוכנה מול מוצרי משתמשים",
                            saasly_goal4: "פיתוח שירותי ענן לניהול המידע",

                            saasly_challanges: "אתגרים",
                            saasly_challange_title1: "דיוק המידע",
                            saasly_challange_1: "מאחר ותכלית המוצר העיקרית היא להצגיע נתונים, מאוד חשוב שהמידע יהיה מדויק",
                            saasly_challange_title2: "ביצועים",
                            saasly_challange_2: "חשוב שהמידע המחושב בענן בזמן אמת יוצג למשתמש בלי דיליי",
                            saasly_challange_title3: "חויית משתמש",
                            saasly_challange_3: "מאחר וזה מוצר מונחה נתונים , עמוס במספרים וחישובים, נדרש לייצר חוויית משתמש ידודתית שהמשתמש יוכל לעבוד במוצר בקלות",

                            saasly_solution_title: "פתרונות",
                            saasly_solution_description1: "יצרנו ארכיטקטורה עבור ניהול המידע בין שירותי הענן וממשק הפרונטהנד",
                            saasly_solution_description2: "יצרנו עיצוב למוצר אשר עזר לפתח את הפונקציונליות הנדרשת",
                            saasly_solution_description3: "בדקנו את המוצר מול כלים שונים ובעזרת לקוחות פוטנציאליים של הלקוח לוודא שהנתונים עוברים היטב באינטגרציה מול המוצר שלהם",

                            saasly_techstack: "טכנולוגיות",
                            saasly_techstack1: "React",
                            saasly_techstack2: "N.JS",
                            saasly_techstack3: "AWS",
                            saasly_techstack4: "MySQL",
                            saasly_techstack5: "Python",

                            saasly_results_title: "תוצאה",
                            saasly_result_title1: "שחרור מוצר",
                            saasly_result_description1: "עזרנו ללקוח לשחרר לשוק מוצר שעובד ונותן ערך ללקוחות שלו תוך פרק זמן של כ-9 חודשים",
                            saasly_result_title2: "דיוק תוצאות",
                            saasly_result_description2: "המוצר הצליח לייצר בדרסה הראשונה דיוק נתונים של ניתוח אנליטי עם שיעור דיוק של 92% שהשתפר עם הזמן.",
                            saasly_result_title3: "הורדת זמן השהייה",
                            saasly_result_description3: "שיפרנו את הפתרון שלנו במהלך הזמן שבו הוא הצליח לספק תוצאות בפחות מ-3 שניות.",
                        },

                        logoipsum: {

                            logo_title: "LOGOIPSUM",
                            logo_subtitle1: "פלטפורמת ניהול לעסקים",
                            logo_subtitle2: "הצטרפנו לצוותים של הלקוח כדי לפתח כלי ניהול המיועד לארגונים שמוכנים לשפר את גישת העבודה שלהם.",
                            logo_description: "תיאור הפרויקט",
                            logo_client_title: "הלקוח",
                            logo_product_title: "המוצר",
                            logo_work_title: "העבודה",
                            logo_client_description: "הלקוח הוא חברת תוכנה צעירה עם משאבים נמוכים שרצתה לזרז את תהליך הפיתוח של המוצר שלה. הצטרפנו עם הידע והניסיון שלנו וסייענו בפיתוח וייעוץ לקיצור תהליך השחרור.",
                            logo_product_description: "המוצר הוא פלטפורמת 'ניהול עבודה' באינטרנט ובמובייל שנועדה לעזור לצוותים לארגן, לעקוב ולנהל את עבודתם.",
                            logo_work_description: "הצטרפנו לצוות האורגני ועזרנו לתכנן את פתרון הארכיטקטורה. המעצב שלנו סיפק את העיצובים ואת אב הטיפוס עבור כל פלטפורמה. לבסוף, חיברנו את פתרון הענן עם יישומי המובייל והאינטרנט.",

                            logo_profile: "פרופיל",
                            logo_profile_country_title: "מדינה",
                            logo_profile_country_value: "USA",
                            logo_profile_industry_titile: "תעשייה",
                            logo_profile_industry_value: "SW",
                            logo_profile_team_titile: "הצוות שלנו",
                            logo_profile_team_value: "6 - מ.פרויקט,מובייל, פולסטאק,מעצב",

                            logo_goals: "ייעדים ומטרות",
                            logo_goal1: "בניית מוצר מרובה פלטפורמות",
                            logo_goal2: "יצירת כלים מבוססי בינה מלאכותית",
                            logo_goal3: "ניהול מידע בענן",
                            logo_goal4: "API ממשק ",

                            logo_challanges: "אתגרים",
                            logo_challange_title1: "סנכרון פלטפורמות",
                            logo_challange_1: "בניית פתרון חוצה פלטפורמות מעוררת מספר אתגרים. לדוגמה, ייתכן שפיתוח תכונה באנדרואיד לא יעבוד ב-אייפון עקב מגבלות פלטפורמה.",
                            logo_challange_title2: "סנכרון מידע",
                            logo_challange_2: "זה היה מאתגר לסנכרן את נתוני הענן עם כל פלטפורמה כראוי. השרת שבנינו היה צריך לתמוך במיליוני בקשות מכל פלטפורמה ולהשוות את התגובה בין השתיים.",
                            logo_challange_title3: "איזון עומסים",
                            logo_challange_3: "The server had to properly support and manage millions of requests with scaling capabilities.",

                            logo_solution_title: "פתרונות",
                            logo_solution_description1: "תכננו והטמענו את ניהול מסד הנתונים באמצעות שירותי אמזון'.",
                            logo_solution_description2: "השתמשנו בדאטה-סימק של אמזון כדי לשפר את הסנכרון של שלושת הפלטפורמות",
                            logo_solution_description3: "בהתבסס על הדרישות של צוות המוצר של הלקוח, המעצב שלנו, באמצעות כלים כמו פיגמה ואילוסטרייטור, יצר את העיצובים ואבות הטיפוס הרלוונטיים.",

                            logo_techstack: "טכנולוגיות",
                            logo_techstack1: "R.Native",
                            logo_techstack2: "N.JS ",
                            logo_techstack3: "Python",
                            logo_techstack4: "AWS",

                            logo_results_title: "תוצאה",
                            logo_result_title1: "עמידה בזמנים",
                            logo_result_description1: "עזרנו ללקוח לשחרר את המוצר בזמן לאחר ~10 חודשים של עבודה אינטנסיבית.",
                            logo_result_title2: "⭡ משתמשים חדשים",
                            logo_result_description2: "הממשק הידידותי שהצוות שלנו יצר, והאפליקציה האחורית, יחד עם עבודת שיווק טובה של הלקוח שלנו, עזרו למוצר להיות מאומץ על ידי לקוחות רבים.",
                            logo_result_title3: "מדידת ביצועים",
                            logo_result_description3: "הכלי האנליטי שבנינו עוזר ללקוח למדוד את הצלחת המוצר שלו.",
                        },

                        fastgrab: {

                            fg_title: "FASTGRAB",
                            fg_subtitle1: "אפליקציית משלוחי אוכל",
                            fg_subtitle2: "אפליקציית משלוחי אוכל למכשירי אנדרואיד ואייפון. דוגמאות תכונה: הזמנת מזון, רכישה וחיסול. בקרת תוכן דינמית על ידי הלקוח. תכונת צ'אט. ניתוח ביצועי אפליקציה.",
                            fg_description: "תיאור הפרויקט",
                            fg_client_title: "הלקוח",
                            fg_product_title: "המוצר",
                            fg_work_title: "העבודה",
                            fg_client_description: "הלקוח הוא חברת משלוחי מזון חדשה שחיפשה פתרון נייד לעסק שלהם.",
                            fg_product_description: "המוצר הוא אפליקציה סלולרית למכשירי אנדרואיד ואייפון. לקוחות יכולים להזמין אוכל משותפי המסעדות ובתי הקפה של הפלטפורמה, או לאסוף את ההזמנה או לקבל אותה משותפי המשלוחים של הפלטפורמה.",
                            fg_work_description: "התאמת הדרישות עם הלקוח. הכנת עיצובי חוויית וממשקי משתמש וארכיטקטורה טכנית. המפתחים החלו לעבוד על הפתרון מבוסס הענן ופיתוח מובייל.",

                            fg_profile: "פרופיל",
                            fg_profile_country_title: "מדינה",
                            fg_profile_country_value: "UK",
                            fg_profile_industry_titile: "תעשייה",
                            fg_profile_industry_value: "מזון",
                            fg_profile_team_titile: "הצוות שלנו",
                            fg_profile_team_value: "5 - מ.פרויקט, מובייל, ענן,מעצב",

                            fg_goals: "ייעדים ומטרות",
                            fg_goal1: "בניית אפליקציית משלוחים",
                            fg_goal2: "עסקי מזון יכולים להרשם",
                            fg_goal3: "משתמשים יכולים להזמין",
                            fg_goal4: "שליחים יכולים לקבל הזמנות",


                            fg_challanges: "אתגרים",
                            fg_challange_title1: "סנכרון אומני-צ'אנל",
                            fg_challange_1: "זה היה אתגר לסנכרן את כל הישויות של האפליקציה כדי לגרום לה לעבוד ביחד כמו שצריך.",
                            fg_challange_title2: "הגבלת זמן",
                            fg_challange_2: "נדרש ליישם את האפליקציה מאפס תוך 10-12 חודשים.",
                            fg_challange_title3: "מוצר רב-פלטפורמות",
                            fg_challange_3: "חלק מהתכונות המשולבות בצורה חלקה באנדרואיד אינן נתמכות באייפון ולהיפך.",

                            fg_solution_title: "פתרונות",
                            fg_solution_description1: "מעצב ה-UX/UI שלנו יצר אב טיפוס של Figma המותאם לדרישות הלקוח. המהנדסים הטכניים שלנו עבדו על הארכיטקטורה הטכנית של הפתרון.",
                            fg_solution_description2: "במקביל, התחלנו לפתח פתרון מבוסס ענן באמצעות שירותי AWS, וכדי ליישר קו עם מגבלת הזמן פיתחנו פתרון מובייל חוצה פלטפורמות באמצעות React Native.",
                            fg_solution_description3: "התגברנו על בעיות הגבלה בפלטפורמות הניידות, והמצאנו גישה יצירתית ליישום התכונות הנדרשות בשתי הפלטפורמות.",

                            fg_techstack: "טכנולוגיות",
                            fg_techstack1: "React",
                            fg_techstack2: "N.JS",
                            fg_techstack3: "Python",
                            fg_techstack4: "AWS",

                            fg_results_title: "תוצאה",
                            fg_result_title1: "עמידה בזמנים",
                            fg_result_description1: "שחררנו אפליקציית משלוח חוצת פלטפורמות בזמן שתואמת את דרישות הלקוח שלנו ונתנה להם ערך.",
                            fg_result_title2: "⭡ משתמשים חדשים",
                            fg_result_description2: "הפתרון שפיתחנו עבור הלקוח עזר להם למוצר שפיתחנו",
                            fg_result_title3: "⭡ עלייה במכירות",
                            fg_result_description3: "העבודה שלנו עזרה ללקוח לייצר רווחים על ידי מתן ערך למשתמשי הקצה.",
                        },

                        healthness: {

                            health_title: "HEALTHNESS",
                            health_subtitle1: "אפליקציית בריאות",
                            health_subtitle2: "פיתוח אפליקציית אנדרואיד ואייםון מבוססת בינה מלאכותית עם ראייה ממוחשבת לניתוח בריאות הפנים.",
                            health_description: "תיאור הפרויקט",
                            health_client_title: "הלקוח",
                            health_product_title: "המוצר",
                            health_work_title: "העבודה",
                            health_client_description: "חברה הפועלת בתעשיית הבריאות. המשימה של החברה היא לעזור לאנשים לדאוג לבריאותם ולרווחתם, לספק משוב על תהליך ההזדקנות ולתת עצות שימושיות לגבי בריאות אסתטית.",
                            health_product_description: "אפליקציה לנייד לניטור בריאות ויופי, המיועדת לשרת בני 18-60 אמריקאים, שהם בעיקר בריאים אך רוצים לעקוב אחר בריאותם כדי להאריך את תוחלת חייהם.",
                            health_work_description: "עיצב מערכת פורצת דרך שיכולה לרכז נתוני בריאות ולהגיע עם ציון מספר אחד כדי לשקף את טווח הבריאות הכולל של המשתמש ולעזור לחיות חיים ארוכים יותר, בריאים ומאושרים יותר.",

                            health_profile: "פרופיל",
                            health_profile_country_title: "מדינה",
                            health_profile_country_value: "USA",
                            health_profile_industry_titile: "תעשייה",
                            health_profile_industry_value: "בריאות",
                            health_profile_team_titile: "הצוות שלנו",
                            health_profile_team_value: "6-8 - מ.פרויקט,בינה מלאכותית,פולסטאק, ומובייל",

                            health_goals: "ייעדים ומטרות",
                            health_goals_subtitle: "פיתוח אפליקציית אייפון המופעלת על ידי בינה מלאכותית עם ראייה ממוחשבת לניתוח בריאות הפנים",
                            health_goal1: "איחוד מדדי בריאות מרובים לציון יחיד",
                            health_goal2: "עזרה למשתמשים להבין את מצב הבריאות שלהם ביחס לעמיתיהם",
                            health_goal3: "לספק התראות בריאות בזמן",
                            health_goal4: "גישה לאנשי מקצוע ומרפאות בדירוג הגבוה ביותר",
                            health_goal5: "לספק למשתמשים הערכות מדויקות של מצב העור באמצעות נתונים חזותיים",

                            health_challanges: "אתגרים",
                            health_challange_title1: "צבירת נתונים",
                            health_challange_1: "מציאת דרך לאסוף ביעילות נתוני משתמשים ממכשירים חכמים באמצעות עכבה ביו-חשמלית כדי ליצור ניקוד המופעל על ידי בינה מלאכותית",
                            health_challange_title2: "שילוב חזון",
                            health_challange_2: "יישום המומחיות שלנו בחישובים ויזואליים כדי לפתח תכונות כולל ניתוח פגמים בפנים, מעקב אחר הזדקנות והערכת גיל לכאורה.",
                            health_challange_title3: "דיוק",
                            health_challange_3: "התמודדות עם כל האתגרים הקשורים ליישום צ'אטבוט  עם מודלים של בינה מלאכותית כדי להסביר את תוצאות ניתוח הפנים ולספק המלצות מותאמות אישית.",

                            health_solution_title: "פתרונות",
                            health_solution_description1: "במהלך תהליך הפיתוח, הצוות שלנו עבד בגיבוש סגור עם רופא בעל ידע מעמיק בבינה מלאכותית ולמידת מכונה, כמו גם מעצב חווית משתמש/ממשק משתמש בצד הלקוח.",
                            health_solution_description2: "כדי לבנות פתרון מדויק ביותר, השתמשנו במערך נתונים מותאם אישית המסומן בהנחיית מומחים רפואיים להדרכה של מודלי בינה מלאכותית שלנו. כדי להבטיח את האיכות והאמינות של הנתונים, ערכנו סקירות איכות נתונים קבועות בהנחיית מומחים רפואיים המעורבים בפרויקט.",
                            health_solution_description3: "הצוות שלנו סיפק את הפתרון בצורה של יישומי אנדרואיד ואייםון המחברים מכשירים חכמים של משתמשים ואיסוף נתונים המופעלים באמצעות מודלים של בינה מלאכותית עטופים בממשקי תוכנה .",

                            health_techstack: "טכנולוגיות",
                            health_techstack1: "AWS",
                            health_techstack2: "Kotlin",
                            health_techstack3: "Swift",
                            health_techstack4: "Python",

                            health_results_title: "תוצאה",
                            health_result_title1: "אינטגרציות",
                            health_result_description1: "הצוות שלנו סיפק את הפתרון הזה בצורה של אפליקציית אנדרואיד ואייפון המחברת מכשירים חכמים של משתמשים ומצבירה נתונים המופעלים באמצעות מודלי בינה מלאכותית עטופים בממשקי תוכנה",
                            health_result_title2: "85% דיוק המודלים",
                            health_result_description2: "הבטיח את קצב הדיוק הגבוה ביותר האפשרי של מודלים של למידת מכונה בליבת אפליקציית הבריאות שלנו, ותרם באופן משמעותי לחוויית משתמש מתקדמת ולאמינות הצעות בריאות.",
                            health_result_title3: "מומחיות בינה מלאכותית",
                            health_result_description3: "סיפקה את המומחיות הנרחבת שלנו בלמידת מכונה, ראיית מחשב, דור מוגבר של אחזור ומודלים של שפות גדולות כדי לפתח פונקציונליות ייחודית באפליקציית אנדרואיד ואייפון עוצמתית.",
                        },

                        rides: {

                            rides_title: "RIDES",
                            rides_subtitle1: "אפליקציה לחנות אופניים",
                            rides_subtitle2: "אפליקציה לאנדרואיד ואייפון המיועדת לחנות אופניים. דוגמאות לפיצ'רים: רכישה וסליקה, תוכן דינמי. שיתוף וצפייה במסלולי רעיבה וניתוח ביצועי אפליקציה.",
                            rides_description: "תיאור הפרויקט",
                            rides_client_title: "הלקוח",
                            rides_product_title: "המוצר",
                            rides_work_title: "העבודה",
                            rides_client_description: "חנות אופניים שרצתה להרחיב את הפעילות האופליין שלה ולהגדיל את החשיפה עם יותר משתמשים באמצעות אפליקציה סלולרית.",
                            rides_product_description: "אפליקצייה לנייד עבור אנדרואיד ואייפון. מאפשרת למשתמשים להזמין אופניים ואביזרים, אך גם ליצור קהילה של רוכבים על ידי מתן מידע על רכיבות ושבילים",
                            rides_work_description: "הצוות שלנו בנה את הארכיטקטורה והעיצוב עבור אנדרואיד ואייפון כאחד. פיתחנו פתרונות מבוססי ענן לניהול נתונים ותקשורת עם האפליקציה, תכננו פונקציות תשלום וסליקה ובנינו פתרון מבוסס מיקום לרכיבה שבילים.",

                            rides_profile: "פרופיל",
                            rides_profile_country_title: "מדינה",
                            rides_profile_country_value: "UK",
                            rides_profile_industry_titile: "תעשייה",
                            rides_profile_industry_value: "אופניים",
                            rides_profile_team_titile: "הצוות שלנו",
                            rides_profile_team_value: "5 - מ.פרויקט,מתכנתי מובייל וענן, מעצב",

                            rides_goals: "ייעדים ומטרות",
                            rides_goal1: "אפליקציית אי-קומרס",
                            rides_goal2: "מימוש באנדרואיד ואייפון בו״ז",
                            rides_goal3: "תוכן דינאמי",
                            rides_goal4: "ניתוח והצגה של שבילים על מפות",

                            rides_challanges: "אתגרים",
                            rides_challange_title1: "דיוק הצגת נתוני מפה",
                            rides_challange_1: "האפליקציה סיפקה ואיפשרה למשתמשים לשתף מפות רכיבה מבוססות לוויין. האתגר שעמד בפנינו היה לפתח מימוש של הצגת השבילים כמו שצריך בכל פלטפורמה והדיוק הנדרש.",
                            rides_challange_title2: "סנכרון דאטה",
                            rides_challange_2: "היינו צריכים לבנות פתרון מבוסס ענן שיסנכרן את הנתונים בשתי הפלטפורמות כראוי.",
                            rides_challange_title3: "אפליקציית חנות",
                            rides_challange_3: "נדרשים תשלומים ואישור משתי הפלטפורמות כדי לעבוד עם חשבון אחד של אותו פתרון.",

                            rides_solution_title: "פתרונות",
                            rides_solution_description1: "התאמנו את התוכנית הטכנית והעיצוב לדרישות המוצר שהגדרנו יחד עם הלקוח.",
                            rides_solution_description2: "הצוותים שלנו התחילו לעבוד על פתרון הענן באמצעות שירותי אמזון ובחרנו בפלאטר כפתרון רב-פלטפורמות להאצת הפיתוח במובייל.",
                            rides_solution_description3: "שילבנו את הפתרון עם מערכת תשלומים שתמכה בקלות בשתי הפלטפורמות והיא הייתה מותאמת לנתונים שניהלנו עם שירותי הענן שלנו.",
                            rides_solution_description4: "לאור הבקשה לבנות קהילת רוכבים, בנינו פתרון שאפשר למשתמשים לשתף ולהשתמש במפות רכיבה מבוססות מיקום-לווין וצפייה בתוך האפליקציה.",

                            rides_techstack: "טכנולוגיות",
                            rides_techstack1: "Flutter",
                            rides_techstack2: "Zelle",
                            rides_techstack3: "Python",
                            rides_techstack4: "AWS",

                            rides_results_title: "תוצאה",
                            rides_result_title1: "העלאת רווחים",
                            rides_result_description1: "פתרון אפליקציית-החנות שבנינו עבור אנדרואיד ואייפון עזרה ללקוח להגדיל את סך ההכנסות שלו ממשתמשי הקצה החדשים שהשתמשו באפליקציה ובצעו רכישות של אופניים ואביזרים.",
                            rides_result_title2: "עלייה במשתמשים",
                            rides_result_description2: "פתרון הקהילה שפיתחנו עזר ללקוח להגביר את המעורבות עם משתמשים קיימים ולרכוש משתמשים חדשים שמוכנים להשתמש במידע הרכיבה בתוך האפליקציה, לשתף מידע עם משתמשים אחרים ולמעשה לקנות פריטים מהאפליקציה.",
                            rides_result_title3: "עמידה בזמנים",
                            rides_result_description3: "פיתוח האפליקציות לתכונות הנדרשות, בשתי הפלטפורמות המחוברות לשירותי הענן, הותאם למסגרת הזמן של ~8 חודשים.",
                        },

                        netpay: {

                            netpay_title: "NETPAY",
                            netpay_subtitle1: "אפליקציית ארנק דיגיטלי לגוף פיננסי",
                            netpay_subtitle2: "פיתוח אפליקציה חדשנית לניהול הוצאות",
                            netpay_description: "תיאור הפרויקט",
                            netpay_client_title: "הלקוח",
                            netpay_product_title: "המוצר",
                            netpay_work_title: "העבודה",
                            netpay_client_description: "הלקוח הוא מוסד מוביל החלוץ בפתרונות ניהול פיננסיים חדשניים, הידוע במומחיות בתכנון אסטרטגי, ניהול סיכונים, ייעוץ השקעות ושילוב פינטק.",
                            netpay_product_description: "אפליקציית ארנק דיגיטלי המאפשרת למשתמשים לבצע עסקאות ולנהל הוצאות בצורה טובה יותר.",
                            netpay_work_description: "בנינו אפליקציית ארנק לאייפון ואנדרואיד, המאפשרת לבצע עסקאות במטבעות שונים ומסייעת למשתמשי הקצה לנהל את ההוצאות שלהם בצורה טובה יותר. כדי להתיישר עם תקנים של תעשיית הפינטק, היה נדרש לשמור על הנתונים שנאספו בטוחים ומאובטחים.",

                            netpay_profile: "פרופיל",
                            netpay_profile_country_title: "מדינה",
                            netpay_profile_country_value: "USA",
                            netpay_profile_industry_titile: "תעשייה",
                            netpay_profile_industry_value: "פינטק",
                            netpay_profile_team_titile: "הצוות שלנו",
                            netpay_profile_team_value: "5 - מ.פרויקט,מובייל,ענן,אבטחת מידע,מעצב",

                            netpay_goals: "ייעדים ומטרות",
                            netpay_goal1: "בניית אפליקציית ניהול כספים",
                            netpay_goal2: "הוספת אוטמציה לתמיכת לקוחות",
                            netpay_goal3: "פיתוח מודלי בינה מלאכותית להוצאות",
                            netpay_goal4: "אבטחת המידע במובייל ובענן",

                            netpay_challanges: "אתגרים",
                            netpay_challange_title1: "פתרון הוליסטי",
                            netpay_challange_1: "שלב את האפליקציה עם מערכות החברה כדי להגשים את הפתרון ההוליסטי הזה.",
                            netpay_challange_title2: "דיוק המידע",
                            netpay_challange_2: "בשל העדינות שלו, היה חשוב מאוד לטפל בנתונים שהועברו מהאפליקציה לשרתים, ולהיפך, בצורה המדויקת ביותר.",
                            netpay_challange_title3: "אבטחת המידע",
                            netpay_challange_3: "באשר לסיכונים הידועים של ארנק דיגיטלי, היה חיוני לפתח פתרון מאובטח שיבטיח את בטיחות הנתונים והפרטיות של המשתמשים.",

                            netpay_solution_title: "פתרונות",
                            netpay_solution_description1: "כדי להתמודד עם האתגרים של הלקוח, פיתחנו אפליקציה ידידותית למשתמש שמטרתה לפשט את מעקב ההוצאות והניהול הפיננסי באמצעות חבילה של תכונות אפקטיביות ביותר. אפליקציה זו אפשרה למשתמשים לקבל הודעה בזמן, לקבל תמונות ברורות של לוח המחוונים לתזכורות על הוצאות קרובות, ולקבל תובנות לגבי הכנסות, הוצאות ואיזון. עם פתרון מנהל ההוצאות משתמשים יכולים לנהל ללא מאמץ כרטיסי אשראי/חיוב באמצעות סריקה או הזנה ידנית, יחד עם אפשרויות נושא. הדגש העיקרי היה על פשטות ונוחות, שכן האפליקציה משמשת כלי רב ערך לשמירה על הארגון הפיננסי ובקרת ההוצאות.",
                            netpay_solution_description2: "שילבנו את האפליקציה עם מערכות החברה ואישרנו שהנתונים מסונכרנים כראוי בשני הכיוונים.",
                            netpay_solution_description3: "לבסוף, אבטחנו את הנתונים באמצעות סטנדרטים גבוהים ומקבולים והתאמנו את הפתרון לתקן התשלום המוביל",

                            netpay_techstack: "טכנולוגיות",
                            netpay_techstack1: "Swift",
                            netpay_techstack2: "Kotlin",
                            netpay_techstack3: "AWS",
                            netpay_techstack4: "Python",

                            netpay_results_title: "תוצאה",
                            netpay_result_title1: "שיפור התנהלות כספית",
                            netpay_result_description1: "שיפור בהרגלי הוצאות של משתמשי הקצה בכשלושים אחוז ע״י פתרון ניהול הוצאות שפתחנו באמצעות השימוש במודלים של הבינה המלאכותית.",
                            netpay_result_title2: "ירידה של כ-70 אחוז בעמלות על איחור",
                            netpay_result_description2: "ירידה בעמלות איחור ובקנסות עקב תזכורות לתשלום בזמן.",
                            netpay_result_title3: " עלייה באנגייג'מנט ",
                            netpay_result_description3: "ההתקשרות הכוללת של משתמשי הקצה במוצרי החברה גדלה ב-39% לאחר פרסום האפליקציה.",
                        },

                        ux: {

                            ux_title: "חוויית משתמש",
                            ux_subtitle1: "אנו יוצרים עיצובי חוויית משתמש ברורים, פשוטים וידידותיים כדי לעזור למשתמשים לנווט בקלות בתוך המוצר",


                            ux_category_title1: "חוויית משתמש",
                            ux_category_description1: "אפליקציית הנייד או, הווב שלכם, יכולה לספק את התמורה הטובה ביותר למשתמשים שלה מבחינה פונקציונלית, אבל ללא חווית משתמש טובה, או ממשק משתמש אטרקטיבי, הם עלולים ללכת לאיבוד במוצר שלכם ולעבור למוצר מתחרה.",
                        
                            ux_category_title2: "ממשק משתמש",
                            ux_category_description2: "אנו יוצרים עיצובי ממשק משתמש ברורים ואטרקטיביים כדי להעשיר את חווית המשתמש של המוצר על ידי התאמה לקונספטים עיצוביים ושימוש ביצירתיות שלנו.",
                        
                            ux_category_title3: "עיצובים למרקטינג",
                            ux_category_description3: "אנו יוצרים ויזואליים אטרקטיביים עבור קמפיינים, באנרים, הערות פרסום וצרכים שיווקיים אחרים.",
                        
                            ux_category_title4: "עיצובים לדוקמנטציה",
                            ux_category_description4: "אנו יוצרים ויזואליות וחווית משתמש בתיעוד, מדריכים למשתמש, בלוגים, מדריכים ועוד.",

                            

                            ux_title2: "שמירה על עקביות של זרימת המשתמש לאורך כל המסע",
                            ux_bullet1: "פתרון בעיות ספציפיות של משתמש",
                            ux_bullet2: "לא להמציא את הגלגל מחדש",
                            ux_bullet3: "כמה שפחות צורך בעקומת למידה",

                            ux_title3: "המשתמש במרכז",
                            ux_bullet4: "הצרכים של המשתמש במרכז",
                            ux_bullet5: "בניית חוויית המשתמש סביב המשתמש",
                            ux_bullet6: "מחקר משתמש לפני הכל",

                            ux_title4: "קונטקסט",
                            ux_bullet7: "לא לבנות עיצוב בחלל ריק",
                            ux_bullet8: "איך המשתמש יתקשר עם המוצר",
                            ux_bullet9: "איך גורמים חיצוניים ישפיעו",
                            ux_bullet10: "איזה מצבים רגשיים נרצה להעביר",

                            ux_title5: "היררכיה",
                            ux_bullet11: "היררכיה חזותית של דפים בודדים",
                            ux_bullet12: "מפת אתר של המוצר להתמצאות",
                            ux_bullet13: "לעזור למשתמש לנווט במוצר שלך",
                            ux_bullet14: "כיצד אלמנטים מונחים על דף",

                            ux_title6: "נגישות",
                            ux_bullet15: "נגישות לכמה שיותר משתמשים",
                            ux_bullet16: "כולל אנשים עם מוגבלויות",
                            ux_bullet17: "קונטרסט חזק ללקויי ראייה",

                            ux_title7: "שימוש",
                            ux_bullet18: "כמה המוצר קל לשימוש",
                            ux_bullet19: "תהליך למידה קצר",
                            ux_bullet20: "זמן סיום משימה קצר ויעיל",
                            ux_bullet21: "שמשתמש חוזר כמה צריך להזכר",
                            ux_bullet22: "כמה טעויות משתמשים",
                            ux_bullet23: "כמה המשתמשים נהנים במוצר",

                            uxui_stack: "כלי העיצוב שלנו",
                            ux_techstack1: "Figma",
                            ux_techstack2: "Balsamiq",
                            ux_techstack3: "Axure",
                            ux_techstack4: "Miro",
                            ux_techstack5: "Sketch",

                        },


                        ui: {

                            ui_title: "ממשק משתמש",
                            ui_subtitle1: "אנו יוצרים עיצובי ממשק משתמש ברורים ואטרקטיביים כדי להעשיר את חווית המשתמש בתוך המוצר",

                            ui_title2: "בהירות",
                            ui_bullet1: "ניווט אינטואיטיבי של משתמשים",
                            ui_bullet2: "נראות ברורה של רכיבים",
                            ui_bullet3: "מטרתו של כל מרכיב ברורה",

                            ui_title3: "רספונסיביות",
                            ui_bullet4: "מתאים למגוון רחב יותר של מכשירים",
                            ui_bullet5: "חווית משתמש חלקה",
                            ui_bullet6: "אלמנטים שמישים ונגישים",

                            ui_title4: "הפחתת עומס קוגניטיבי",
                            ui_bullet7: "הימנע מצורך המשתמש לנתח",
                            ui_bullet8: "החלת כלל 3 קליקים",
                            ui_bullet9: "מזעור ריקול לטובת הכרה",

                            ui_title5: "יישורים",
                            ui_bullet10: "סידור חזותי של פריטים",
                            ui_bullet11: "הזמנה קלה עם ניווט למשתמש",

                            ui_title6: "רשתות",
                            ui_bullet12: "תן לממשק תחושת מבנה",
                            ui_bullet13: "השגת איזון חזותי",

                            ui_title7: "מינימליזם",
                            ui_bullet14: "שמירה על מינימליזם עם שימושיות",

                            ui_techstack: "Tech Stack",
                            ui_techstack1: "Figma",
                            ui_techstack2: "Photoshop",
                            ui_techstack3: "Illustrator",
                            ui_techstack4: "JIM.",
                            ui_techstack5: "Zeplin",
                        },



                        marketing: {

                            market_title: "מרקטינג ומדיה",
                            market_subtitle1: "אנחנו מייצרים גרפיקות אטרקטיביות לקמפיינים וצרכי שיווק ומגיה אחרים",

                            market_title2: "ניגודיות וחזרתיות",
                            market_bullet1: "משיכת עיניים לחלקים חשובים",
                            market_bullet2: "הדגשה ע״י סטייל של טקסט",
                            market_bullet3: "חזרה כדי לדחוף את הברנד",
                            market_bullet4: "חזרתיות עטזרת להתמצא",

                            market_title3: "יישור וקרבה",
                            market_bullet5: "שימוש ביישור נכון להקל על קריאה",
                            market_bullet6: "קירוב אל אלמנטים יחד",
                            market_bullet7: "קירוב חשוב במיוחד עם כיתוב",

                        },


                        dcos: {

                            dcos_title: "עיצובים וגרפיקות לדוקמנטציה ותיעודים כלליים",
                            dcos_subtitle1: "אנחנו מייצרים גרפיקות ועיצובים כולל חוויית משתמש נכונה לדוקמנטציות ומדריכים עבור תוכנות של לקוחות, בלוגים ועוד",

                            dcos_title1: "דוקמנטציה ןמדריך למשתמש",
                            dcos_bullet1: "עיצבנו דוקמנטציות ברורות ונוחות",
                            dcos_bullet2: "עזרנו לקוחות לתכנן את חוויית המשתמש",
                            dcos_bullet3: "יצרנו גרפיקות ואנימציות מתאימות לאופי החברה והמוצר",

                            dcos_title2: "מסמך שחרור גרסה",
                            dcos_bullet4: "הדגשת הטקסט הרלבנטי",
                            dcos_bullet5: "סידור נכון של הטקסט",
                            dcos_bullet6: "קישורים נכונים בטקסט",

                            dcos_title3: "בלוגים",
                            dcos_bullet7: "הכנת גרפיקות לבלוגים",
                            dcos_bullet8: "תכנון חוויית משתמש כללית",

                            dcos_techstack: "Tech Stack",
                            dcos_techstack1: "Readme",
                            dcos_techstack2: "Doxygen",
                            dcos_techstack3: "ClickUp",
                            dcos_techstack4: "Document360",
                            dcos_techstack5: "GitBook",
                            dcos_techstack6: "Tettra",


                        },
                    },



                },

                general: {
                    cta: {
                        cta_title: "?האם אתם מוכנים לקדם את העסק שלכם",
                        cta_subtitle1: "צרו קשר ובואו נבנה משהו מדהים",
                        cta_subtitle2: "ביחד",
                        cta_messgae: "שליחת הודעה",
                    },
                },

                footer: {
                    logo: {
                        logo_title: "פיתוח תוכנה וייעוץ",
                        logo_address: "תל אביב, ישראל",

                    },
                    company: {
                        company_title: "חברה",
                        company_about: "אודות",
                        company_aproach: "הגישה שלנו",
                        company_policy: "מדיניות",
                        company_terms: "תנאי שימוש",
                        company_contact: "יצירת קשר",
                    },
                    services: {
                        services_title: "שירותים",
                        services_web: "Web פיתוח",
                        services_mobile: "Mobile פיתוח",
                        services_uxui: "UX/UI פתרונות",
                        services_consult: "IT ייעוץ",
                    },
                    social: {
                        social_title: "רשתות חברתיות",
                        social_subtitle: "עקבו אחרנו ברשתות החברתיות",
                    },
                },

                contact: {
                    message: {
                        message_title: "שלחו לנו הודעה",
                        message_fname: "שם פרטי",
                        message_lname: "שם משפחה",
                        message_email: "מייל",
                        message_phone: "נייד",
                        message_text: "הודעה",
                        message_button: "שליחה",
                        message_button_loading: "שולח...",
                        message_address_title: "מיקום",
                        message_address: "תל אביב, ישראל",
                        message_phone_title: "טלפנו אלינו",
                        message_phone_number: "Tel: +972546604257",
                        message_email_title: "שלחו מייל",
                        message_email_address: "info@codesuits.com",
                        message_validation: "נא למלא את כל השדות",

                    },
                },

                demo: {
                    form: {
                        form_title: "שלחו לנו הודעה",
                        form_cb_web: "אפליקציית ווב/חנות אונליין/אתרים",
                        form_cb_mobile: "אפליקציות מובייל",
                        form_cb_cloud: "פתרונות ענן",
                        form_cb_uxui: "UX/UI שירותי",
                        form_cb_consult: "IT ייעוץ",
                        form_fname: "שם פרטי",
                        form_lname: "שם משפחה",
                        form_email: "מייל",
                        form_phone: "נייד",
                        form_text: "הודעה",
                        form_button: "שליחה",
                        form_button_loading: "שולח...",
                        form_address_title: "מיקום",
                        form_address: "תל אביב, ישראל",
                        form_phone_title: "טלפנו אלינו",
                        form_phone_number: "Tel: +972546604257",
                        form_email_title: "שלחו מייל",
                        form_email_address: "info@codesuits.com",
                        form_validation: "נא למלא את כל השדות",

                    },
                },

            },
        },

    },
});

