import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from './Navbar/NavBar';

import phpLogo from '../images/casestudy/phpLogo.svg';
import reactjsLogo from '../images/casestudy/reactLogo.svg';
import hgLogo from '../images/casestudy/hgLogo.png';

import wooLogo from '../images/casestudy/wooLogo.svg';
import joomLogo from '../images/casestudy/joomLogo.svg';

import foodIntro from '../images/casestudy/foodIntro.jpg';
import foodImg2 from '../images/casestudy/foodImg2.jpg';
import foodImg3 from '../images/casestudy/foodImg3.jpg';



const CSFoodilyIntro = () => {


    const { t } = useTranslation()

    const { food_page_title } = t("portfolio_all.pages.foodily");
    const { food_subtitle1, food_subtitle2, food_description, food_client_title, food_product_title, food_work_title, food_client_description, food_product_description, food_work_description } = t("portfolio_all.pages.foodily");
    const { food_profile, food_profile_country_title, food_profile_country_value, food_profile_industry_titile, food_profile_industry_value, food_profile_team_titile, food_profile_team_value } = t("portfolio_all.pages.foodily");
    const { food_goals, food_goal1, food_goal2, food_goal3, food_goal4, food_goal5 } = t("portfolio_all.pages.foodily");

    const { food_challanges,
        food_challange_title1,
        food_challange_1,
        food_challange_title2,
        food_challange_2,
        food_challange_title3,
        food_challange_3 } = t("portfolio_all.pages.foodily");


    const { food_solution_title,
        food_solution_description1,
        food_solution_description2,
        food_solution_description3 } = t("portfolio_all.pages.foodily");


    const { food_techstack,
        food_techstack1,
        food_techstack2,
        food_techstack3,
        food_techstack4 } = t("portfolio_all.pages.foodily");


    const { food_results_title,
        food_result_title1,
        food_result_description1,
        food_result_title2,
        food_result_description2,
        food_result_title3,
        food_result_description3 } = t("portfolio_all.pages.foodily");


    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    return (

        <>
            <div className="foodily" id='foodily'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {food_page_title}</h1>


                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_subtitle2}</p>
                            </div>

                            <div>
                                <a className='my-3 text-xl text-gray-600 font-semibold' href='https://www.foodily.me' target='_blank'><u>www.foodily.me </u></a>
                            </div>

                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={foodIntro} />
                        </div>

                    </div>
                </div>



                {/* ---  DESCRIPTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_description}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_client_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_client_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_product_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_product_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_work_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_work_description}</p>
                            </div>
                        </div>

                    </div>


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {food_profile}</h1>
                        <p className='my-3 text-xl text-gray-600 font-regular'><b>{food_profile_country_title}: </b> UK</p> <p className='my-3 text-xl text-gray-600 font-regular'><b>{food_profile_industry_titile}: </b> Restaurants</p> <p className='my-3 text-xl text-gray-600 font-font-regular'><b>{food_profile_team_titile}: </b> 3 (PM, Fullstack eng., UX/UI)</p>

                    </div>




                </div>



                {/* ---  GOALS  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={foodImg2} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_goals}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{food_goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{food_goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{food_goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{food_goal4}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{food_goal5}</li>
                            </div>
                        </div>


                    </div>
                </div>


                {/* ---  CHALLANGES  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_challanges}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_challange_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_challange_1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_challange_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_challange_2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_challange_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_challange_3}</p>
                            </div>
                        </div>

                    </div>

                </div>


                {/* ---  SOLUTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_solution_title}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>


                            <div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_solution_description1}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_solution_description2}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_solution_description3}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={foodImg3} />
                        </div>

                    </div>


                    {/* ---  Tech Stack --- */}


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {food_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={reactjsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {food_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={wooLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {food_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={hgLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {food_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                             <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={joomLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {food_techstack4}</h3>
                                    </div>

                                </div>
                            </div> 


                        </div>
                    </div>
                </div>



                {/* ---  RESULT  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_results_title}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_result_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_result_description1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_result_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_result_description2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {food_result_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{food_result_description3}</p>
                            </div>
                        </div>

                    </div>

                </div>


            </div >
        </>
    )

}

export default CSFoodilyIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

