import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


import squidLogo from '../images/squid-192.svg';
import qsLogo from '../images/quickspace_logo.svg';
import cdLogo from '../images/compactdesign-log.jpg';
import saaslyLogo from '../images/saasly_logo.jpg';
import healthLogo from '../images/casestudy/health-logo.jpg';
import wearLogo from '../images/casestudy/wewear-logo.jpg';
import netpayLogo from '../images/casestudy/netpayLogo.jpg';

import pureLogo from '../images/casestudy/pure-logo.jpg';
import pureBg from '../images/casestudy/pureBg.jpg';

import foodilyLogo from '../images/casestudy/foodily-log.jpg';
import foodilyBg from '../images/casestudy/foodily.jpg';

import roveroLogo from '../images/casestudy/rovero-logo.jpg';
import roveroBg from '../images/casestudy/roveroBg.jpg';



import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: true,
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};



const Portfolio = () => {


    const { t } = useTranslation()
    const { title, all_cases } = t("portfolio");
    const { squid_title, squid_subtitle, squid_description, squid_learn } = t("portfolio.squid");
    const { health_title, health_subtitle, health_description, health_learn } = t("portfolio.health");
    const { wewear_title, wewear_subtitle, wewear_description, wewear_learn } = t("portfolio.wewear");
    const { netpay_title, netpay_subtitle, netpay_description, netpay_learn } = t("portfolio.netpay");


    return (
        <>

            <section className="clients-section pt-0 pb-0">


                <div className="my-4 py-4" id='portfolio'></div>
                <div className="my-4 py-4" id='portfolio'>

                    <div className="w-full lg:w-72 pt-2 lg:mx-12 pl-8 pr-8" >
                        <Link to="/portfolio-all" className="bg-transparent border border-blue-900 hover:bg-blue-900 hover:border-blue-800 hover:text-white  text-blue-900 justify-center text-center rounded-lg px-10 py-3 flex items-center group">{all_cases}
                            <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>

                    <h2 className="my-12 text-center text-3xl text-blue-900 uppercase font-bold">{title}</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                    </div>


                    <div className="auto-container pl-8 pr-8" >
                        <Swiper {...swiperOptions} className="clients-carousel owl-carousel owl-theme ">

                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">

                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-5.png" alt="Image" /></Link></figure> */}

                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={pureLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">PureRelax</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Beauty & Wellness</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    We developed an e-commerce site for PureRelax, a beauty and health products and services company. It included additional features such as dynamic content, booking system, SEO solutions and more.
                                                </p>
                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-relax" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {netpay_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </SwiperSlide>


                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">

                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-1.png" alt="Image" /></Link></figure> */}

                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={foodilyLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Foodily</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">Coffee Bar</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    A website for a cafehouse with information about its products, menu and events. We enabled the client to increase their engagement with their customers.
                                                </p>
                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-foodily" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {netpay_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>


                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">

                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-2.png" alt="Image" /></Link></figure> */}

                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={roveroLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">Rovero Hotel</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{netpay_subtitle}</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    CodeSuits developed a website for the Rovero Hotel with a room reservation and online payment solution, including dynamic content (CMS), live chat powered by artificial intelligence, accessibility and more..
                                                </p>
                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-rovero" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {netpay_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </SwiperSlide>

                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">


                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-1.png" alt="Image" /></Link></figure> */}


                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={squidLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{squid_title}</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{squid_subtitle}</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    {squid_description}
                                                </p>


                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-squid" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {squid_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">



                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-2.png" alt="Image" /></Link></figure> */}

                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={healthLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{health_title}</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{health_subtitle}</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    {health_description}
                                                </p>
                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-healthness" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {health_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">


                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={wearLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{wewear_title}</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{wewear_subtitle}</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    <p> {wewear_description}</p>
                                                </p>
                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-wewear" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {wewear_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>


                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-3.png" alt="Image" /></Link></figure> */}

                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* Clients Block */}
                            <SwiperSlide className="client-block">
                                <div className="inner-box">
                                    <div className="image-box">


                                        {/* <figure className="image"><Link href="#"><img src="/images/resource/client1-4.png" alt="Image" /></Link></figure> */}


                                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                                            <div className="m-2 text-justify text-sm">
                                                <div className="grid align-items:center">
                                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={netpayLogo} />

                                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{netpay_title}</h4>
                                                </div>

                                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{netpay_subtitle}</h4>
                                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                                    {netpay_description}
                                                </p>
                                                <div className="flex justify-center my-4">
                                                    <Link to="/casestudy-fintech" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                                        {netpay_learn}
                                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </SwiperSlide>



                        </Swiper>
                    </div>












                </div>
            </section >
        </>
    );
};

export default Portfolio;
/*
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>




                <div className="w-full lg:w-72 pt-6 lg:mx-12" >

                    <Link to="/portfolio-all" className="bg-transparent border border-blue-900 hover:bg-blue-900 hover:border-blue-800 hover:text-white  text-blue-900 justify-center text-center rounded-lg px-10 py-3 flex items-center group">{all_cases}
                        <svg className="w-5 h-5 ml-1 group-hover:translate-x-2 duration-500 ease-in" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </Link>
                </div>

                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{title}</h2>

                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>











                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">



                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="grid align-items:center">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={squidLogo} />

                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{squid_title}</h4>
                                </div>

                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{squid_subtitle}</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    {squid_description}
                                </p>


                                <div className="flex justify-center my-4">
                                    <Link to="/casestudy-squid" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        {squid_learn}
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="grid align-items:center">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={healthLogo} />

                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{health_title}</h4>
                                </div>

                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{health_subtitle}</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    {health_description}
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/casestudy-healthness" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        {health_learn}
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>










                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="grid align-items:center">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={wearLogo} />

                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{wewear_title}</h4>
                                </div>

                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{wewear_subtitle}</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    <p> {wewear_description}</p>
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/casestudy-wewear" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        {wewear_learn}
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="grid align-items:center">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={netpayLogo} />

                                    <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{netpay_title}</h4>
                                </div>

                                <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{netpay_subtitle}</h4>
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    {netpay_description}
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/casestudy-fintech" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        {netpay_learn}
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>




                </div>
            </div>
        </>
    )
}

*/


